.loading-container .img{
    height: 80px;
    width: 80px;
    align-self: center;
    display: flex;
    justify-content: center;
}
.loading-container .img{
    height: 80px;
    width: 80px;
}