@font-face {
  font-family: Lineat;
  src: url('../fonts/Lineat.ttf');
}

/* width */
::-webkit-scrollbar {
  width: 6px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #10a5b2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rlr-navigation .rlr-logo__navbar-brand img {
  width: 85px;
}

@media (max-width: 1300px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1050px !important;
  }
}

.submenu-indicator:last-child {
  display: none;

}

.most_popul_sect {
  flex-grow: 2 !important;
  width: 200px !important;
  max-width: 260px !important;
  float: right;
  position: absolute !important;
  right: 30px;
  top: 25px;
}

.most_popul_sect h1 {
  font-size: 20px;
  padding-bottom: 5px;

}

.most_popul_sect p {
  padding-top: 15px;
  font-size: 14px;
}

.most_popul_sect .banner_img {
  width: 100%;
  height: 130px;
}

.most_popul_sect .banner_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navigation-tabs-nav>li a {
  font-size: 14px !important;
}

.rlr-navigation .navigation-col div>span>a {
  font-size: 14px !important;
}

.loader-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  /* background: #10a5b2; */
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: transparent;
  /* border:3px solid #3c3c3c; */
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #006eff;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #006eff;
  /* box-shadow:0 0 5px rgba(0,0,0,.5); */
  display: flex;
  justify-content: center;
  align-items: center;

}

.loader img {
  max-width: 80%;
}

.loader:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 104%;
  height: 104%;
  border: 3px solid transparent;
  border-top: 3px solid #adaeca;
  border-right: 3px solid #adaeca;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
  display: none;
}

.loader span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
  display: none;
}

.loader span:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #adaeca;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px #adaeca;
  display: none;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.menuhidden {
  display: none !important;
}

.navigation-item.has-submenu:hover>.navigation-submenu {
  opacity: 1;
  max-height: 2500px;
  right: 60px !important;
}

/* .navigation-item.has-submenu:hover>.navigation-submenu {
  opacity: 1;
  max-height: 2500px;
  right: 0 !important;
} */

.navigation-item.has-submenu:hover>.navigation-dropdown-new {
  opacity: 1;
  max-height: 2500px;
  background: #fcfcfd;
}

.navigation-dropdown-new-months {
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.navigation-dropdown-new-months li {
  width: calc(100% / 3);
}

.list_property .swiper-slide img {
  height: 250px !important;
}

.banner-section {
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.banner-section img {
  width: 100%;
  height: 100%;
}

.sortby_item {
  top: 60px;
}

.sortby_item.active {
  display: block !important;
}

.rlr-search-results-header__value {
  font-size: 16px !important;
  margin-bottom: 0;
}

.list_property .swiper-button-next,
.list_property .swiper-button-prev {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 65%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.list_property .swiper-button-next:after,
.list_property .swiper-button-prev:after {
  font-size: 15px !important;
  color: #000 !important;
}

.splide__arrow {
  border-radius: 50%;
}

.swiper_h:hover .splide__arrow {
  opacity: 0.9;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
}

.right_slider_banner {
  height: calc((50vh - 0px) - 0px);
}

.right_slider_banner .swiper {
  height: 90%;
}

.right_slider_banner .swiper-slide img {
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.navigation {
  transition: background 0.3s, height 0.3s;
}

.navigation.sticky-nav {
  height: 70px;
}

.home_banner_section {
  position: relative;
}

.home_banner_section::after {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 /13%);
  z-index: 1;
  position: absolute;
}

.rlr-banner-splide__content-wrapper {
  z-index: 1;
}

.opacity_color {
  position: relative;
  border-radius: 0.5rem;
}

.opacity_color::after {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 10%);
  z-index: 1;
  position: absolute;
  border-radius: 0.5rem;
}

.rlr-product-card--featured__body_home {
  justify-content: space-between !important;
}

.list_property_paading {
  padding: 15px 0 !important;
}

.text-uppercase {
  text-transform: uppercase;
  color: #fff;
  font-size: 35px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

.featured__summary_new {
  padding: 20px;
  text-align: center;
  margin-top: -50px;
  z-index: 111;
  position: relative;
  /* background: #fff; */
  width: 100%;
  margin: -44px auto 0;
}

.product_card__image_wrapper_details {
  border-radius: 1.5rem;
  overflow: hidden;
}

.product_card__image_wrapper_details img {
  border-radius: 1.5rem;
}

.months_box {}

/*
.navigation-dropdown.is-visible{
 opacity: 0 !important;
}
.profile_class:hover ~ .navigation-dropdown.is-visible{
opacity: 1 !important;
} */

.like_section {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.like_section h4 {
  margin-bottom: 0;
  font-size: 14px;
  color: #222;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 10px;
  cursor: pointer;
}

.like_section h4.active {
  color: #10a5b2;
}

.like_section h4 i {
  font-size: 16px;
}

.like_section h4 span {
  font-size: 10px;
}

.swiper-pagination-bullet-active {
  background: #000 !important;
}

/* .select_category{
  background: url('../images/pattern_2.png');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */
.select_category {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
  /* object-fit: cover; */
}

.padding_top {
  padding-top: 6rem;
}

.rlr-subscribe {
  position: relative;
}

.rlr_subscribe__input_new {
  width: 100%;
  position: relative;
}

.rlr-subscribe .mb-3 {
  margin-bottom: 0 !important;
}

.invalid-feedback {
  position: absolute;
  bottom: 0px;
  left: 60px;
}

.opcity_bg {
  position: relative;
  border-radius: 1.5rem;
}

.opcity_bg::after {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 30%);
  z-index: 1;
  position: absolute;
  border-radius: 1.5rem;
}

.rlr-section__cards-mo {
  background: #dee4e5;
  margin-bottom: 0;
  margin-top: 0;
  padding: 100px 0 100px;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  font-size: 120px;
  font-weight: bold;
  padding: 10px 20px 100px;
  background: #dee4e5;
  margin-bottom: 80px;
}

.marquee .track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 140s linear infinite;
}

.marquee .content {
  opacity: 0.05;
  line-height: 1.2;

}

.rlr-section__cards_new {
  margin-bottom: 4rem !important;
}


@keyframes marquee {
  from {
    transform: translateX(0)
  }

  to {
    transform: translateX(-30%)
  }
}

footer {
  background: #fcfcfd;
}

.rlr_button_brand {
  padding: 8px 30px;
  font-size: 16px;
}

.rlr-banner-input-group__input-wrapper span {
  font-size: 12px;
}

.rlr-banner-input-group__input-wrapper i {
  font-size: 14px;
}

.rlr-category-card {
  padding: 0;
}

.rlr-category-card__badge {
  position: absolute;
  right: 10px;
  top: 10px;
}

.rlr-category-card_img {
  height: 150px !important;
  max-height: 150px;
  object-fit: cover;
}

.rlr-category-card header {
  padding: 15px;
}

.filter_list {
  transition: 0.5s;
  display: none;
}

.filter_list.active {
  display: block;
  transition: 0.5s;
}

.accordion-button:focus {
  box-shadow: none;
}

.disclaimer {
  margin-top: 20px;
  background: #000;
  color: #fff;
  padding: 14px 35px;
  line-height: 18px;
  transform: translateY(100%);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1111;
  transition: 0.6s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.disclaimer button {
  color: #fff;
  background-color: #10a5b2;
  border: none;
  float: right;
  padding: 5px 14px;
  font-size: 14px;
}

.disclaimer.active {
  transform: translateY(0%);
}

.disclaimer h2 {
  font-size: 20px;
}

.custome_popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  /* background: #282828ba; */
  z-index: 11111;
  top: 0%;
  left: 0;

  justify-content: center;
  align-items: center;
  opacity: 0;
  display: flex;
  transition: 1s;
  transform: translateY(-100%);
}

.custome_popup.active {

  opacity: 1;
  transform: translateY(0);

}

.overflow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #282828ba;
  z-index: 1111;
  display: none;
  opacity: 1;
  transition: 1s;
}

.overflow.active {
  display: block;
  opacity: 1;
}

/* .container_section{
  transition: 1s;
  transition-delay: 2s;
transform: translateY(-100%);
opacity: 0;
} */
/* .container_section.active{
  opacity: 1;
  transform: translateY(0);
} */
.custome_popup .container_section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 460px;
  position: relative;
  z-index: 11;
}

.custome_popup .container_section .popup_left_box {
  width: 50%;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  overflow: hidden;
  height: 100%;
}

.custome_popup .container_section .popup_left_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custome_popup .container_section .right_section_popup {
  width: 50%;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.custome_popup .container_section .right_section_popup h1 {
  font-size: 24px;
}

.custome_popup .container_section .right_section_popup p {
  font-size: 14px;
  padding: 10px 0;
}

.custome_popup .container_section .right_section_popup form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custome_popup .container_section .right_section_popup input {
  width: 63%;
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid #bfbfbf;
  outline: none;
  font-size: 14px;

}

.custome_popup .container_section .right_section_popup button {
  width: 33%;
  padding: 10px;
  background: #10a5b2;
  outline: none;
  border: 1px solid #10a5b2;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
}

.custome_popup .container_section .right_section_popup h6 {
  font-weight: normal;
  font-size: 14px;
  padding-top: 20px;
}

.custome_popup .container_section .right_section_popup h6 a {
  color: #000;
}

.custome_popup .container_section .right_section_popup .close_btn {
  background: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 25px;
  right: 55px;
  border: none;
  padding: 0;
}

.custome_popup .container_section .right_section_popup .close_btn img {
  width: 65%;
}

.animated {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform, opacity;
  background: #fff;


}

.animated.slideDown,
.animated.slideDown {
  height: 70px;
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%)
  }

  100% {
    -webkit-transform: translateY(0)
  }
}

@-moz-keyframes slideDown {
  0% {
    -moz-transform: translateY(-100%)
  }

  100% {
    -moz-transform: translateY(0)
  }
}

@-o-keyframes slideDown {
  0% {
    -o-transform: translateY(-100%)
  }

  100% {
    -o-transform: translateY(0)
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%)
  }

  100% {
    transform: translateY(0)
  }
}

.animated.slideDown {
  -webkit-animation-name: slideDown;
  -moz-animation-name: slideDown;
  -o-animation-name: slideDown;
  animation-name: slideDown
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0)
  }

  100% {
    -webkit-transform: translateY(-100%)
  }
}

@-moz-keyframes slideUp {
  0% {
    -moz-transform: translateY(0)
  }

  100% {
    -moz-transform: translateY(-100%)
  }
}

@-o-keyframes slideUp {
  0% {
    -o-transform: translateY(0)
  }

  100% {
    -o-transform: translateY(-100%)
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(-100%)
  }
}

.animated.slideUp {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  -o-animation-name: slideUp;
  animation-name: slideUp
}

.rlr_category_card_new {
  border: none;
  box-shadow: none;
  background: transparent;
  text-align: center;
}

.rlr_category_card_new .rlr-category-card__sub-title {
  text-align: center;
}

.rlr_category_card_new_img {
  border-radius: 200px;
  height: 300px !important;
  max-height: auto !important;
  object-fit: cover;
  transition: 0.6s;
}

.rlr_category_card_new:hover .rlr_category_card_new_img {
  transform: scale(.9);
}

.who_left_section {
  background-image: url('../images/whois.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100vh;
  border-radius: 200px;
  background-attachment: fixed;
}

.addSection {
  padding-top: 5rem;
}

.addSection_inner_two {
  padding-top: 2rem;
}

.destination-video {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background-color: #dee4e5;
  position: relative;
}

.destination-video::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: #fff;
}

.destination-video video {
  width: 82%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 100px;
  z-index: 1;
}

.video_detail_box {
  position: absolute;
  right: 50%;
  top: 50%;
  width: 82%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background: #00000040;
  transform: translate(50%, -50%);
  z-index: 1;
  border-radius: 100px;
}

.video_detail_box h1 {
  font-weight: 100;
  font-size: 55px;
  line-height: 54px;
  padding: 16px 0 35px;
  font-family: Lineat;
  color: #fff;
}

.video_detail_box h4 {
  font-family: Georgia_font;
  font-size: 24px;
  margin-bottom: 0;
}

.video_detail_box p {
  font-family: Futura bk BT;
  font-size: 22px;
  color: #fff;
}

.video_detail_section {
  padding-right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.footer_tag {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  padding-top: 100px;
}

.footer_tag h3 {
  font-size: 50px;
  color: #e5e5e5;
  font-weight: 700;
}

.footer_tag i {
  font-size: 40px;
  padding-left: 10px;
  color: #d1a1b6;

}

.footer_tag div {
  display: flex;
  align-items: center;
}

.footer_tag div i {}

.footer_section {
  background: #fcfcfd;

}

footer {
  background: url('../images/globe.png') top center no-repeat;
  background-size: 80% auto;
  margin-top: 5rem !important;
  padding: 40px 0 0;
}

.first-paragraph {
  line-height: 25px;
  letter-spacing: 1px;
}

.first-paragraph::first-letter {
  font-size: 50px;
  font-weight: 700;

}

.rlr_overview_detail__icon_groupset_new {
  background: #fcfcfd;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #f2f6fd;
  border-radius: 10px;
  /* height: 100%; */

}

.rlr_overview_detail__icon_groupset_new .rlr-overview-detail__icon-group {
  width: 100% !important;
  line-height: 20px;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.rlr_overview_detail__icon_groupset_new .rlr-overview-detail__label {
  color: var(--heading-color);
  display: inline-block;
  letter-spacing: 0.56px;
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  font-size: 13px;
  /* margin-right: 20px; */
  width: 40%;
  padding-right: 10px;
}

.rlr_overview_detail__icon_groupset_new .rlr-icon-text,
.rlr_overview_detail__icon_groupset_new .rlr-overview-detail__icons .rlr-icon-font {
  color: #000;
  margin: 0;
  align-items: baseline;
  display: flex;
  font-size: 13px;
}

.rlr_overview_detail__icon_groupset_new .rlr-overview-detail__icons .rlr-icon-font {
  font-size: 13px;
}

.rlr_overview_detail__icon_groupset_new .rlr-overview-detail__icons {
  width: 60% !important;
}

.star-container svg {
  width: 15px;
  height: 15px;
}

.who_right_section {}


.modal_form {
  max-width: 800px !important;
}

.banner_slide {
  height: 500px !important;
}

.pt-100px {
  padding-top: 100px !important;
}

.pb-100px {
  padding-bottom: 100px !important;
}

.rlr-masonary-grid__container_new {
  padding: 15px 0 !important;
}

.rlr-section__mb_bottom {
  margin-bottom: 2rem;
}

.add_section_mid {
  margin: 60px 0;
}

.mobile_link {
  display: none;
}

.web_link {}

.rlr-search-results-page__card-wrapper_bot {
  padding-bottom: 30px;
}

.mobile_menu_ext_btn {
  display: none;
}

.rlr-search-results-page__dynamic-filter-section p {
  margin: 10px 0px;
}

.profile-page {
  position: relative;
  padding: 50px 50px 50px;
  margin-top: 40px;
  background: #f5f5f5;
}

.left_profile_box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 100%;
  padding: 20px;
}

.profile_img {
  width: 120px;
  height: 120px;
  background: #ccc;
  border-radius: 50%;
  overflow: hidden;

}

.profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.left_profile_box h3 {
  font-size: 20px;
  text-align: center;
  padding: 5px 0 0;
  margin-bottom: 0;
}

.left_profile_box p {
  font-size: 14px;
  text-align: center;
}

.right_profile_content {
  background-color: #fff;
  height: 100%;
  padding: 20px;
}

.right_profile_content ul {
  list-style: none;
}

.right_profile_content ul li {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.right_profile_content ul li:last-child {
  border: none;
}

.right_profile_content ul li .left_detail {
  width: 30%;
  padding-right: 20px;
}

.rlr-product-card__from {
  color: #10a5b2 !important;
}

.mmargin_bottom_100px {
  margin-bottom: 65px;
}

.rlr-search-results-page__dynamic-filter-section_new {
  margin-top: var(--spacing-8) !important;
}

.new_service_list ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.new_service_list li {
  padding-right: 10px;
}

.new_service_list li span {
  color: #000 !important;
}

.visite_card {
  padding-top: 50px;
}

.S9gUrf-YoZ4jf,
.S9gUrf-YoZ4jf {
  width: 100% !important;
}

.nsm7Bb-HzV7m-LgbsSe {
  width: 100vw !important;
  max-width: 100% !important;
}

.haAclf {
  padding: 0 !important;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f1f1f1;
  padding: 20px;
}

.loading-container img {
  width: 80px;
}

.loading-container span {
  margin-left: 20px;
}

.search_input {
  position: relative;
  padding: 0px 18px 30px;
}


.search_field {
  width: 800px;
  display: flex;
  align-items: center;
}

.search_field input {
  width: 70%;
  padding: 6px;
  outline: none;
  height: 40px;
  border-radius: 0 !important;
  border: 1px solid #10a5b2;

}

.search_field button {
  width: 30%;
  padding: 0;
  background: #10a5b2;
  border-color: #10a5b2;
  color: #fff;
  outline: none;
  border: navajowhite;
  height: 40px;
  border-radius: 0 !important;
  border: 1px solid #10a5b2;
}

.eapps-instagram-feed-container~a {
  display: none !important;
}

.otp-input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.otp-input>div {
  display: flex;
  align-items: center;
  justify-content: space-evenly !important;
}

.otp-input-field {
  width: 100% !important;
  height: 50px;
  font-size: 30px;
  text-align: center;
  border: 2px solid #546078;
  border-radius: 0.5rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.resend_otp_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.resend_otp_section button {
  background: transparent;
  color: #546078;
  border: none;
  outline: none;
  font-weight: 500;
}

.rlr-authforms__inputgroup {
  position: relative;
}

.rlr-authforms__inputgroup i {
  position: absolute;
  right: 42px;
  top: 68%;
  transform: translateY(-50%);
}

.top {
  place-self: end;
  /* visual styling */
  text-decoration: none;
  padding: 10px;
  font-family: sans-serif;
  color: #fff;
  background: #10a5b2;
  border-radius: 100px;
  white-space: nowrap;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: none;
  z-index: 111;
}

.rlr_overview_detail__icon_groupset_new_fixed {
  height: 100%;
}

.booking_sticky_setion {
  position: sticky;
  top: 30px;
}

.map_right {
  position: relative;
}

.map_right div>div:first-child {
  position: relative !important;
}

.back_to_top {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.filter_toogle_btn {
  width: 40px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  bottom: 80px;
  right: 0;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 6px;
}

.filter_toogle_btn img {
  width: 30px;
}

.favourites-heading {
  padding: 20px 0;
  font-size: 30px;
}

.rlr-product-card--featured__duration p {
  margin-bottom: 2px !important;
}

.rlr-product-card--featured__body_home .rlr-button--circle {
  align-items: center;
  width: 2rem;
  height: 2rem;
}

.rlr-product-card--featured__body_home .rlr-button--circle:hover,
.rlr-product-card--featured__body_home .rlr-button--circle:active {
  box-shadow: none !important;
}

.sectin_comm {
  position: relative;
  padding-top: 30px;
}

.sectin_comm p {
  color: var(--body-color);
  font-weight: 300;
  font-size: 1rem;
}

.sectin_comm ul {
  position: relative;
}

.sectin_comm ul li {
  color: var(--body-color);
  font-weight: 300;
  font-size: 1rem;
}

.sectin_comm h4 {
  font-size: 20px;
  padding: 10px 0;
}

.also-check-more-continents {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.also-check-more-continents .rlr-search-results-page__card-wrapper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.also-check-more-continents .rlr-search-results-page__product-details {
  padding-top: 0 !important;
}

.map_right_footer {
  margin: 40px 0 50px;
}

.swiper_h {
  padding: 0 70px;
}

.splide__arrow--prev_n {
  background: var(--brand-07) !important;
}

.splide__arrow--next_n {
  background: var(--brand-07) !important;
}

.splide__arrow--next_n i,
.splide__arrow--prev_n i {
  padding-top: 4px;
}

.unlike_modal .modal-content {
  padding: 40px 40px !important;
}

.unlike_modal h3 {
  padding-bottom: 0;
  font-size: 30px;
  margin-bottom: 10px;
}

.unlike_modal p {
  text-align: center;
  font-size: 15px;
  padding-bottom: 10px;
}

.unlike_modal .rlr-copylink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unlike_modal .rlr-copylink button {
  padding: 5px 10px;
  margin: 0 5px;
}

.unlike_modal .rlr-copylink button.btn-primary {
  background: var(--brand-07) !important;
  border-color: var(--brand-07) !important;
}

.google_login div {
  position: fixed !important;
  top: -100% !important;
  left: 0 !important;
}

.filter_items_section {
  padding: 10px;
}

.filter_items_section input[type=checkbox] {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid var(--brand-07);
  margin-right: 6px;
  margin-top: 3px;
  cursor: pointer;
}

.filter_items_section .form-check-label {
  cursor: pointer;
  font-size: 14px;
}

.filter_items_section .form-check-input:checked {
  background-color: #10a5b2 !important;
  border-color: #10a5b2;
}

.filter_items_section .form-check-input:focus {
  border-color: #10a5b2;
  box-shadow: 0 0 0 0.25rem #10a5b247 !important;
  outline: 0;
}

.destination_section {
  display: flex;
  /* align-items: center; */
}

.left_listing {
  width: 25%;

  /* padding: 20px; */
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  /* padding: 20px; */
}

.left_listing li {
  font-size: 16px;
  padding: 5px 0;
}

.left_listing li .fiter_btn {
  width: 100%;
  border: none;
  outline: none;
  background: #f2f2f2;
  text-align: left;
  padding: 5px 10px;
}

.left_listing li .fiter_btn.active {
  background: var(--brand-07);
  color: #fff;
}

.right_listing_destination {
  /* background: #f1f1f1; */
  width: 75%;
  height: 100%;
}

.right_des_list {
  display: none;

}

.right_des_list.active {
  display: flex;
}

.right_des_list .navigation-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.right_des_list .navigation-row .destination_link {
  width: 33%;
  padding: 10px;
}

.filter_section {
  position: relative;
}

.filter_items_section h3 {
  font-size: 20px;
  padding: 10px 0;
  flex-direction: column;
}

.experiences_section {
  padding-top: 10px;
}

.experiences_section ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}

.experiences_section ul li {
  width: 33%;
  padding: 10px 0;
}

.filter_modal_box {
  height: 550px;
  overflow: hidden;
  overflow-y: scroll;
}

.filter_footer {
  padding-top: 20px;
}

.filter_footer .btn-primary {
  margin-right: 10px;
  background: var(--brand-07) !important;
  border-color: var(--brand-07) !important;
}

.filter_modal_box .close_btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  color: #000;
  outline: none;
  border: none;
}

.allcheck {
  height: 18px !important;
  width: 18px !important;
  border-radius: 50% !important;
  border: 1px solid var(--brand-07) !important;
  margin-right: 6px !important;
  margin-top: 3px !important;
  cursor: pointer !important;
}

.allcheck:checked {
  background-color: #10a5b2 !important;
  border-color: #10a5b2 !important;
}
.vCard_section{
  width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.vCard_section p{
  font-size: 30px;
  text-align: center;
}
.vCard_section button{
  border-radius: 0 !important;
}
@media (min-width: 576px) {
  .filter_modal {
    margin: 1.75rem auto;
    max-width: 95%;

  }
}

@media(max-width:1300px) {
  .who_left_section {
    height: 100vh;
  }
}

@media(max-width:1199px) {
  .rlr-search-results-page__dynamic-filter-section_new {
    margin: 50px 0 80px;
  }

  .mmargin_bottom_100px {
    margin-bottom: 0;
  }
}

@media(max-width:991px) {

  .rlr-button--medium,
  .rlr-button--small,
  .rlr-navigation__link,
  .rlr-product-card--v3 .rlr-product-card__price,
  .rlr-product-card--v3 .rlr-product-card__title,
  .type-sub-title-medium {
    font-size: 1rem;
  }

  .mc4wp-form-fields .btn,
  .rlr-banner-splide__payment-desc,
  .rlr-banner-splide__sub-title,
  .rlr-footer__getintouch__col--title h4,
  .rlr-footer__quicklinks,
  .rlr-footer__quicklinks--expore,
  .rlr-newsletter__content .rlr-newsletter__subtitle,
  .rlr-newsletter__content p,
  .rlr-section__title--sub,
  .rlr-subscribe .btn,
  .rlr-support-card__subtitle,
  .rlr-support-card__text,
  .type-h6-light {
    font-size: calc(1rem + 0.3vw);
    font-weight: 300;
  }

  .who_left_section {
    height: 60vh;
    width: 95%;
    margin: auto;
    border-radius: 50px;
    background-size: cover;
  }

  .who_right_section {
    padding: 50px 30px;
  }

  .rlr_category_card_new_img {
    height: 250px !important;
    border-radius: 50%;
    width: 250px;
  }

  .addSection {
    padding-top: 1rem;
  }

  .video_detail_box {
    width: 95%;
  }

  .destination-video video {
    width: 95%;
  }

  .rlr-section__cards_new {
    margin-bottom: 3rem !important;
  }

  .rlr-banner-splide__slogan {
    font-size: 30px;
  }

  .rlr-section__title--main {
    font-size: calc(1rem + 1.2vw);
  }

  .padding_top {
    padding-top: 4rem;
  }

  .mobile_link {
    display: block;
  }

  .web_link {
    display: none;
  }

  .mobile_menu_ext_btn {
    position: absolute;
    right: 0;
    top: 3px;
    font-size: 30px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    outline: none;
    color: #10a5b2;
  }

  .navigation-item.has-submenu:hover>.navigation-submenu {
    opacity: 0;
    max-height: 0;
    right: 0 !important;
  }

  .navigation-body {
    height: 100vh;
  }

  .navigation-item {
    position: relative;
  }

  .navigation-item.has-submenu:hover>.navigation-dropdown-new {
    opacity: 0;
    max-height: 0;
    background: #fcfcfd;
  }

  .rlr-navigation .navigation-submenu.active {
    opacity: 1 !important;
    max-height: 2500px !important;
    right: 0 !important;

  }

  .navigation-dropdown.active {
    opacity: 1 !important;
    max-height: 2500px !important;
    right: 0 !important;
  }

  .navigation-dropdown-new-months li {
    width: calc(100% / 2);
  }

  .addSection_inner {
    padding-top: 4rem;
  }

  .rlr-search-results-header__wrapper {
    width: 95%;
    margin: auto;
  }

  .first-paragraph {
    padding: 0 !important;
    font-size: 15px;
  }

  footer {
    margin-top: 5rem !important;
  }

  .rlr-search-results-page__dynamic-filter-section_new {
    margin: 50px 0 80px;
  }

  .rlr-banner-search__input-wrapper {
    flex-direction: initial;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rlr-banner-input-group {
    flex: auto;
    width: 50%;
  }

  .product_card__image_wrapper_details {
    height: 320px;
    object-fit: cover;
  }

  .rlr-js-category-card-swiper .container-fluid.p-0 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }

  .custome_popup .container_section {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    flex-wrap: wrap;
  }

  .custom-popup__holder {
    flex-flow: wrap;
  }

  .custome_popup .container_section .popup_left_box {
    width: 100%;
    border-radius: 0;
  }

  .custome_popup .container_section .right_section_popup {
    width: 100%;
    border-radius: 0;
    padding: 30px;
    justify-content: flex-start;
    height: fit-content;
  }

  .custome_popup .container_section .right_section_popup h1 {
    font-size: 18px;
  }

  .custome_popup .container_section .popup_left_box {
    width: 100%;
    border-radius: 0;
  }
}

@media(max-width:768px) {
  .list_property .swiper-slide img {
    width: 100%;
    height: 300px !important;
  }

  .who_right_section {
    padding: 50px 30px 30px;
  }

  .rlr-section-header {
    padding-bottom: 2rem;
  }

  .marquee {
    margin-bottom: 50px;
    padding: 10px 20px 60px;
    font-size: 75px;
  }

  .footer_tag {
    padding-top: 50px;
  }

  .footer_tag h3 {
    font-size: 40px;
  }

  .rlr-section__mb {
    margin-bottom: 2.5rem;
  }

  .rlr-navigation .navigation-row>[class^="navigation-col"] {
    width: 100%;
    max-width: 100%;
  }

  .rlr-banner-search__input-wrapper {
    row-gap: var(--spacing-4);
  }

  .rlr-banner-input-group__label mark,
  .rlr-product-card__price,
  .rlr-support .rlr-section__heading--sub,
  .type-h5 {
    font-size: calc(1.2rem + 0.6vw);
  }

  .rlr-banner-search {
    padding: var(--spacing-5) var(--spacing-5);
  }

  .rlr-product-detail-section.mt-2 {
    margin-top: var(--spacing-9) !important;
  }

  .search_field {
    width: 100% !important;
  }

  .search_field input::placeholder {
    font-size: 11px !important;
  }

  .search_input {
    padding: 0 30px 20px !important;
  }
}

@media(max-width:600px) {
  .rlr-section-header>.rlr-section__title {
    padding-bottom: 0;
    width: 100%;
    text-align: center;
  }

  .list_property .swiper-slide img {
    width: 100%;
    height: 180px !important;
  }

  .rlr_category_card_new_img {
    width: 180px !important;
  }

  .padding_top {
    padding-top: 3.5rem;
  }

  .who_right_section {
    padding: 50px 30px 15px;
  }

  .pb_0_m {
    margin-bottom: 1.4rem !important;

  }

  .video_detail_box {
    border-radius: 25px;
  }

  .destination-video video {
    width: 95%;
    border-radius: 25px;
  }

  .video_detail_box h1 {
    font-size: 30px;
    line-height: 0;
  }

  .video_detail_section {
    padding: 20px;
  }

  .rlr-section.pt-5 {
    padding-top: 2rem !important;
  }

  .rlr-banner-splide--v3 .rlr-banner-splide__content-wrapper {
    text-align: center;
  }

  .rlr-banner-splide__arrows {
    justify-content: center;
  }

  .rlr-banner-splide__content-desc-right {
    align-items: center;
  }

  .rlr-section-header .button-row {
    justify-content: center;
    margin-top: 24px;
  }

  .rlr-section-header .button-row .rlr-button--carousel {
    margin: 0 5px;
    width: 45px;
    height: 45px;
  }

  .rlr-section__title {
    text-align: center;
  }

  footer {
    margin-top: 3.5rem !important;
    padding: 25px 0 0;
  }

  .mb_0_m {
    margin-bottom: 0 !important;
  }

  .rlr-footer__menu__col {
    text-align: center;
  }

  .footer_tag div {
    justify-content: center;
  }

  .footer_tag h3 {
    font-size: 30px;
    margin-bottom: 0;
  }

  .footer_tag i {
    font-size: 30px;
  }

  .rlr-footer__menu__col--lg .rlr-footer__menu__col__letstalk {
    background-size: 20px auto;
    padding: 2px 30px 20px 0;
  }

  .footer_tag {
    padding-top: 30px;
  }

  .banner-section {
    margin-bottom: 20px;
    height: 250px;
  }

  .banner-section img {
    object-fit: cover;
  }

  .rlr-search-results-page__product-details {
    padding-top: 20px;
  }

  .add_section_mid {
    margin: 20px 0;
  }

  .rlr-search-results-page__card-wrapper_bot {
    padding-bottom: 20px;

  }

  .addSection_inner {
    padding-top: 2rem;
  }

  .rlr-search-results-header__value {
    font-size: 14px !important;
    margin-bottom: 0;
    text-align: center;
  }

  .rlr-section__title--main {
    font-size: 18px;
  }

  .rlr-search-results-page__dynamic-filter-section_new {
    margin: 40px 0 50px;
  }

  .rlr-banner-input-group__label mark,
  .rlr-product-card__price,
  .rlr-support .rlr-section__heading--sub,
  .type-h5 {
    font-size: calc(1rem + 0.6vw);
  }

  .rlr-breadcrumb__item+.rlr-breadcrumb__item:before {
    content: ">";
    margin: 2px 2px 0;
  }

  .breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
    font-size: 12px;
  }

  .rlr-product-detail-header__actions {
    position: absolute;
    right: 0;
    top: 38px;
  }

  .rlr-booking-card__current-price,
  .rlr-product-detail-header__title {
    font-size: calc(1.2rem + 0.6vw);
  }

  .rlr-product-detail-header__actions .rlr-icon-font {
    font-size: 1.2rem;
  }

  .rlr-button--circle {
    border-radius: 50%;
    height: 2.5rem;
    padding: 1rem;
    width: 2.5rem;
  }

  .addSection_inner_two {
    padding-top: 0 !important;
    margin-top: 25px !important;
  }

  .product_card__image_wrapper_details {
    height: 240px;
    object-fit: cover;
  }

  .profile-page {
    position: relative;
    padding: 30px 20px 30px;
    margin-top: 30px;
  }

  .right_profile_content {
    height: 100%;
    padding: 0 10px;
  }

  .right_profile_content ul {
    padding-left: 0;
  }

  .left_profile_box p {
    margin-bottom: 0;
  }

  .left_profile_box h3 {
    padding: 10px 0 0;
  }

  .custome_popup .container_section .right_section_popup form {
    flex-wrap: wrap;
  }

  .custome_popup .container_section .right_section_popup input {
    width: 100%;
  }

  .custome_popup .container_section .right_section_popup button {
    width: 100%;
    margin-top: 20px;
  }

  .custome_popup .container_section .right_section_popup .close_btn {
    top: -5px;
    right: 15px;
  }

  .rlr-main--fixed-top {
    margin-top: 80px !important;
  }

  .rlr_main_fixed_top_common {
    margin-top: 80px !important;
  }

  .rlr_main_fixed_top_inner_page {
    margin-top: 80px !important;
  }

  .rlr-product-card__image-wrapper_new img {
    height: 220px !important;
  }

  .top_home_section .rlr-section-header {
    padding-bottom: 3rem;
  }

  .row.rlr-featured__cards {
    justify-content: center;
  }

  .destination-video {
    display: none;
  }

  .marquee {
    margin-bottom: 50px;
    padding: 20px 20px 60px;
    font-size: 60px;
  }

  .google_login,
  #credential_picker_iframe {
    display: none !important;
  }

  .search_field button {
    font-size: 15px;
  }

  .search_field button i {
    font-size: 14px !important;
  }

  .search_input {
    padding: 0 15px 20px !important;
  }

  .disclaimer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .disclaimer button {
    margin-top: 14px;
  }

  .rlr-product-card--featured__body_home .rlr-product-detail-header__actions {
    position: relative !important;
    top: initial !important;
  }
  .vCard_section p{
    font-size: 20px;
  }
}

.rlr-wishlist-button.active {
  box-shadow: none !important;
}

@media(max-width:320px) {

  .mc4wp-form-fields .btn,
  .rlr-subscribe .btn {
    padding: 6px 0;
    width: 88px;
    font-size: 12px !important;
  }
}

