@charset "UTF-8";
/*! ==============================================================
<!-- Table of contents ==== -->

1. Bootstrap files
2. Bootstrap customization
    2.1 Custom variables
    2.2 Bootstrap variables
    2.3 Bootstrap mixins
3. Abstract
    3.1 Mixins
    3.2 Settings
    3.3 Utilities
4. Base
    4.1 Base
    4.2 Spacing
    4.3 Typography
5. Components
    5.1 Icon fonts
    5.2 Accordion
    5.3 Button
    5.4 Badge
    5.5 Logo
    5.6 Navigation
    5.7 Section heading
    5.8 Page Title
    5.9 Form control
    5.10 Fieldrow
    5.11 Form label
    5.12 Form select
    5.13 Radio
    5.14 Checkbox
    5.15 Pagination
    5.16 Step
    5.17 Photo uploader
    5.18 Action button
    5.19 Typography
    5.20 Input group
    5.21 Input range
    5.22 Breadcrumb
    5.23 Review star
    5.24 Media
    5.25 Image
    5.26 Secondary menu
    5.27 Read more
    5.28 Icon text
    5.29 Review card
    5.30 Avatar
    5.31 Rating star
    5.32 Recommend button
    5.33 Share
    5.34 Popover
    5.35 Copylink
    5.36 Calendar
    5.37 Icon card
    5.38 Tooltip
    5.39 Contact Detail Card
    5.40 Contact Details
    5.41 Product card
    5.42 Search results header
    5.43 Dropdown menu
    5.44 Banner input group
    5.45 Autocomplete
    5.46 Category card
    5.47 Banner
    5.48 Blog card
    5.49 Support
    5.50 Subscribe
    5.51 Team
    5.52 CTA
    5.53 Gallery
    5.54 Newsletter
    5.55 Destination card
    5.56 Wishlist
    5.57 Header Search
    5.58 Tooltip
    5.59 Rev Slider
    5.60 WooCommerce
    5.61 Data Table
    5.62 Sidebar Menu
    5.63 Lightbox
6. Layouts
    6.1 Masonary Grid
    6.2 Product Form
    6.3 Progress steps
    6.4 Product details
    6.5 Overview details
    6.6 Secondary menu description
    6.7 Product detail header
    6.8 Itinerary details
    6.9 Tabbed field
    6.10 Review Form
    6.11 Booking form
    6.12 Product filter
    6.13 Banner search
    6.14 Header layout
    6.15 Account
    6.16 Footer
    6.17 Blog Single
    6.18 Comment form
    6.19 Sidebar
    6.20 Support section
    6.21 Page banner
    6.22 Page header
    6.23 About Hero
    6.24 Contact Form
    6.25 Features
    6.26 Header Banner
    6.27 Logo Slider
    6.28 Metrics
    6.29 WooCommerce
    6.30 My Account
7. Pages
    7.1 Product detail page
    7.2 Search results
    7.3 Home
    7.4 Checkout
    7.5 Blog listings
    7.6 404 Error
    7.7 Account pages
    7.8 About page
    7.9 Contact page
    7.10 Landing Page
    7.11 Order Received
    7.12 Icon Fonts Page
============================================================== **/
/*!  ==============================================================
<!-- 1. Bootstrap files ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 2. Bootstrap customization ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 2.1 Custom variables ==== -->
============================================================== **/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700;800&display=swap");

/*!  ==============================================================
<!-- 2.2 Bootstrap variables ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 2.3 Bootstrap mixins ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 3. Abstract ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 3.1 Mixins ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 3.2 Settings ==== -->
============================================================== **/
:root {
  --white: #fff;
  --black: #000;
  --gray-00: #fcfcfd;
  --gray-01: #f9fafb;
  --gray-02: #f2f6fd;
  --gray-03: #eaedf0;
  --gray-04: #dbe4eb;
  --gray-05: #98a2b3;
  --gray-06: #667085;
  --gray-07: #546078;
  --gray-08: #344055;
  --gray-09: #1d293f;
  --gray-10: #101828;
  --brand-00: #f7fdfd;
  --brand-01: #eefbfa;
  --brand-02: #cef3f0;
  --brand-03: #9ce7e1;
  --brand-04: #7cdfd7;
  --brand-05: #5bd7cd;
  --brand-06: #3acfc3;
  --brand-07: #10a5b2;
  --brand-08: #22968e;
  --brand-09: #20837b;
  --brand-10: #14524d;
  --error-00: #fffbfa;
  --error-01: #fef2f1;
  --error-02: #fee3e1;
  --error-03: #fecbc8;
  --error-04: #fda29b;
  --error-05: #f97167;
  --error-06: #f04438;
  --error-07: #d92d20;
  --error-08: #b42318;
  --error-09: #912018;
  --error-10: #7a271a;
  --warning-00: #fffcf5;
  --warning-01: #fef1dc;
  --warning-02: #fef0c8;
  --warning-03: #fedf8b;
  --warning-04: #fec94d;
  --warning-05: #f5a524;
  --warning-06: #f78f08;
  --warning-07: #dd6903;
  --warning-08: #b54708;
  --warning-09: #91370d;
  --warning-10: #7b2f0e;
  --success-00: #f6fef9;
  --success-01: #edfdf3;
  --success-02: #d1fadf;
  --success-03: #a4f4c4;
  --success-04: #6de9a7;
  --success-05: #34d585;
  --success-06: #12b569;
  --success-07: #039654;
  --success-08: #027947;
  --success-09: #05613b;
  --success-10: #054d30;
  --red-00: #fbdae1;
  --red-01: #f8b4c3;
  --red-02: #f490a5;
  --red-03: #f06a87;
  --red-04: #ed456a;
  --red-05: #e91c48;
  --red-06: #cc143c;
  --red-07: #a71131;
  --red-08: #820d26;
  --red-09: #5d091b;
  --red-10: #2e050e;
  --accent-blue: #2e52e0;
  --accent-red: #ff3863;
  --accent-black: #080708;
  --accent-magenta: #ba2c73;
  --brand: var(--brand-07);
  --body-color: var(--gray-07);
  --heading-color: var(--gray-09);
  --neutral-color: var(--gray-05);
  --success-color: var(--success-05);
  --warning-color: var(--warning-06);
  --danger-color: var(--red-05);
  --danger-color-00: var(--red-00);
  --danger-color-03: var(--red-00);
  --danger-color-04: var(--red-00);
  --brand-bg: rgba(40, 175, 166, 0.08);
  --form-border-color: var(--body-color);
  --form-border-color-light: var(--greay-04);
  /*!  ==============================================================
	  Spacing variables
	  ============================================================== **/
  --spacing-1: 0.125rem;
  --spacing-2: 0.25rem;
  --spacing-3: 0.5rem;
  --spacing-4: 0.75rem;
  --spacing-5: 1rem;
  --spacing-6: 1.25rem;
  --spacing-7: calc(1.275rem + 0.3vw);
  --spacing-8: calc(1.325rem + 0.9vw);
  --spacing-9: calc(1.375rem + 1.5vw);
  --spacing-10: calc(1.425rem + 2.1vw);
  --spacing-11: calc(1.475rem + 2.7vw);
  --spacing-12: calc(1.525rem + 3.3vw);
  --spacing-13: calc(1.575rem + 3.9vw);
  --spacing-14: calc(1.625rem + 4.5vw);
  --spacing-15: calc(1.725rem + 5.7vw);
  --spacing-16: calc(1.825rem + 6.9vw);
  --spacing-17: calc(1.925rem + 8.1vw);
  --spacing-18: calc(1.975rem + 8.7vw);
  --spacing-19: calc(2.025rem + 9.3vw);
  --spacing-20: calc(2.625rem + 16.5vw);
  /*!  ==============================================================
	<!-- Global spacing ==== -->
	============================================================== **/
  --global-section-spacing-top: var(--spacing-17);
  --global-section-heading-spacing-bottom: var(--spacing-12);
}

@media (min-width: 1200px) {
  :root {
    --spacing-7: 1.5rem;
    --spacing-8: 2rem;
    --spacing-9: 2.5rem;
    --spacing-10: 3rem;
    --spacing-11: 3.5rem;
    --spacing-12: 4rem;
    --spacing-13: 4.5rem;
    --spacing-14: 5rem;
    --spacing-15: 6rem;
    --spacing-16: 7rem;
    --spacing-17: 8rem;
    --spacing-18: 8.5rem;
    --spacing-19: 9rem;
    --spacing-20: 15rem;
  }
}

.rlr-body--yellow {
  --brand: var(--warning-color);
  --brand-00: #fffcf5;
  --brand-01: #fef1dc;
  --brand-02: #fef0c8;
  --brand-03: #fedf8b;
  --brand-04: #fec94d;
  --brand-05: #f5a524;
  --brand-06: #f78f08;
  --brand-07: #dd6903;
  --brand-08: #b54708;
  --brand-09: #91370d;
  --brand-10: #7b2f0e;
}

.rlr-body--red {
  --brand: var(--danger-color);
  --brand-00: #fbdae1;
  --brand-01: #f8b4c3;
  --brand-02: #f490a5;
  --brand-03: #f06a87;
  --brand-04: #ed456a;
  --brand-05: #e91c48;
  --brand-06: #cc143c;
  --brand-07: #a71131;
  --brand-08: #820d26;
  --brand-09: #5d091b;
  --brand-10: #2e050e;
}

/*!  ==============================================================
<!-- 4. Base ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 4.1 Base ==== -->
============================================================== **/
button:focus,
input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

* {
  margin: 0;
  padding: 0;
}

img {
  height: auto;
  max-width: 100%;
}

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  -webkit-transition-duration: 50ms;
  -o-transition-duration: 50ms;
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  -webkit-transition-delay: 50ms;
  -o-transition-delay: 50ms;
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  -webkit-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  -webkit-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.45s;
  -o-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  -webkit-transition-duration: 0.55s;
  -o-transition-duration: 0.55s;
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.55s;
  -o-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  -webkit-transition-duration: 0.65s;
  -o-transition-duration: 0.65s;
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.65s;
  -o-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  -webkit-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  -webkit-transition-duration: 0.75s;
  -o-transition-duration: 0.75s;
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  -webkit-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  -webkit-transition-duration: 0.85s;
  -o-transition-duration: 0.85s;
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.85s;
  -o-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  -webkit-transition-duration: 0.9s;
  -o-transition-duration: 0.9s;
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  -webkit-transition-duration: 0.95s;
  -o-transition-duration: 0.95s;
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.95s;
  -o-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  -webkit-transition-duration: 1.05s;
  -o-transition-duration: 1.05s;
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.05s;
  -o-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  -webkit-transition-duration: 1.1s;
  -o-transition-duration: 1.1s;
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.1s;
  -o-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  -webkit-transition-duration: 1.15s;
  -o-transition-duration: 1.15s;
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.15s;
  -o-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  -webkit-transition-duration: 1.2s;
  -o-transition-duration: 1.2s;
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  -webkit-transition-duration: 1.25s;
  -o-transition-duration: 1.25s;
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.25s;
  -o-transition-delay: 1.25s;
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  -webkit-transition-duration: 1.3s;
  -o-transition-duration: 1.3s;
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  -webkit-transition-duration: 1.35s;
  -o-transition-duration: 1.35s;
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.35s;
  -o-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  -webkit-transition-duration: 1.4s;
  -o-transition-duration: 1.4s;
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  -webkit-transition-duration: 1.45s;
  -o-transition-duration: 1.45s;
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.45s;
  -o-transition-delay: 1.45s;
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  -webkit-transition-duration: 1.5s;
  -o-transition-duration: 1.5s;
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  -webkit-transition-duration: 1.55s;
  -o-transition-duration: 1.55s;
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.55s;
  -o-transition-delay: 1.55s;
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  -webkit-transition-duration: 1.6s;
  -o-transition-duration: 1.6s;
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.6s;
  -o-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  -webkit-transition-duration: 1.65s;
  -o-transition-duration: 1.65s;
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.65s;
  -o-transition-delay: 1.65s;
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  -webkit-transition-duration: 1.7s;
  -o-transition-duration: 1.7s;
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  -webkit-transition-duration: 1.75s;
  -o-transition-duration: 1.75s;
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.75s;
  -o-transition-delay: 1.75s;
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  -webkit-transition-duration: 1.8s;
  -o-transition-duration: 1.8s;
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.8s;
  -o-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  -webkit-transition-duration: 1.85s;
  -o-transition-duration: 1.85s;
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.85s;
  -o-transition-delay: 1.85s;
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  -webkit-transition-duration: 1.9s;
  -o-transition-duration: 1.9s;
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.9s;
  -o-transition-delay: 1.9s;
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  -webkit-transition-duration: 1.95s;
  -o-transition-duration: 1.95s;
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.95s;
  -o-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  -webkit-transition-duration: 2s;
  -o-transition-duration: 2s;
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  -webkit-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  -webkit-transition-duration: 2.05s;
  -o-transition-duration: 2.05s;
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.05s;
  -o-transition-delay: 2.05s;
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  -webkit-transition-duration: 2.1s;
  -o-transition-duration: 2.1s;
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.1s;
  -o-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  -webkit-transition-duration: 2.15s;
  -o-transition-duration: 2.15s;
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.15s;
  -o-transition-delay: 2.15s;
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  -webkit-transition-duration: 2.2s;
  -o-transition-duration: 2.2s;
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.2s;
  -o-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  -webkit-transition-duration: 2.25s;
  -o-transition-duration: 2.25s;
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.25s;
  -o-transition-delay: 2.25s;
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  -webkit-transition-duration: 2.3s;
  -o-transition-duration: 2.3s;
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.3s;
  -o-transition-delay: 2.3s;
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  -webkit-transition-duration: 2.35s;
  -o-transition-duration: 2.35s;
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.35s;
  -o-transition-delay: 2.35s;
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  -webkit-transition-duration: 2.4s;
  -o-transition-duration: 2.4s;
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.4s;
  -o-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  -webkit-transition-duration: 2.45s;
  -o-transition-duration: 2.45s;
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.45s;
  -o-transition-delay: 2.45s;
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  -webkit-transition-duration: 2.5s;
  -o-transition-duration: 2.5s;
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.5s;
  -o-transition-delay: 2.5s;
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  -webkit-transition-duration: 2.55s;
  -o-transition-duration: 2.55s;
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.55s;
  -o-transition-delay: 2.55s;
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  -webkit-transition-duration: 2.6s;
  -o-transition-duration: 2.6s;
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.6s;
  -o-transition-delay: 2.6s;
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  -webkit-transition-duration: 2.65s;
  -o-transition-duration: 2.65s;
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.65s;
  -o-transition-delay: 2.65s;
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  -webkit-transition-duration: 2.7s;
  -o-transition-duration: 2.7s;
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.7s;
  -o-transition-delay: 2.7s;
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  -webkit-transition-duration: 2.75s;
  -o-transition-duration: 2.75s;
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.75s;
  -o-transition-delay: 2.75s;
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  -webkit-transition-duration: 2.8s;
  -o-transition-duration: 2.8s;
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.8s;
  -o-transition-delay: 2.8s;
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  -webkit-transition-duration: 2.85s;
  -o-transition-duration: 2.85s;
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.85s;
  -o-transition-delay: 2.85s;
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  -webkit-transition-duration: 2.9s;
  -o-transition-duration: 2.9s;
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.9s;
  -o-transition-delay: 2.9s;
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  -webkit-transition-duration: 2.95s;
  -o-transition-duration: 2.95s;
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.95s;
  -o-transition-delay: 2.95s;
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  -webkit-transition-duration: 3s;
  -o-transition-duration: 3s;
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  -webkit-transition-delay: 3s;
  -o-transition-delay: 3s;
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"],
body[data-aos-easing="linear"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  -o-transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"],
body[data-aos-easing="ease-in"] [data-aos] {
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"],
body[data-aos-easing="ease-out"] [data-aos] {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"],
body[data-aos-easing="ease-in-out"] [data-aos] {
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"],
body[data-aos-easing="ease-in-back"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"],
body[data-aos-easing="ease-out-back"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"],
body[data-aos-easing="ease-in-out-back"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"],
body[data-aos-easing="ease-in-sine"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  -o-transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"],
body[data-aos-easing="ease-out-sine"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"],
body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -o-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"],
body[data-aos-easing="ease-in-quad"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  -o-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"],
body[data-aos-easing="ease-out-quad"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"],
body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -o-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"],
body[data-aos-easing="ease-in-cubic"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  -o-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"],
body[data-aos-easing="ease-out-cubic"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"],
body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -o-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"],
body[data-aos-easing="ease-in-quart"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  -o-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"],
body[data-aos-easing="ease-out-quart"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"],
body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -o-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 1;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: opacity, transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  -webkit-transform: translate3d(0, 100px, 0);
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  -webkit-transform: translate3d(-100px, 100px, 0);
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  -webkit-transform: translate3d(100px, 100px, 0);
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  -webkit-transform: translate3d(-100px, -100px, 0);
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  -webkit-transform: translate3d(100px, -100px, 0);
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: opacity, transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}

[data-aos="zoom-in"] {
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}

[data-aos="zoom-in-up"] {
  -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos="zoom-in-down"] {
  -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos="zoom-in-right"] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos="zoom-in-left"] {
  -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos="zoom-out"] {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos="zoom-out-down"] {
  -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos="zoom-out-right"] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos="zoom-out-left"] {
  -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos="flip-left"] {
  -webkit-transform: perspective(2500px) rotateY(-100deg);
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-right"] {
  -webkit-transform: perspective(2500px) rotateY(100deg);
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-up"] {
  -webkit-transform: perspective(2500px) rotateX(-100deg);
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
  transform: perspective(2500px) rotateX(0);
}

[data-aos="flip-down"] {
  -webkit-transform: perspective(2500px) rotateX(100deg);
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
  transform: perspective(2500px) rotateX(0);
}

/*!  ==============================================================
<!-- 4.2 Spacing ==== -->
============================================================== **/
.rlr-globalSection-spacing-top {
  --global-section-spacing-top: var(--spacing-15);
  margin-top: var(--global-section-spacing-top);
}

.rlr-globalSection-spacing-top__heading {
  --global-section-heading-spacing-bottom: var(--spacing-5);
  padding-bottom: var(--global-section-heading-spacing-bottom);
}

/*!  ==============================================================
<!-- 4.3 Typography ==== -->
============================================================== **/
.rlr-article .content h2,
.rlr-article .content h3,
.rlr-banner-input-group__label mark,
.rlr-banner-splide__book-now.rlr-button,
.rlr-banner-splide__slogan,
.rlr-banner-splide__temperature span,
.rlr-button,
.rlr-button--large,
.rlr-button--medium,
.rlr-button--small,
.rlr-checkout .order-total .woocommerce-Price-amount,
.rlr-checkout .order-total td,
.rlr-checkout .order-total th,
.rlr-cta--no-button p,
.rlr-cta p,
.rlr-destination-card__info--main,
.rlr-footer__getintouch__col--title p,
.rlr-footer__legal__row--top,
.rlr-footer__menu__col--lg .rlr-footer__menu__col__letstalk,
.rlr-footer__menu__col h4,
.rlr-footer__quicklinks--title,
.rlr-navigation__link,
.rlr-newsletter__content .rlr-newsletter__title,
.rlr-page-title__title,
.rlr-product-card--v3 .rlr-product-card__price,
.rlr-product-card--v3 .rlr-product-card__title,
.rlr-product-card__price,
.rlr-product-card__title,
.rlr-product-form .type-h2,
.rlr-search-results-page__card-wrapper .sticky .rlr-product-card__title,
.rlr-section__title--main,
.rlr-share__title,
.rlr-step__text--active.type-sub-title,
.rlr-support .rlr-section__heading--sub,
.rlr-team-card--v2 .rlr-team-card__title>a,
.rlr-team-card__summary .rlr-team-card__about-row .rlr-team-card__aboutbox h4,
.rlr-team-card__summary .rlr-team-card__name,
.type-d1,
.type-h1,
.type-h2,
.type-h2-bold,
.type-h3,
.type-h4,
.type-h5,
.type-h6-medium,
.type-h6-semi-bold,
.type-sub-title,
.type-sub-title-medium,
.woocommerce-billing-fields h3,
.woocommerce-checkout-review-order-table td dl.variation dt.variation-BookingDetails span,
.woocommerce-column__title {
  color: var(--heading-color);
}

.mc4wp-form-fields__input,
.navigation-col>ul>li>a,
.navigation-dropdown-link,
.navigation-tabs-nav>li a,
.order-detail-card__footer a,
.order-detail-card__title,
.rlr-accordion__badge,
.rlr-accordion__button.accordion-button,
.rlr-action-button__custom,
.rlr-article .content,
.rlr-article .content__highlight,
.rlr-article .content blockquote .content-quote__author,
.rlr-article .rlr-comments__single__comment,
.rlr-article .rlr-comments__single__details .rlr-comments__single__nrow,
.rlr-article__author__about .rlr-article__author__designation,
.rlr-article__author__about .rlr-article__author__name,
.rlr-autocomplete__item-wrapper,
.rlr-autocomplete__sub-text,
.rlr-autocomplete__text,
.rlr-avatar__name,
.rlr-badge,
.rlr-banner-input-group__input,
.rlr-booking-card__button.rlr-button,
.rlr-booking-card__counter,
.rlr-booking-card__label,
.rlr-booking-card__message .rlr-icon-text__subtext,
.rlr-booking-card__message .rlr-icon-text__title,
.rlr-booking-card__result-title,
.rlr-breadcrumb__item,
.rlr-breadcrumb__item a,
.rlr-button--product-form-repeater,
.rlr-category-card__sub-title,
.rlr-checkout .cart-item-card__footer p,
.rlr-checkout .cart-item-card__title,
.rlr-checkout .cart-item-title,
.rlr-checkout .checkout__section-title span,
.rlr-checkout .form-row label,
.rlr-contact-detail-item__desc,
.rlr-contact-detail-item__title,
.rlr-data-table__cell .view,
.rlr-data-table__header-row th,
.rlr-destination-card__info--sub,
.rlr-dropdown__button,
.rlr-dropdown__item,
.rlr-footer__legal--inline li a,
.rlr-footer__legal__row--bottom,
.rlr-footer__menu__col__subscribe input.input,
.rlr-footer__menu__col ul a,
.rlr-form-label,
.rlr-form-label--checkbox,
.rlr-form-label--radio,
.rlr-icon-text__block span,
.rlr-icon-text__card-title,
.rlr-itinerary__room-type,
.rlr-itinerary__title,
.rlr-js-autocomplete__item-wrapper,
.rlr-js-autocomplete__sub-text,
.rlr-js-autocomplete__text,
.rlr-landing .rlr-contact-detail-item__title,
.rlr-media__page-counter,
.rlr-navigation .navigation-list .navigation-list-heading>a,
.rlr-newsletter__content .rlr-newsletter__ps,
.rlr-order-dashboard-nav__link,
.rlr-overview-detail__icon-name,
.rlr-overview-detail__label,
.rlr-page-title__desc,
.rlr-pagination__page-link--form,
.rlr-product-card__icon-text,
.rlr-product-card__sub-title,
.rlr-product-detail-secondary-menu__tabitem,
.rlr-product-filters__label,
.rlr-rating-stars__label,
.rlr-readmore-desc__content,
.rlr-readmore-desc__readmore,
.rlr-recommendation-button__label,
.rlr-review-card__review-date,
.rlr-review-card__title-review,
.rlr-review-form__cancel,
.rlr-review-form__submit,
.rlr-review-stars,
.rlr-search-results-header__label,
.rlr-search-results-header__value,
.rlr-secondary-menu-desc__list,
.rlr-secondary-menu-desc__review-link,
.rlr-secondary-menu-desc__show-more,
.rlr-share__button-cancel,
.rlr-share__button-share,
.rlr-sidebar-menu__link,
.rlr-sidebar .wp-block-group__inner-container>h2,
.rlr-sidebar .wp-block-search__label,
.rlr-sidebar h4,
.rlr-step__text .type-sub-title,
.rlr-subscribe__input,
.rlr-team-card--v2 .rlr-team-card__desc,
.rlr-team-card--v2 .rlr-team-card__subtitle,
.rlr-team-card__summary .rlr-team-card__about-row .rlr-team-card__aboutbox,
.rlr-team-card__summary .rlr-team-card__designation,
.rlr-team-card__summary .rlr-team-card__sociallinks,
.rlr-text-card--numbered .rlr-text-card__title,
.rlr-text-card__desc,
.type-body,
.type-body-medium,
.type-body-semibold,
.type-lead,
.type-lead-medium,
.type-lead-semibold,
.woocommerce-details__desc,
.woocommerce-details__title,
.woocommerce-order-details__title,
.woocommerce-thankyou-order-details li,
.woocommerce-thankyou-order-details li .woocommerce-Price-amount,
.woocommerce-thankyou-order-details li .woocommerce-Price-currencySymbol,
.woocommerce-thankyou-order-details li>span,
.woocommerce .shop_table td,
.woocommerce .shop_table tfoot td,
.woocommerce .shop_table tfoot td strong,
.woocommerce .shop_table tfoot th,
.woocommerce button.button.alt {
  color: var(--body-color);
}

.rlr-banner-splide__slogan,
.type-d1 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  letter-spacing: -2.05px;
}

@media (min-width: 1200px) {

  .rlr-banner-splide__slogan,
  .type-d1 {
    font-size: 4rem;
  }
}

.rlr-cta h2,
.type-d2 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 500;
  letter-spacing: -2.05px;
}

@media (min-width: 1200px) {

  .rlr-cta h2,
  .type-d2 {
    font-size: 3.5rem;
  }
}

.rlr-newsletter__content .rlr-newsletter__title,
.rlr-page-title__title,
.rlr-search-results-page__card-wrapper .sticky .rlr-product-card__title,
.type-h1 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  letter-spacing: -2.05px;
}

@media (min-width: 1200px) {

  .rlr-newsletter__content .rlr-newsletter__title,
  .rlr-page-title__title,
  .rlr-search-results-page__card-wrapper .sticky .rlr-product-card__title,
  .type-h1 {
    font-size: 3rem;
  }
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slogan {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  letter-spacing: -2.05px;
}

@media (min-width: 1200px) {
  .rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slogan {
    font-size: 3rem;
  }
}

.rlr-footer__getintouch__col--title p,
.rlr-footer__menu__col--lg .rlr-footer__menu__col__letstalk,
.rlr-product-form .type-h2,
.rlr-team-card__summary .rlr-team-card__name,
.type-h2 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  letter-spacing: -0.64px;
  line-height: 1.5;
}

@media (min-width: 1200px) {

  .rlr-footer__getintouch__col--title p,
  .rlr-footer__menu__col--lg .rlr-footer__menu__col__letstalk,
  .rlr-product-form .type-h2,
  .rlr-team-card__summary .rlr-team-card__name,
  .type-h2 {
    font-size: 2.5rem;
  }
}

.type-h2-bold {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  letter-spacing: -0.38px;
  line-height: 1.5;
}

@media (min-width: 1200px) {
  .type-h2-bold {
    font-size: 2.5rem;
  }
}

.rlr-article .content h2,
.rlr-section__title--main,
.type-h3 {
  font-size: calc(1.35rem + 1.2vw);
  font-weight: 700;
  line-height: 1.33;
}

@media (min-width: 1200px) {

  .rlr-article .content h2,
  .rlr-section__title--main,
  .type-h3 {
    font-size: 2rem;
  }
}

.rlr-cta p,
.type-h4 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 600;
  letter-spacing: -0.51px;
}

@media (min-width: 1200px) {

  .rlr-cta p,
  .type-h4 {
    font-size: 2rem;
  }
}

.rlr-support-card__title {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 500;
  letter-spacing: -0.51px;
}

@media (min-width: 1200px) {
  .rlr-support-card__title {
    font-size: 2rem;
  }
}

.rlr-banner-input-group__label mark,
.rlr-product-card__price,
.rlr-support .rlr-section__heading--sub,
.type-h5 {
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 600;
  letter-spacing: -0.45px;
  line-height: 1.79;
}

@media (min-width: 1200px) {

  .rlr-banner-input-group__label mark,
  .rlr-product-card__price,
  .rlr-support .rlr-section__heading--sub,
  .type-h5 {
    font-size: .9rem;
  }
}

.rlr-booking-card__current-price,
.rlr-product-detail-header__title {
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 700;
  letter-spacing: -0.45px;
  line-height: 1.79;
}

@media (min-width: 1200px) {

  .rlr-booking-card__current-price,
  .rlr-product-detail-header__title {
    font-size: 1.75rem;
  }
}

.mc4wp-form-fields .btn,
.rlr-banner-splide__payment-desc,
.rlr-banner-splide__sub-title,
.rlr-footer__getintouch__col--title h4,
.rlr-footer__quicklinks,
.rlr-footer__quicklinks--expore,
.rlr-newsletter__content .rlr-newsletter__subtitle,
.rlr-newsletter__content p,
.rlr-section__title--sub,
.rlr-subscribe .btn,
.rlr-support-card__subtitle,
.rlr-support-card__text,
.type-h6-light {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 300;
}

@media (min-width: 1200px) {

  .mc4wp-form-fields .btn,
  .rlr-banner-splide__payment-desc,
  .rlr-banner-splide__sub-title,
  .rlr-footer__getintouch__col--title h4,
  .rlr-footer__quicklinks,
  .rlr-footer__quicklinks--expore,
  .rlr-newsletter__content .rlr-newsletter__subtitle,
  .rlr-newsletter__content p,
  .rlr-section__title--sub,
  .rlr-subscribe .btn,
  .rlr-support-card__subtitle,
  .rlr-support-card__text,
  .type-h6-light {
    font-size: 1rem;
  }
}

.rlr-category-card__title h4,
.rlr-team-card__thumb .rlr-slide-count,
.woocommerce-checkout-review-order-table thead .product-name {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
  letter-spacing: -0.38px;
}

@media (min-width: 1200px) {

  .rlr-category-card__title h4,
  .rlr-team-card__thumb .rlr-slide-count,
  .woocommerce-checkout-review-order-table thead .product-name {
    font-size: 1rem;
  }
}

.rlr-banner-splide__temperature span,
.rlr-button--large,
.rlr-checkout .order-total td,
.rlr-checkout .order-total th,
.rlr-cta--no-button p,
.rlr-footer__legal__row--top,
.rlr-footer__menu__col h4,
.rlr-team-card__summary .rlr-team-card__about-row .rlr-team-card__aboutbox h4,
.type-h6-medium,
.woocommerce-checkout-review-order-table td dl.variation dt.variation-BookingDetails span,
.woocommerce-column__title {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
  letter-spacing: -0.38px;
  line-height: 1.5;
}

@media (min-width: 1200px) {

  .rlr-banner-splide__temperature span,
  .rlr-button--large,
  .rlr-checkout .order-total td,
  .rlr-checkout .order-total th,
  .rlr-cta--no-button p,
  .rlr-footer__legal__row--top,
  .rlr-footer__menu__col h4,
  .rlr-team-card__summary .rlr-team-card__about-row .rlr-team-card__aboutbox h4,
  .type-h6-medium,
  .woocommerce-checkout-review-order-table td dl.variation dt.variation-BookingDetails span,
  .woocommerce-column__title {
    font-size: 1rem;
  }
}

.rlr-article .content h3,
.rlr-banner-splide__book-now.rlr-button,
.rlr-checkout .order-total .woocommerce-Price-amount,
.rlr-destination-card__info--main,
.rlr-footer__quicklinks--title,
.rlr-product-card__title,
.rlr-share__title,
.rlr-team-card--v2 .rlr-team-card__title>a,
.type-h6-semi-bold {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 600;
  letter-spacing: -0.38px;
}

@media (min-width: 1200px) {

  .rlr-article .content h3,
  .rlr-banner-splide__book-now.rlr-button,
  .rlr-checkout .order-total .woocommerce-Price-amount,
  .rlr-destination-card__info--main,
  .rlr-footer__quicklinks--title,
  .rlr-product-card__title,
  .rlr-share__title,
  .rlr-team-card--v2 .rlr-team-card__title>a,
  .type-h6-semi-bold {
    font-size: 1.3rem;
  }
}

.rlr-button,
.rlr-step__text--active.type-sub-title,
.type-sub-title {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.29px;
  line-height: 1.5;
}

.rlr-button--medium,
.rlr-button--small,
.rlr-navigation__link,
.rlr-product-card--v3 .rlr-product-card__price,
.rlr-product-card--v3 .rlr-product-card__title,
.type-sub-title-medium {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
}

.rlr-navigation .align-to-right .navigation-link,
.type-lead-light {
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: -0.29px;
  line-height: 1.5;
}

.mc4wp-form-fields__input,
.rlr-article .content__highlight,
.rlr-banner-input-group__input,
.rlr-contact-detail-item__desc,
.rlr-footer__menu__col__subscribe input.input,
.rlr-form-label,
.rlr-landing .rlr-contact-detail-item__title,
.rlr-page-title__desc,
.rlr-product-detail-secondary-menu__tabitem,
.rlr-search-results-header__label,
.rlr-subscribe__input,
.rlr-team-card--v2 .rlr-team-card__desc,
.rlr-team-card--v2 .rlr-team-card__subtitle,
.rlr-team-card__summary .rlr-team-card__designation,
.rlr-team-card__summary .rlr-team-card__sociallinks,
.rlr-text-card--numbered .rlr-text-card__title,
.rlr-text-card__desc,
.type-lead,
.woocommerce-details__desc,
.woocommerce-thankyou-order-details li>span {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.navigation-tabs-nav>li a,
.rlr-article .rlr-comments__single__details .rlr-comments__single__nrow,
.rlr-article__author__about .rlr-article__author__name,
.rlr-autocomplete__item-wrapper,
.rlr-autocomplete__text,
.rlr-avatar__name,
.rlr-checkout .cart-item-title,
.rlr-checkout .checkout__section-title span,
.rlr-js-autocomplete__item-wrapper,
.rlr-js-autocomplete__text,
.rlr-navigation .navigation-list .navigation-list-heading>a,
.rlr-pagination__page-link--form,
.rlr-search-results-header__value,
.rlr-share__button-cancel,
.rlr-share__button-share,
.type-lead-medium,
.woocommerce-order-details__title,
.woocommerce-thankyou-order-details li,
.woocommerce-thankyou-order-details li .woocommerce-Price-amount,
.woocommerce-thankyou-order-details li .woocommerce-Price-currencySymbol {
  font-size: .9rem;
  font-weight: 500;
}

.rlr-booking-card__button.rlr-button,
.rlr-booking-card__counter,
.rlr-dropdown__button,
.rlr-rating-stars__label,
.rlr-recommendation-button__label,
.rlr-review-card__title-review,
.rlr-review-form__cancel,
.rlr-review-form__submit,
.rlr-step__text .type-sub-title,
.type-lead-semibold,
.woocommerce button.button.alt {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.11;
}

.rlr-article .content,
.rlr-article .content blockquote .content-quote__author,
.rlr-article .rlr-comments__single__comment,
.rlr-article__author__about .rlr-article__author__designation,
.rlr-autocomplete__sub-text,
.rlr-booking-card__label,
.rlr-category-card__sub-title,
.rlr-checkout .form-row label,
.rlr-destination-card__info--sub,
.rlr-footer__legal--inline li a,
.rlr-footer__legal__row--bottom,
.rlr-footer__menu__col ul a,
.rlr-form-label--checkbox,
.rlr-form-label--radio,
.rlr-itinerary__room-type,
.rlr-js-autocomplete__sub-text,
.rlr-newsletter__content .rlr-newsletter__ps,
.rlr-order-dashboard-nav__link,
.rlr-overview-detail__label,
.rlr-product-card__sub-title,
.rlr-readmore-desc__content,
.rlr-readmore-desc__readmore,
.rlr-review-card__review-date,
.rlr-secondary-menu-desc__list,
.rlr-secondary-menu-desc__review-link,
.rlr-secondary-menu-desc__show-more,
.rlr-sidebar-menu__link,
.rlr-team-card__summary .rlr-team-card__about-row .rlr-team-card__aboutbox,
.type-body,
.woocommerce .shop_table td {
  font-size: 14px;
  letter-spacing: -0.26px;
}

.navigation-col>ul>li>a,
.navigation-dropdown-link,
.order-detail-card__footer a,
.rlr-action-button__custom,
.rlr-badge,
.rlr-booking-card__message .rlr-icon-text__subtext,
.rlr-booking-card__message .rlr-icon-text__title,
.rlr-booking-card__result-title,
.rlr-breadcrumb__item,
.rlr-breadcrumb__item a,
.rlr-button--product-form-repeater,
.rlr-checkout .cart-item-card__title,
.rlr-data-table__cell .view,
.rlr-data-table__header-row th,
.rlr-dropdown__item,
.rlr-icon-text__block span,
.rlr-icon-text__card-title,
.rlr-overview-detail__icon-name,
.rlr-product-card__icon-text,
.rlr-product-filters__label,
.rlr-review-stars,
.type-body-medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
}

.order-detail-card__title,
.rlr-accordion__badge,
.rlr-accordion__button.accordion-button,
.rlr-checkout .cart-item-card__footer p,
.rlr-contact-detail-item__title,
.rlr-itinerary__title,
.rlr-media__page-counter,
.rlr-sidebar .wp-block-group__inner-container>h2,
.rlr-sidebar .wp-block-search__label,
.rlr-sidebar h4,
.type-body-semibold,
.woocommerce-details__title,
.woocommerce .shop_table tfoot td,
.woocommerce .shop_table tfoot td strong,
.woocommerce .shop_table tfoot th {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
}

.order-detail-card__date,
.order-detail-card__item,
.rlr-article .content figure figcaption,
.rlr-article .rlr-comments__single__likerow button,
.rlr-booking-card__del-price,
.rlr-booking-card__info,
.rlr-booking-card__price-type,
.rlr-booking-card__total-price,
.rlr-checkout-list__text,
.rlr-checkout .cart-item-card__date,
.rlr-checkout .cart-item-card__item-number,
.rlr-checkout .cart-item-card__item-title,
.rlr-copylink__title,
.rlr-icon-text--anchor,
.rlr-icon-text__subtext,
.rlr-icon-text__title,
.rlr-icon-text a,
.rlr-pagination__page-link--counter,
.rlr-pagination__page-link--next,
.rlr-pagination__page-link--prev,
.rlr-postcard__author,
.rlr-postcard__title,
.rlr-product-card__from,
.rlr-product-card__rating-text,
.rlr-product-card__relates,
.type-small,
.wc_payment_method .form-row.woocommerce-SavedPaymentMethods-saveNew label,
.wc_payment_method label:not(.payment_box label),
.woocommerce-thankyou-order-details li .woocommerce-order-overview__payment-method,
.woocommerce table.shop_table .cart-subtotal td,
.woocommerce table.shop_table .cart-subtotal th {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
}

/*!  ==============================================================
<!-- 5. Components ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 5.1 Icon fonts ==== -->
============================================================== **/
@font-face {
  font-display: swap;
  font-family: flaticon;
  src: url(../assets/emprise-font-icons/font/flaticon.ttf) format("truetype"), url(../assets/emprise-font-icons/font/flaticon.woff?94a28fd361079e50f7a58acd761176a4) format("woff"),
    url(../assets/emprise-font-icons/font/flaticon.woff2?94a28fd361079e50f7a58acd761176a4) format("woff2"), url(../assets/emprise-font-icons/font/flaticon.eot?94a28fd361079e50f7a58acd761176a4#iefix) format("embedded-opentype"),
    url(../assets/emprise-font-icons/font/flaticon.svg?94a28fd361079e50f7a58acd761176a4#flaticon) format("svg");
}

i[class*=" flaticon-"]:before,
i[class^="flaticon-"]:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: flaticon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: 400 !important;
  text-transform: none;
}

.flaticon-bungee:before {
  content: "";
}

.flaticon-beach:before {
  content: "";
}

.flaticon-city-tours:before {
  content: "";
}

.flaticon-hiking:before {
  content: "";
}

.flaticon-jungle:before {
  content: "";
}

.flaticon-chevron:before {
  content: "";
}

.flaticon-left-chevron:before {
  content: "";
}

.flaticon-down-chevron:before {
  content: "";
}

.flaticon-up-chevron:before {
  content: "";
}

.flaticon-map-marker:before {
  content: "";
}

.flaticon-outline-down:before {
  content: "";
}

.flaticon-calendar:before {
  content: "";
}

.flaticon-search:before {
  content: "";
}

.flaticon-heart:before {
  content: "";
}

.flaticon-heart-1:before {
  content: "";
}

.flaticon-share:before {
  content: "";
}

.flaticon-globe:before {
  content: "";
}

.flaticon-fast-forward:before {
  content: "";
}

.flaticon-back-button:before {
  content: "";
}

.flaticon-three-o-clock-clock:before {
  content: "";
}

.flaticon-check:before {
  content: "";
}

.flaticon-email:before {
  content: "";
}

.flaticon-headset:before {
  content: "";
}

.flaticon-headset-1:before {
  content: "";
}

.flaticon-plus:before {
  content: "";
}

.flaticon-globe-1:before {
  content: "";
}

.flaticon-right-arrows:before {
  content: "";
}

.flaticon-left-arrow:before {
  content: "";
}

.flaticon-copyright:before {
  content: "";
}

.flaticon-deals:before {
  content: "";
}

.flaticon-icon-chopper:before {
  content: "";
}

.flaticon-special:before {
  content: "";
}

.flaticon-star-plus:before {
  content: "";
}

.flaticon-timer:before {
  content: "";
}

.flaticon-star:before {
  content: "";
}

.flaticon-star-1:before {
  content: "";
}

.flaticon-gallery:before {
  content: "";
}

.flaticon-share-1:before {
  content: "";
}

.flaticon-cake:before {
  content: "";
}

.flaticon-activity-level:before {
  content: "";
}

.flaticon-group-size:before {
  content: "";
}

.flaticon-walking:before {
  content: "";
}

.flaticon-faqs:before {
  content: "";
}

.flaticon-inclusions:before {
  content: "";
}

.flaticon-overview:before {
  content: "";
}

.flaticon-reviews:before {
  content: "";
}

.flaticon-like:before {
  content: "";
}

.flaticon-minus:before {
  content: "";
}

.flaticon-check-rounded:before {
  content: "";
}

.flaticon-cross-rounded:before {
  content: "";
}

.flaticon-facebook:before {
  content: "";
}

.flaticon-twitter:before {
  content: "";
}

.flaticon-instagram:before {
  content: "";
}

.flaticon-email-1:before {
  content: "";
}

.flaticon-whatsapp:before {
  content: "";
}

.flaticon-messenger:before {
  content: "";
}

.flaticon-copy:before {
  content: "";
}

.flaticon-comment-left:before {
  content: "";
}

.flaticon-cancellation:before {
  content: "";
}

.flaticon-plus-filled:before {
  content: "";
}

.flaticon-minus-filled:before {
  content: "";
}

.flaticon-cut:before {
  content: "";
}

.flaticon-full-screen:before {
  content: "";
}

.flaticon-video-camera:before {
  content: "";
}

.flaticon-sliders:before {
  content: "";
}

.flaticon-product:before {
  content: "";
}

.flaticon-sidebar:before {
  content: "";
}

.flaticon-sidebar-1:before {
  content: "";
}

.flaticon-dimensions:before {
  content: "";
}

.flaticon-add-user:before {
  content: "";
}

.flaticon-user:before {
  content: "";
}

.flaticon-padlock:before {
  content: "";
}

.flaticon-verified:before {
  content: "";
}

.flaticon-dashboard:before {
  content: "";
}

.flaticon-add-list:before {
  content: "";
}

.flaticon-feedback:before {
  content: "";
}

.flaticon-search-1:before {
  content: "";
}

.flaticon-phone-receiver-silhouette:before {
  content: "";
}

.flaticon-menu:before {
  content: "";
}

.flaticon-menu-1:before {
  content: "";
}

.flaticon-close:before {
  content: "";
}

.flaticon-right:before {
  content: "";
}

.flaticon-left:before {
  content: "";
}

.flaticon-minus-1:before {
  content: "";
}

.flaticon-plus-1:before {
  content: "";
}

.flaticon-up-arrow:before {
  content: "";
}

.flaticon-down:before {
  content: "";
}

.flaticon-check-mark:before {
  content: "";
}

.flaticon-multiply:before {
  content: "";
}

.flaticon-upload:before {
  content: "";
}

.flaticon-toggle:before {
  content: "";
}

.flaticon-toggle-1:before {
  content: "";
}

.flaticon-menu-2:before {
  content: "";
}

.flaticon-login:before {
  content: "";
}

.flaticon-credit-cards-payment:before {
  content: "";
}

.flaticon-destination:before {
  content: "";
}

.flaticon-carbon-carbon-for-ibm-dotcom:before {
  content: "";
}

.flaticon-carbon-delivery-parcel:before {
  content: "";
}

.flaticon-carbon-download:before {
  content: "";
}

.flaticon-carbon-earth-southeast-asia-filled:before {
  content: "";
}

.flaticon-carbon-dashboard:before {
  content: "";
}

.flaticon-carbon-location:before {
  content: "";
}

.flaticon-carbon-login:before {
  content: "";
}

.flaticon-carbon-logout:before {
  content: "";
}

.flaticon-carbon-money:before {
  content: "";
}

.flaticon-carbon-tall-man:before {
  content: "";
}

.flaticon-carbon-user:before {
  content: "";
}

.flaticon-carbon-block-storage:before {
  content: "";
}

.flaticon-carbon-box:before {
  content: "";
}

.flaticon-carbon-delivery:before {
  content: "";
}

.flaticon-carbon-result:before {
  content: "";
}

.flaticon-carbon-shopping-cart:before {
  content: "";
}

.flaticon-carbon-wallet:before {
  content: "";
}

.flaticon-fluent-hand-left-20-regular:before {
  content: "";
}

.flaticon-gridicons-cross-circle:before {
  content: "";
}

.flaticon-ion-hand-right-outline:before {
  content: "";
}

.flaticon-ph-hand-waving-light-1:before {
  content: "";
}

.flaticon-ph-hand-waving-light:before {
  content: "";
}

.flaticon-ph-hand-waving:before {
  content: "";
}

.flaticon-seen:before {
  content: "";
}

.flaticon-cancel:before {
  content: "";
}

.flaticon-checked:before {
  content: "";
}

.flaticon-cross:before {
  content: "";
}

.flaticon-check-1:before {
  content: "";
}

.flaticon-hotel-bed:before {
  content: "";
}

.flaticon-telephone:before {
  content: "";
}

.flaticon-information-button:before {
  content: "";
}

.flaticon-info:before {
  content: "";
}

.flaticon-support:before {
  content: "";
}

.flaticon-eiffel-tower:before {
  content: "";
}

.flaticon-destination-1:before {
  content: "";
}

.flaticon-bus:before {
  content: "";
}

.flaticon-luggage:before {
  content: "";
}

.flaticon-ticket:before {
  content: "";
}

.flaticon-dinner:before {
  content: "";
}

.flaticon-bag:before {
  content: "";
}

.flaticon-ball:before {
  content: "";
}

.flaticon-trailer:before {
  content: "";
}

.flaticon-cable-car:before {
  content: "";
}

.flaticon-air-balloon:before {
  content: "";
}

.flaticon-canoe:before {
  content: "";
}

.flaticon-dish:before {
  content: "";
}

.flaticon-bag-1:before {
  content: "";
}

.flaticon-tourism:before {
  content: "";
}

.flaticon-beach-1:before {
  content: "";
}

.flaticon-boat:before {
  content: "";
}

.flaticon-deck:before {
  content: "";
}

.flaticon-ice-cream:before {
  content: "";
}

.flaticon-car:before {
  content: "";
}

.flaticon-protection:before {
  content: "";
}

.flaticon-coconut:before {
  content: "";
}

.flaticon-swimming:before {
  content: "";
}

.flaticon-pisa-tower:before {
  content: "";
}

.flaticon-room:before {
  content: "";
}

.flaticon-icecream-cone:before {
  content: "";
}

.flaticon-luggage-1:before {
  content: "";
}

.flaticon-rope:before {
  content: "";
}

.flaticon-travel-insurance:before {
  content: "";
}

.flaticon-phone-ringing:before {
  content: "";
}

.flaticon-no-smoking:before {
  content: "";
}

.flaticon-sunny:before {
  content: "";
}

.flaticon-sunrise:before {
  content: "";
}

.flaticon-swimming-pool:before {
  content: "";
}

.flaticon-traveling:before {
  content: "";
}

.flaticon-train:before {
  content: "";
}

.flaticon-umbrella:before {
  content: "";
}

.flaticon-wine:before {
  content: "";
}

.flaticon-bathtub:before {
  content: "";
}

.flaticon-camera:before {
  content: "";
}

.flaticon-compass:before {
  content: "";
}

.flaticon-cycling:before {
  content: "";
}

.flaticon-hot-air-balloon:before {
  content: "";
}

.flaticon-key:before {
  content: "";
}

.flaticon-map:before {
  content: "";
}

.flaticon-mountain:before {
  content: "";
}

.flaticon-nature:before {
  content: "";
}

.flaticon-photo-album:before {
  content: "";
}

.flaticon-shopping:before {
  content: "";
}

.flaticon-suitcase:before {
  content: "";
}

.flaticon-surf-board:before {
  content: "";
}

.flaticon-van:before {
  content: "";
}

.flaticon-water-bottle:before {
  content: "";
}

.flaticon-yacht:before {
  content: "";
}

.flaticon-temple:before {
  content: "";
}

.flaticon-art:before {
  content: "";
}

.flaticon-joker-hat:before {
  content: "";
}

.flaticon-hall:before {
  content: "";
}

.flaticon-baking:before {
  content: "";
}

.flaticon-fireworks:before {
  content: "";
}

.flaticon-history:before {
  content: "";
}

.flaticon-parachute:before {
  content: "";
}

.flaticon-jeep:before {
  content: "";
}

.flaticon-safari:before {
  content: "";
}

.flaticon-jeep-1:before {
  content: "";
}

.flaticon-museum:before {
  content: "";
}

.flaticon-music-notes:before {
  content: "";
}

.flaticon-pawn:before {
  content: "";
}

.flaticon-central-park:before {
  content: "";
}

.flaticon-alpine:before {
  content: "";
}

.flaticon-ice-skate:before {
  content: "";
}

.flaticon-insert-picture-icon:before {
  content: "";
}

.flaticon-map-1:before {
  content: "";
}

.flaticon-play-button-arrowhead:before {
  content: "";
}

/*!  ==============================================================
<!-- 5.2 Accordion ==== -->
============================================================== **/
.rlr-accordion__item {
  border: 2px solid var(--gray-02);
}

.rlr-accordion__item,
.rlr-accordion__item.accordion-item:first-of-type {
  border-radius: var(--spacing-5) var(--spacing-5) 0 0;
}

.rlr-accordion__body {
  padding: var(--spacing-9) var(--spacing-7);
}

.rlr-accordion__badge {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: var(--brand);
  border-radius: 50%;
  color: var(--brand-02);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin-right: var(--spacing-5);
  min-height: 2rem;
  min-width: 2rem;
}

.rlr-accordion__button.accordion-button {
  background: var(--gray-00);
  border-bottom-color: var(--gray-03);
  border-radius: var(--spacing-5) var(--spacing-5) 0 0;
  color: var(--heading-color);
  padding: var(--spacing-7) var(--spacing-5);
}

.rlr-accordion__button.accordion-button:after {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTEuNSAxOC41IDgtOC41LTgtOC41IiBzdHJva2U9IiNDRUQ0RDkiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=") no-repeat;
}

.rlr-accordion__button.accordion-button:not(.collapsed):after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rlr-accordion__button.accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*!  ==============================================================
<!-- 5.3 Button ==== -->
============================================================== **/
.rlr-button {
  --border-radius: 0;
  --button-background-color: transparent;
  --padding: 0.5em;
  --button-text-color: var(--white);
  --button-border-color: var(--neutral-color);
  --button-font-weight: 300;
  --button-font-weight-bold: 500;
  background-color: var(--button-background-color);
  border-radius: var(--border-radius);
  color: var(--white);
  white-space: nowrap;
}

.rlr-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rlr-button--google {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: var(--white);
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--heading-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  justify-content: center;
  text-align: center;
}

.rlr-button--google:before {
  background: url(../assets/images/google-signin.png) no-repeat 0 0;
  content: "";
  display: inline-block;
  height: 25px;
  margin-right: 10px;
  width: 25px;
}

.rlr-button--form-tooltip .rlr-icon-font {
  color: var(--brand);
  font-size: 1.5rem;
}

.rlr-button--form-tooltip .rlr-icon-font:hover {
  color: var(--brand-04);
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

.rlr-button--fullwidth {
  width: 100%;
}

.rlr-button--primary {
  background: var(--brand);
  border-radius: var(--spacing-3);
  color: var(--white);
  height: 44px;
  width: 100%;
}

.rlr-button--primary:hover {
  color: var(--white);
}

.rlr-button--search {
  padding: 40px;
}

.rlr-button--large {
  padding: var(--spacing-5) var(--spacing-8);
}

.rlr-button--large .rlr-icon-font {
  font-size: 2rem;
  line-height: 0;
}

.rlr-button--medium {
  font-weight: var(--button-font-weight-bold);
  padding: var(--spacing-5) var(--spacing-6);
}

.rlr-button--small {
  padding: var(--spacing-3) var(--spacing-5);
}

.rlr-button--rounded {
  border-radius: 2rem;
}

.rlr-button--circle {
  border-radius: 50%;
  height: 3rem;
  padding: 1rem;
  width: 3rem;
}

.rlr-button--carousel {
  background: transparent;
  border: 2px solid var(--gray-04);
  border-radius: 50%;
  color: var(--neutral-color);
}

@media (min-width: 576px) {
  .rlr-button--carousel {
    height: 3rem;
    padding: 1rem;
    width: 3rem;
    display: flex;
    justify-content: 'center';
    justify-content: center;
    align-items: center;
  }

  .rlr-button--carousel .rlr-icon-font {
    font-size: 24px;
    line-height: 52px;
    margin-top: 4px;
  }
}

.rlr-button--carousel.button--next {
  background: var(--brand);
  color: var(--brand-01);
}

.rlr-button--carousel:hover {
  background-color: var(--brand-08);
  border-color: var(--brand);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--white);
}

.rlr-button--carousel.button--previous {
  color: var(--gray-03);
  background: var(--brand);
}

.rlr-button--carousel.button--previous:hover {
  background: var(--brand);
  color: var(--white);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.rlr-button--outline {
  stroke: var(--body-color);
  border: 1px solid var(--button-border-color);
}

.rlr-button--link {
  padding: 0;
  text-decoration: underline;
}

.rlr-button--link-no-underline {
  padding: 0;
  text-decoration: none;
}

.rlr-button--brand {
  background-color: var(--brand);
  color: var(--brand-01);
}

.rlr-button--brand:hover {
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--white);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.rlr-button--white {
  background-color: var(--white);
}

.rlr-button--gray-10 {
  background-color: var(--gray-10);
}

.rlr-button--gray-09 {
  background-color: var(--gray-09);
}

.rlr-button--gray-08 {
  background-color: var(--gray-08);
}

.rlr-button--body-color {
  background-color: var(--body-color);
}

.rlr-button--gray-06 {
  background-color: var(--gray-06);
}

.rlr-button--gray-05 {
  background-color: var(--neutral-color);
}

.rlr-button--gray-04 {
  background-color: var(--gray-04);
}

.rlr-button--gray-03 {
  background-color: var(--gray-03);
}

.rlr-button--gray-02 {
  background-color: var(--gray-02);
}

.rlr-button--gray-01 {
  background-color: var(--gray-01);
}

.rlr-button--black {
  background-color: var(--black);
}

.rlr-button--success {
  background-color: var(--success);
}

.rlr-button--warning {
  background-color: var(--warning);
}

.rlr-button--danger {
  background-color: var(--danger);
}

.rlr-button--transparent {
  background-color: transparent;
  color: var(--body-color);
}

.rlr-button__color--gray {
  color: var(--body-color);
}

.rlr-button__color--delete {
  color: var(--danger-color);
}

.rlr-button--product-form-repeater {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

/*!  ==============================================================
<!-- 5.4 Badge ==== -->
============================================================== **/
.rlr-badge {
  --badge-color: var(--gray-10);
  --badge-bg-color: var(--warning-01);
  --badge-padding-y: ($spacer * 0.25);
  --badge-padding-x: ($spacer * 1);
  --badge-border-radius: 1rem;
  --badge-bg-color-sale: var(--danger-color-03);
  --badge-color-sale: var(--danger-color);
  background-color: var(--badge-bg-color);
  border-radius: var(--badge-border-radius);
  color: var(--badge-color);
  display: inline-block;
  padding: 0.375rem 0.825rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  z-index: 2;
}

.rlr-badge--right {
  float: right;
}

.rlr-badge--sale {
  background-color: var(--badge-bg-color-sale);
  color: var(--badge-color-sale);
}

.rlr-badge--accent-blue {
  background-color: var(--accent-blue);
  color: var(--white);
}

.rlr-badge--accent-red {
  background-color: var(--accent-red);
  color: var(--white);
}

.rlr-badge--accent-magenta {
  background-color: var(--accent-magenta);
  color: var(--white);
}

.rlr-badge--accent-black {
  background-color: var(--accent-black);
  color: var(--white);
}

.rlr-badge--brand {
  background-color: var(--brand);
  color: var(--white);
}

.rlr-badge--abs {
  position: absolute;
  top: var(--spacing-8);
}

.rlr-badge--abs-left {
  left: var(--spacing-8);
}

.rlr-badge--abs-dest,
.rlr-badge--abs-right {
  right: var(--spacing-8);
}

.rlr-badge--abs-dest {
  --badge-color: var(--brand);
  --badge-bg-color: var(--white);
  --badge-border-radius: 0.25rem;
}

/*!  ==============================================================
<!-- 5.5 Logo ==== -->
============================================================== **/
.rlr-logo__img {
  display: inline-block;
  height: 2rem;
  max-width: 11rem;
  vertical-align: top;
}

.rlr-logo__text {
  font-weight: 600;
  text-decoration: none;
}

@media (min-width: 768px) {
  .rlr-logo__navbar-brand {
    padding: 0;
  }
}

.navigation-animated .rlr-logo--transparent-bg,
.navigation-transparent .rlr-logo--default {
  display: none;
}

@media (max-width: 991.98px) {
  .navigation-transparent .navigation-body.is-visible .rlr-logo--transparent-bg {
    display: none;
  }

  .navigation-transparent .navigation-body.is-visible .rlr-logo--default {
    display: block;
  }
}

/*!  ==============================================================
<!-- 5.6 Navigation ==== -->
============================================================== **/
.navigation {
  -ms-flex-align: initial;
  -webkit-box-align: initial;
  align-items: normal;
  background-color: var(--white);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: inherit;
  line-height: normal;
  min-height: var(--spacing-9);
  padding: 0;
  position: relative;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  width: 100%;
}

@media (min-width: 992px) {
  .navigation {
    height: auto;
  }
}

.navigation *,
.navigation :after,
.navigation :before {
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

.navigation.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;
}

.navigation.sticky-top {
  position: sticky;
  top: 0;
  will-change: opacity;
  z-index: 19998;
}

@media (max-width: 991.98px) {

  .navigation.sticky-top .overlay-panel,
  .navigation.sticky-top .rlr-navigation__body {
    min-height: 100vh;
  }
}

.navigation-header,
.navigation>.container {
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navigation-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 992px) {
  .navigation-header {
    display: none;
  }
}

.overlay-panel {
  height: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  -webkit-transition: opacity 0.8s;
  -o-transition: opacity 0.8s;
  transition: opacity 0.8s;
  width: 0;
  z-index: -999999;
}

.overlay-panel.is-visible {
  height: 100%;
  opacity: 1;
  width: 100%;
  z-index: 19999;
}

.overlay-panel.is-invisible {
  opacity: 0;
}

@media (min-width: 992px) {
  .overlay-panel.is-visible {
    display: none !important;
  }
}

.navigation-brand-text a {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

@media (min-width: 992px) {
  .navigation-brand-text a {
    font-size: 22px;
  }
}

.navigation-header .navigation-brand-text {
  padding: 0 1rem;
}

.navigation-logo,
.navigation-logo a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navigation-logo a img {
  display: block;
  height: 40px;
}

@media (min-width: 992px) {
  .navigation-logo a img {
    height: 48px;
  }
}

.navigation-logo-top .navigation-body .navigation-logo img {
  height: auto;
  width: auto;
}

.navigation-header .navigation-logo {
  padding: 0 1rem;
}

.navigation-logo.navigation-item a img {
  height: auto;
  width: auto;
}

.navigation-button-toggler {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  cursor: pointer;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 60px;
}

.navigation-button-toggler .hamburger-icon {
  -webkit-box-shadow: inset 0 0 0 32px, 0 -6px, 0 6px;
  box-shadow: inset 0 0 0 32px, 0 -6px, 0 6px;
  color: var(--heading-color);
  display: inline-block;
  font-style: normal;
  height: 2px;
  width: 20px;
}

.navigation-body {
  background-color: var(--white);
  left: -100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  -webkit-transition: left 0.8s cubic-bezier(0.1, 0.1, 0.1, 0.1), right 0.8s cubic-bezier(0.1, 0.1, 0.1, 0.1);
  -o-transition: left 0.8s cubic-bezier(0.1, 0.1, 0.1, 0.1), right 0.8s cubic-bezier(0.1, 0.1, 0.1, 0.1);
  transition: left 0.8s cubic-bezier(0.1, 0.1, 0.1, 0.1), right 0.8s cubic-bezier(0.1, 0.1, 0.1, 0.1);
  width: 100%;
  z-index: 20000;
}

.navigation-body.active {
  left: 0%;
  height: 100vh;
  top: 0;
}

@media (min-width: 500px) {
  .navigation-body {
    width: 320px;
  }
}

@media (min-width: 992px) {
  .navigation-body {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    display: flexbox;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: auto;
    overflow: visible;
    position: relative;
    -webkit-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    width: 100%;
    z-index: auto;
  }

}

.navigation-body.offcanvas-right {
  left: auto;
  right: -100%;
}

@media (min-width: 992px) {
  .navigation-body.offcanvas-right {
    right: auto;
  }
}

.navigation-body.is-visible {
  left: 0;
  -webkit-transition: left 0.8s, right 0.8s;
  -o-transition: left 0.8s, right 0.8s;
  transition: left 0.8s, right 0.8s;
}

.navigation-body.offcanvas-right.is-visible {
  left: auto;
  right: 0;
}

@media (min-width: 992px) {
  .navigation-body.offcanvas-right.is-visible {
    right: auto;
  }
}

.navigation-body.is-invisible {
  left: -100%;
}

.navigation-body.offcanvas-right.is-invisible {
  right: -100%;
}

.navigation-body.scroll-momentum {
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 992px) {
  .navigation-centered .navigation-body {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .navigation-justified .navigation-body {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .navigation-logo-top .navigation-body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.navigation-body-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  border-bottom: 1px solid var(--white);
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-4);
}

@media (min-width: 992px) {
  .navigation-body-header {
    border: none;
  }

  .navigation-centered .navigation-body-header {
    padding: 0;
  }

  .navigation-logo-top .navigation-body-header {
    padding-top: 20px;
  }
}

.navigation-body-close-button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--heading-color);
  cursor: pointer;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 25px;
  height: 30px;
  justify-content: center;
  line-height: normal;
  margin-left: auto;
  padding-bottom: 2px;
  text-align: center;
  width: 30px;
}

@media (min-width: 992px) {
  .navigation-body-close-button {
    display: none;
  }
}

.navigation-body-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: var(--spacing-11) 0;
  width: 100%;
}

@media (min-width: 992px) {
  .navigation-body-section {
    display: inline-block;
    width: auto;
  }
}

.navigation-body-section+.navigation-body-section {
  padding-top: 0;
}

@media (min-width: 992px) {
  .navigation-body-section+.navigation-body-section {
    padding-top: var(--spacing-11) 0;
  }
}

.navigation-menu {
  display: inline-block;
  float: left;
  font-size: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (min-width: 992px) {
  .navigation-menu {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flexbox;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
  }

  .navigation .navigation-menu {
    -ms-flex-item-align: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    align-self: stretch;
  }

  .navigation-logo-top .navigation-menu {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.navigation-item {
  display: inline-block;
  float: left;
  margin: 0;
  width: 100%;

}

@media (min-width: 992px) {
  .navigation-item {
    width: auto;
  }
}

.navigation-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--white);
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.25rem;
  letter-spacing: 0.3px;
  padding: 13px 16px;
  position: relative;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  width: 100%;
}

.navigation-link,
.navigation-link:visited {
  color: var(--heading-color);
}

@media (min-width: 992px) {
  .navigation-item:last-child .navigation-link {
    border-bottom: none;
    border-top: none;
  }
}

.navigation-item:not(.navigation-brand-text).is-active .navigation-link,
.navigation-item:not(.navigation-brand-text):focus .navigation-link,
.navigation-item:not(.navigation-brand-text):hover .navigation-link {
  background-color: transparent;
  color: var(--brand);
  text-decoration: none !important;
}

@media (min-width: 992px) {
  .navigation-link {
    border: none;
    height: 100%;
    line-height: var(--spacing-9);
    padding: 10px 16px;
  }
}

.navigation-menu.navigation-social-menu+.navigation-menu:not(.navigation-social-menu) .navigation-item:first-child .navigation-link {
  border-top: 1px solid var(--white);
}

@media (min-width: 992px) {
  .navigation-menu.navigation-social-menu+.navigation-menu:not(.navigation-social-menu) .navigation-item:first-child .navigation-link {
    border-top: none;
  }
}

.navigation-link i {
  float: left;
  font-size: 24px;
  line-height: 0;
  text-align: center;
  width: 28px;
}

.navigation-link i[class*="fa"] {
  font-size: 21px;
}

@media (min-width: 992px) {
  .navigation-link i {
    width: auto;
  }
}

.navigation-link i+span {
  padding-left: 8px;
}

.navigation-icon-item .navigation-link {
  padding: 13px 16px;
}

@media (min-width: 992px) {
  .navigation-icon-item .navigation-link {
    border-left: 1px solid var(--white);
    margin: 0;
    padding: 25px 25.6px;
    width: 100%;
  }

  .navigation-icon-item .navigation-link span:not(.submenu-indicator) {
    display: none;
  }

  .navigation-icon-item .navigation-link .navigation-badge {
    right: 10px;
  }

  .navigation-icon-item .navigation-link i {
    float: left;
    font-size: 25.6px;
    height: 26px;
    line-height: 26px;
    margin: 0;
    text-align: center;
    width: 20px;
  }

  .navigation-icon-item .navigation-link i[class*="fa"] {
    font-size: 21px;
    width: auto;
  }
}

.navigation-avatar-item .navigation-link {
  font-size: 12px;
  font-weight: 700;
}

.navigation-avatar-item .navigation-link img {
  border: 2px solid var(--brand);
  border-radius: 50%;
  width: 48px;
}

.navigation-avatar-item .navigation-link span {
  margin-left: 8px;
}

.navigation-body-section+.navigation-menu:not(.navigation-social-menu)>.navigation-item:first-child>.navigation-link {
  border-top: 1px solid var(--white);
}

@media (min-width: 992px) {
  .navigation-body-section+.navigation-menu:not(.navigation-social-menu)>.navigation-item:first-child>.navigation-link {
    border-top: none;
  }
}

.navigation-social-menu {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  width: 100%;
}

@media (min-width: 992px) {
  .navigation-social-menu {
    width: auto;
  }
}

@media (max-width: 991px) {
  .navigation-social-menu {
    padding: 16px;
  }
}

.navigation-social-menu .navigation-item {
  display: inline-block;
  width: auto;
}

.navigation-social-menu .navigation-item .navigation-link {
  border: none;
  margin: 0;
  padding: 0 5px;
}

.navigation-social-menu .navigation-item .navigation-link i {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--gray-00);
  border-radius: 50%;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 19px;
  height: 38px;
  justify-content: center;
  line-height: normal;
  margin: 0;
  width: 38px;
}

.navigation-social-menu .navigation-item .navigation-link i:not([class*="fa"]):before {
  margin-right: -1px;
}

.navigation-social-menu .navigation-item:last-child .navigation-link {
  padding-right: 0;
}

.navigation-social-menu .navigation-item:first-child .navigation-link {
  padding-left: 0;
}

@media (max-width: 991px) {
  .navigation-social-menu .navigation-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.submenu-indicator {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 46px;
  z-index: 20000;
}

@media (min-width: 992px) {
  .submenu-indicator {
    float: right;
    height: 6px;
    margin-left: 6px;
    position: relative;
    width: 6px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) and (max-width: 1100px) {
  .submenu-indicator {
    display: none;
  }
}

.submenu-indicator:after {
  border-bottom: 1px solid var(--heading-color);
  border-left: 0 solid transparent;
  border-right: 1px solid var(--heading-color);
  border-top: 0 solid transparent;
  content: "";
  height: 8px;
  left: 50%;
  margin: -5px 0 0;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: border 0.3s, -webkit-transform 0.3s;
  transition: border 0.3s, -webkit-transform 0.3s;
  -o-transition: border 0.3s, transform 0.3s;
  transition: border 0.3s, transform 0.3s;
  transition: border 0.3s, transform 0.3s, -webkit-transform 0.3s;
  width: 8px;
}

.navigation-item.is-active>.navigation-link>.submenu-indicator:after,
.navigation-item:focus>.navigation-link>.submenu-indicator:after,
.navigation-item:hover>.navigation-link>.submenu-indicator:after {
  border-bottom-color: var(--brand);
  border-left-color: transparent;
  border-right-color: var(--brand);
  border-top-color: transparent;
}

.submenu-indicator.is-active:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media (min-width: 992px) {
  .submenu-indicator.is-active:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.navigation-btn {
  background-color: var(--brand);
  border: 1px solid var(--brand);
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 1.4;
  padding: 10px 12px;
  text-align: center;
  -webkit-transition: opacity 0.3s, background 0.3s;
  -o-transition: opacity 0.3s, background 0.3s;
  transition: opacity 0.3s, background 0.3s;
  white-space: nowrap;
  width: 100%;
}

.navigation-btn:focus,
.navigation-btn:hover {
  background-color: var(--brand);
  color: var(--white);
  text-decoration: none;
}

@media (min-width: 992px) {
  .navigation-btn {
    width: auto;
  }
}

.navigation-text {
  color: var(--heading-color);
  display: inline-block;
  font-size: 14px;
  padding: 11px 0;
}

@media (min-width: 992px) {
  .navigation-text {
    padding: 11px 16px;
  }
}

.navigation-badge {
  background-color: var(--brand);
  border-radius: 20px;
  color: var(--white);
  display: inline-block;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  margin: 0 5px;
  min-width: 18px !important;
  text-align: center;
}

@media (min-width: 992px) {
  .navigation-link .navigation-badge {
    position: absolute;
    right: -2px;
    top: calc(50% - 20px);
  }

  .navigation-dropdown-link .navigation-badge {
    position: static;
  }
}

.navigation-inline-form {
  position: relative;
}

@media (min-width: 992px) {
  .navigation-inline-form {
    display: flexbox;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.navigation-inline-form .navigation-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: absolute;
  right: 4px;
  top: 0;
  width: auto;
}

.navigation-input {
  background-color: var(--gray-00);
  border: 1px solid var(--gray-04);
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--heading-color);
  font-size: 14px;
  line-height: 1.4;
  outline-width: 0;
  padding: 11px 32px 11px 12px;
  -webkit-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
  width: 100%;
}

.navigation-input:focus,
.navigation-input:hover {
  border-color: var(--brand);
}

.navigation-input::-webkit-input-placeholder {
  color: var(--heading-color);
}

.navigation-input::-moz-placeholder {
  color: var(--heading-color);
}

.navigation-input::placeholder {
  color: var(--heading-color);
}

.navigation-input:-ms-input-placeholder {
  color: var(--heading-color);
}

.navigation-input::-ms-input-placeholder {
  color: var(--heading-color);
}

.navigation-search-icon {
  border: 2px solid;
  border-radius: 50%;
  color: var(--heading-color);
  display: inline-block;
  height: 14px;
  position: relative;
  text-align: left;
  text-indent: -9999px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  vertical-align: middle;
  width: 14px;
}

.navigation-search-icon:after,
.navigation-search-icon:before {
  content: "";
  pointer-events: none;
}

.navigation-search-icon:before {
  border-radius: 0 0 1px 1px;
  -webkit-box-shadow: inset 0 0 0 32px;
  box-shadow: inset 0 0 0 32px;
  height: 7px;
  left: 50%;
  position: absolute;
  top: 10px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 2px;
}

.navigation-btn:hover .navigation-search-icon {
  color: var(--brand);
}

.navigation-dropdown {
  background-color: var(--white);
  display: block;
  list-style: none;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  position: static;
  -webkit-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  -o-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  width: 100%;
  z-index: 20001;
}

.navigation-dropdown.is-visible {
  max-height: 2500px;
  -webkit-transition: max-height 5s ease-out;
  -o-transition: max-height 5s ease-out;
  transition: max-height 5s ease-out;
}

@media (min-width: 992px) {
  .navigation-dropdown {
    -webkit-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    display: inline-block;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.2s linear, max-height 0s linear 0.2s;
    -o-transition: opacity 0.2s linear, max-height 0s linear 0.2s;
    transition: opacity 0.2s linear, max-height 0s linear 0.2s;
    width: 240px;
  }

  .navigation-dropdown.is-visible {
    opacity: 1;
    overflow: visible;
    -webkit-transition: opacity 0.2s linear, max-height 0s linear;
    -o-transition: opacity 0.2s linear, max-height 0s linear;
    transition: opacity 0.2s linear, max-height 0s linear;
  }

  .navigation-item>.navigation-dropdown {
    -webkit-box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
  }
}

.navigation-dropdown .navigation-dropdown {
  left: 100%;
}

.navigation-dropdown-left {
  left: auto;
  right: 100%;
}

.navigation-dropdown-item {
  display: inline-block;
  float: left;
  margin: 0;
  width: 100%;
}

.navigation-dropdown-item:last-child a,
.navigation-dropdown-item:last-child a:hover {
  border-radius: 0 0 1rem 1rem;
}

.navigation-dropdown-link {
  border-bottom: 1px solid var(--white);
  display: inline-block;
  position: relative;
  -webkit-transition: color 0.3s, background 0.3s;
  -o-transition: color 0.3s, background 0.3s;
  transition: color 0.3s, background 0.3s;
  width: 100%;
}

.navigation-dropdown-link,
.navigation-dropdown-link:visited {
  color: var(--heading-color);
}

.navigation-dropdown-item.is-active>.navigation-dropdown-link,
.navigation-dropdown-item:focus>.navigation-dropdown-link,
.navigation-dropdown-item:hover>.navigation-dropdown-link {
  background-color: var(--white);
  color: var(--brand);
  text-decoration: none !important;
}

.navigation-dropdown-link {
  padding: 14px 19px;
  text-transform: uppercase;
  text-decoration: none !important;
}

.navigation-dropdown-link+ul .navigation-dropdown-link {
  padding-left: 32px;
}

.navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link,
.navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link {
  padding-left: 48px;
}

.navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link {
  padding-left: 64px;
}

.navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link+ul .navigation-dropdown-link {
  padding-left: 128px;
}

@media (min-width: 992px) {
  .navigation-dropdown-link {
    border-bottom: none;
    padding-left: 19px !important;
  }
}

.navigation-dropdown-link>.submenu-indicator {
  position: absolute;
  right: 0;
  top: 0;
}

.navigation-dropdown-item.is-active>.navigation-dropdown-link>.submenu-indicator:after,
.navigation-dropdown-item:focus>.navigation-dropdown-link>.submenu-indicator:after,
.navigation-dropdown-item:hover>.navigation-dropdown-link>.submenu-indicator:after {
  border-bottom-color: var(--brand);
  border-left-color: transparent;
  border-right-color: var(--brand);
  border-top-color: transparent;
}

@media (min-width: 992px) {
  .navigation-dropdown-link>.submenu-indicator {
    position: absolute;
    right: 22px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .navigation-dropdown-link>.submenu-indicator:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .navigation-dropdown-link>.submenu-indicator.submenu-indicator-left:after {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.navigation-dropdown-horizontal {
  background-color: var(--white);
}

@media (min-width: 992px) {
  .navigation-dropdown-horizontal {
    left: 0;
    width: 100% !important;
  }

  .navigation-dropdown-horizontal .navigation-dropdown-horizontal {
    -webkit-box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    left: 0;
    top: 100%;
  }

  .navigation-dropdown-horizontal .navigation-dropdown-item {
    width: auto;
  }

  .navigation-dropdown-horizontal .navigation-dropdown-link {
    display: flexbox;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 19px;
  }

  .navigation-dropdown-horizontal .submenu-indicator {
    position: relative;
    right: 0;
  }

  .navigation-dropdown-horizontal .submenu-indicator:after {
    margin-top: 5px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .navigation-dropdown-horizontal.is-visible {
    display: block;
  }
}

.navigation-megamenu {
  background-color: var(--white);
  color: var(--heading-color);
  display: block;
  font-size: 14px;
  max-height: 0;
  overflow: hidden;
  position: static;
  -webkit-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  -o-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  transition: max-height 2s cubic-bezier(0, 1, 0, 1);
  width: 100%;
  z-index: 20001;
}

.navigation-megamenu.is-visible {
  border-bottom: 1px solid var(--white);
  max-height: 2500px;
  -webkit-transition: max-height 5s ease-out;
  -o-transition: max-height 5s ease-out;
  transition: max-height 5s ease-out;
}

@media (min-width: 992px) {
  .navigation-megamenu {
    border-top: 0 solid transparent;
    -webkit-box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.3s linear, max-height 0s linear 0.4s;
    -o-transition: opacity 0.3s linear, max-height 0s linear 0.4s;
    transition: opacity 0.3s linear, max-height 0s linear 0.4s;
  }

  .navigation-megamenu.is-visible {
    border-bottom: none;
    opacity: 1;
    -webkit-transition: opacity 0.3s linear, max-height 0s linear;
    -o-transition: opacity 0.3s linear, max-height 0s linear;
    transition: opacity 0.3s linear, max-height 0s linear;
  }

  .navigation-megamenu.navigation-megamenu-half {
    width: 50%;
  }

  .navigation-megamenu.navigation-megamenu-quarter {
    width: 25%;
  }
}

.scroll-momentum .navigation-megamenu {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@media (min-width: 992px) {
  .scroll-momentum .navigation-megamenu {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.navigation-megamenu [class*="container"] {
  width: 100%;
}

.navigation-megamenu [class*="container"] [class*="col-"] {
  padding: 0;
}

.navigation-megamenu-container {
  display: table;
}

@media (min-width: 992px) {
  .navigation-megamenu-container {
    padding: 16px;
  }
}

.navigation-row {
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.navigation-row>[class^="navigation-col"] {
  margin-bottom: 1rem;
  min-height: 20px;
  position: relative;
  width: 100%;
}

@media (min-width: 992px) {
  .navigation-row .navigation-col {
    -ms-flex-preferred-size: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-basis: auto;
    flex-grow: 1;
    max-width: 100%;
  }

  .navigation-row .navigation-col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .navigation-row .navigation-col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666666%;
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
  }

  .navigation-row .navigation-col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .navigation-row .navigation-col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .navigation-row .navigation-col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666666%;
  }

  .navigation-row .navigation-col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .navigation-row .navigation-col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .navigation-row .navigation-col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666666%;
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
  }

  .navigation-row .navigation-col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .navigation-row .navigation-col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .navigation-row .navigation-col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666666%;
    flex: 0 0 91.666666%;
    max-width: 91.666666%;
  }

  .navigation-row .navigation-col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.navigation-row:last-of-type [class^="navigation-col"]:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .navigation-row:last-of-type [class^="navigation-col"] {
    margin-bottom: 0;
  }
}

.navigation-col>ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (min-width: 992px) {
  [class^="navigation-col"]:last-of-type .navigation-col>ul {
    padding: 0;
  }
}

.navigation-col>ul>li {
  margin: 0;
  width: 100%;
}

.navigation-col>ul>li>a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--heading-color);
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.3s, background 0.3s;
  -o-transition: color 0.3s, background 0.3s;
  transition: color 0.3s, background 0.3s;
}

.navigation-col>ul>li>a:hover {
  background-color: var(--gray-02);
  color: var(--brand);
}

@media (min-width: 992px) {
  .navigation-col>ul>li>a {
    border-right: 1px solid var(--white);
  }
}

[class^="navigation-col"]:last-of-type .navigation-col>ul>li>a {
  border-right: none;
}

.navigation-col>ul>li:not(.navigation-list-heading)>a:before {
  content: "◥";
  font-size: 9px;
  left: 12px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navigation-list-heading {
  font-weight: 700;
}

.navigation-list-heading>a:hover {
  background-color: transparent !important;
  color: var(--heading-color) !important;
}

.navigation-tabs {
  background-color: var(--gray-00);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
  display: block;
  float: left;
  position: relative;
  width: 100%;
}

.navigation-tabs .navigation-megamenu-container ul.navigation-tabs {
  padding-left: 0;
}

@media (min-width: 992px) {
  .navigation-tabs:after {
    background-color: var(--white);
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 99;
  }
}

.navigation-tabs-nav {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navigation-tabs-nav .navigation-tabs-nav-item {
  width: 100%;
}

.navigation-tabs-nav .navigation-tabs-nav-item a {
  width: fit-content !important;
}

@media (min-width: 992px) {
  .navigation-tabs-nav {
    width: 20%;
  }
}

.navigation-tabs-nav>li a {
  background-color: var(--gray-01);
  outline: 0;
  padding: 10px 16px;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  width: 100%;
  text-transform: uppercase;
}

.navigation-tabs-nav>li.is-active a,
.navigation-tabs-nav>li:active a,
.navigation-tabs-nav>li:hover a {
  background-color: var(--white);
  color: var(--brand);
}

.navigation-tabs-pane {
  background-color: var(--white);
  color: var(--heading-color);
  display: none;
  float: right;
  font-size: 15px;
  min-height: 30px;
  opacity: 0;
  padding: 16px;
  position: relative;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  width: 100%;
  z-index: 100;
}

@media (min-width: 992px) {
  .navigation-tabs-pane {
    width: 80%;
  }
}

.navigation-tabs-pane.is-active {
  display: block;
  opacity: 1;
}

.navigation-transparent {
  background-color: transparent;
}

.navigation-transparent .navigation-header .navigation-brand-text a {
  color: var(--white);
}

@media (min-width: 992px) {
  .navigation-transparent .navigation-body .navigation-brand-text a {
    color: var(--white);
  }
}

.navigation-transparent .navigation-button-toggler .hamburger-icon {
  color: var(--white);
}

@media (min-width: 992px) {
  .navigation-transparent .navigation-link {
    color: var(--white);
  }

  .navigation-transparent .navigation-item.is-active>.navigation-link>.submenu-indicator:after,
  .navigation-transparent .navigation-item:focus>.navigation-link>.submenu-indicator:after,
  .navigation-transparent .navigation-item:hover>.navigation-link>.submenu-indicator:after,
  .navigation-transparent .navigation-link>.submenu-indicator:after {
    border-bottom-color: var(--white);
    border-left-color: transparent;
    border-right-color: var(--white);
    border-top-color: transparent;
  }

  .navigation-transparent .navigation-item:not(.navigation-brand-text).is-active .navigation-link,
  .navigation-transparent .navigation-item:not(.navigation-brand-text):focus .navigation-link,
  .navigation-transparent .navigation-item:not(.navigation-brand-text):hover .navigation-link {
    color: var(--white);
  }

  .navigation-transparent .navigation-icon-item .navigation-link {
    border-left: none;
  }

  .navigation-transparent .navigation-input {
    background-color: hsla(0, 0%, 100%, 0.01);
    border-color: hsla(0, 0%, 100%, 0.05);
    color: var(--white);
  }
}

@media (min-width: 992px) {
  .navigation-transparent .navigation-input ::-webkit-input-placeholder {
    color: hsla(0, 0%, 100%, 0.2);
  }

  .navigation-transparent .navigation-input ::-moz-placeholder {
    color: hsla(0, 0%, 100%, 0.2);
  }

  .navigation-transparent .navigation-input :-ms-input-placeholder {
    color: hsla(0, 0%, 100%, 0.2);
  }

  .navigation-transparent .navigation-input :-moz-placeholder {
    color: hsla(0, 0%, 100%, 0.2);
  }
}

@media (min-width: 992px) {
  .navigation-transparent .navigation-social-menu .navigation-link i {
    background-color: hsla(0, 0%, 100%, 0.05);
  }

  .navigation-transparent .navigation-item>.navigation-dropdown,
  .navigation-transparent .navigation-megamenu.is-visible {
    border-top-color: transparent;
  }
}

@media (max-width: 991px) {
  .navigation-fullscreen .navigation-body {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: center;
    display: flexbox;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .navigation-fullscreen .navigation-body-header {
    border: none;
    padding: 0;
  }

  .navigation-fullscreen .navigation-body-header .navigation-body-close-button,
  .navigation-fullscreen .navigation-body-header .navigation-brand-text,
  .navigation-fullscreen .navigation-body-header .navigation-logo {
    position: absolute;
    top: 20px;
  }

  .navigation-fullscreen .navigation-body-header .navigation-body-close-button {
    right: 20px;
  }

  .navigation-fullscreen .navigation-body-header .navigation-brand-text,
  .navigation-fullscreen .navigation-body-header .navigation-logo {
    left: 20px;
  }

  .navigation-fullscreen .navigation-menu {
    width: 300px;
  }

  .navigation-fullscreen .navigation-dropdown-link,
  .navigation-fullscreen .navigation-link {
    border: none;
  }

  .navigation-fullscreen .navigation-link {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .navigation-fullscreen .navigation-dropdown-link {
    text-align: center;
  }

  .navigation-fullscreen .navigation-btn {
    -ms-flex-item-align: center;
    -webkit-align-self: center;
    align-self: center;
    width: 300px;
  }

  .navigation-fullscreen .navigation-inline-form {
    margin: auto;
    width: 300px;
  }

  .navigation-fullscreen .navigation-inline-form .navigation-btn {
    width: auto;
  }
}

.align-to-right {
  margin-left: auto !important;
}

.align-to-left {
  margin-right: auto !important;
}

.margin-top {
  margin-top: 1rem !important;
}

@media (min-width: 992px) {
  .margin-top {
    margin-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .hide-on-portrait {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hide-on-landscape {
    display: none !important;
  }
}

.rlr-navigation {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rlr-navigation__body-header {
  padding: 0;
}

.rlr-navigation__body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.875rem;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 992px) {
  .rlr-navigation__body {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 1.875rem 0;
  }
}

.rlr-navigation__button-login {
  color: var(--heading-color);
  margin-right: calc(2.562rem - var(--spacing-4));
}

.rlr-navigation__button-signup {
  color: var(--white);
  font-weight: 300;
}

@media (max-width: 991.98px) {
  .rlr-navigation__button-signup {
    min-width: 100%;
  }
}

.rlr-navigation__link {
  color: var(--heading-color);
  height: auto;
  line-height: normal;
  padding: 0;
}

.rlr-navigation__menu.navigation-menu {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .rlr-navigation__menu.navigation-menu {
    gap: 0;
  }
}

.rlr-navigation__menu--main-links {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 992px) {
  .rlr-navigation__menu--main-links {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: var(--spacing-5);
  }
}

.rlr-navigation .rlr-logo__navbar-brand {
  margin-right: var(--spacing-8);
}

.rlr-navigation .rlr-svg-icon__header-search {
  fill: var(--body-color);
  stroke: var(--body-color);
}

.rlr-navigation--wp .navigation-col>ul {
  padding-top: var(--spacing-7);
}

.rlr-navigation .sign-up-in {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.rlr-navigation .sign-up-in a {
  width: 100%;
}

.rlr-navigation .sign-up-in .sign-up {
  background-color: #28b0a6;
  color: #fff;
  margin-bottom: 15px;
}

.rlr-navigation .sign-up-in .sign-up:hover {
  border: 2px solid #28b0a6;
}

.rlr-navigation .sign-up-in .sign-in {
  background-color: #eefbfa;
  color: #22968e;
}

.rlr-navigation .sign-up-in .sign-in:hover {
  border: 2px solid #eefbfa;
}

.rlr-navigation .navigation-list-heading a {
  color: var(--body-color);
  font-size: 14px;
}

.rlr-navigation .navigation-col div>span>a {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  text-transform: uppercase;
}

.rlr-navigation .navigation-list .navigation-list-heading>a {
  color: var(--gray-07);
  padding: 0 0 13px !important;
}

.rlr-navigation .navigation-list>li a:hover {
  background-color: transparent;
}

.rlr-navigation .navigation-list :not(.navigation-list-heading)>a:before {
  content: "";
}

.rlr-navigation .navigation-body {
  padding: var(--spacing-5);
}

.rlr-navigation .navigation-body .navigation-col>ul>li>a {
  padding: var(--spacing-5) var(--spacing-4);
}

.rlr-navigation .navigation-body .navigation-link {
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem;
  text-transform: uppercase;
  text-decoration: none !important;
}

.rlr-navigation .navigation-body .navigation-link .avatar {
  width: 2rem;
}

.rlr-navigation .rlr-navigation--wp .navigation-megamenu-container {
  padding: 16px !important;
}

.rlr-navigation .navigation-row>[class^="navigation-col"] {
  margin-bottom: 0;
  min-height: 20px;
  position: relative;
  width: 40%;
  max-width: 40%;
}

.rlr-navigation .navigation-row:last-of-type [class^="navigation-col"] {
  list-style: none;
}

@media (max-width: 767.98px) {
  .rlr-navigation .navigation-row {
    padding-left: 0;
  }
}

.rlr-navigation .rlr-icon-text {
  -webkit-column-gap: var(--spacing-5);
  -moz-column-gap: var(--spacing-5);
  column-gap: var(--spacing-5);
  /* padding-bottom: 1.5rem; */
  padding: 8px 0;
}

.rlr-navigation .rlr-icon-text__subtext {
  color: #667085;
  font-size: 0.875rem;
  font-weight: 400;
}

.rlr-navigation .rlr-navigation-list-heading--tall {
  margin-bottom: 1rem;
}

.rlr-navigation .rlr-step__icon {
  background: var(--brand-01);
  height: 3rem;
  width: 3rem;
}

.rlr-navigation .rlr-step__icon svg {
  fill: var(--brand);
  stroke: var(--white);
}

.rlr-navigation .rlr-svg-icon__header-language svg {
  fill: none;
  stroke: var(--body-color);
}

.rlr-navigation .align-to-right {
  gap: var(--spacing-5);
}

@media screen and (max-width: 991px) {
  .rlr-navigation .align-to-right {
    margin-top: 0;
  }
}

.rlr-navigation .align-to-right .navigation-link {
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  line-height: var(--spacing-9);
}

@media (min-width: 992px) {
  .rlr-navigation .align-to-right .navigation-link {
    padding: 0.5rem;
    white-space: nowrap;
  }
}

.rlr-navigation .rlr-navigation__link--so {
  color: var(--brand);
  font-weight: 500 !important;
}

.rlr-navigation .navigation-button-toggler {
  height: auto;
  width: auto;
  margin-right: 20px;
}

.rlr-navigation .navigation-button-toggler span i {
  font-size: 2rem;
  padding: 0.5rem 0;
}

.rlr-navigation .navigation-megamenu {
  border-radius: 0 0 var(--spacing-7) var(--spacing-7);
  max-height: 0;
  padding-left: 0;
  position: static;
  -webkit-transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  -o-transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  width: 100%;
}

.rlr-navigation .navigation-megamenu.is-visible {
  border-bottom: 1px solid var(--white);
  max-height: 2500px;
  -webkit-transition: max-height 1s ease-out;
  -o-transition: max-height 1s ease-out;
  transition: max-height 1s ease-out;
}

@media (min-width: 992px) {
  .rlr-navigation .navigation-megamenu {
    border-top: 0 solid transparent;
    -webkit-box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 var(--white), 0 12px 20px -5px rgba(0, 0, 0, 0.05);
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.3s linear, max-height 0s linear 0.4s;
    -o-transition: opacity 0.3s linear, max-height 0s linear 0.4s;
    transition: opacity 0.3s linear, max-height 0s linear 0.4s;
  }

  .rlr-navigation .navigation-megamenu-container {
    display: table;
    padding: 0;
  }

  .rlr-navigation .navigation-megamenu-container,
  .rlr-navigation .navigation-megamenu .navigation-dropdown {
    padding-top: 0.5rem;
  }
}

@media (min-width: 992px) and (min-width: 992px) {

  .rlr-navigation .navigation-megamenu-container,
  .rlr-navigation .navigation-megamenu .navigation-dropdown {
    -webkit-transition: opacity -0.1s linear, max-height 0s linear -0.1s;
    -o-transition: opacity -0.1s linear, max-height 0s linear -0.1s;
    transition: opacity -0.1s linear, max-height 0s linear -0.1s;
  }
}

@media (min-width: 992px) {
  .rlr-navigation .navigation-megamenu.is-visible {
    border-bottom: none;
    opacity: 1;
    -webkit-transition: opacity 0.3s linear, max-height 0s linear;
    -o-transition: opacity 0.3s linear, max-height 0s linear;
    transition: opacity 0.3s linear, max-height 0s linear;
  }

  .rlr-navigation .navigation-megamenu.navigation-megamenu-3-quarter {
    width: 75%;
  }

  .rlr-navigation .navigation-megamenu.navigation-megamenu-half {
    width: 80%;
  }

  .rlr-navigation .navigation-megamenu.navigation-megamenu-quarter {
    width: 25%;
  }
}

.scroll-momentum .rlr-navigation .navigation-megamenu {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@media (min-width: 992px) {
  .scroll-momentum .rlr-navigation .navigation-megamenu {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.rlr-navigation .navigation-megamenu [class*="container"] {
  width: 100%;
}

.rlr-navigation .navigation-megamenu [class*="container"] [class*="col-"] {
  padding: 0;
}

.rlr-navigation .navigation-submenu {
  border-radius: 0.5rem;
}

.rlr-navigation .navigation-col>ul {
  margin-top: 1.5rem;
}

.rlr-navigation.navigation-transparent .navigation-dropdown-item:first-child {
  padding-top: 0.875rem;
}

.rlr-navigation__tabbed-list {
  list-style: none;
}

@media (min-width: 992px) {
  .rlr-navigation .rlr-currency-dropdown {
    width: 120px;
  }
}

.rlr-sVGIcon {
  cursor: pointer;
}

.rlr-header-search__button {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-5px, -50%);
  -ms-transform: translate(-5px, -50%);
  transform: translate(-5px, -50%);
}

.rlr-header-search__wrapper {
  padding: 10px 5px;
}

.rlr-header-search__wrapper .rlr-header-search__results {
  margin-left: unset;
}

.rlr-header-search__wrapper .rlr-header-search.open .rlr-banner-input-group__input {
  font-size: 14px;
  padding-right: 34px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .rlr-header-search__wrapper .rlr-header-search.open .rlr-banner-input-group__input {
    min-width: 175px;
  }

  .container {
    min-width: 98%;
  }
}

@media (max-width: 991px) {
  .navigation-body {
    padding: 0;
  }

  .rlr-navigation .navigation-list>li.navigation-list-heading>a {
    font-size: 14px;
  }

  .navigation-header {
    padding: var(--spacing-5) var(--spacing-3);
  }

  .rlr-navigation__body-header {
    padding: 0;
  }

  .rlr-header-search__wrapper,
  .rlr-header-search__wrapper ul {
    width: 100%;
  }

  .rlr-header-search__wrapper ul i>span {
    content: "Search";
    display: block;
    font-size: 1.125rem;
    font-weight: 400;
    left: 30px;
    position: absolute;
    top: 50%;
  }

  .avatar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .rlr-header-search__button {
    padding-right: 10px;
    -webkit-transform: translate(5px, -50%);
    -ms-transform: translate(5px, -50%);
    transform: translate(5px, -50%);
  }
}

.rlr-icon-font--megamenu {
  color: var(--brand);
  font-size: 1rem;
  line-height: 1;
}

.navigation {
  height: 100px;
  -webkit-transition: background 0.3s, height 0.3s;
  -o-transition: background 0.3s, height 0.3s;
  transition: background 0.3s, height 0.3s;
}

.navigation-animated {
  height: 70px;
}

.navigation-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: var(--spacing-2);
}

@media (min-width: 992px) {
  .navigation-tabs {
    display: block;
    padding-left: 0;
  }
}

.navigation-tabs-pane .navigation-col>ul {
  margin-top: 0;
}

@media (max-width: 767.98px) {
  .navigation-item .rlr-button--brand {
    margin-top: 1rem;
  }
}

@media (max-width: 991.98px) {
  .navigation-link {
    border-bottom: 1px solid var(--gray-03);
  }
}

/*!  ==============================================================
<!-- 5.7 Section heading ==== -->
============================================================== **/
.rlr-section {
  --section-spacing-xxl: var(--spacing-16);
  --section-spacing-xl: var(--spacing-12);
}

.rlr-section__my {
  margin-bottom: var(--section-spacing-xxl);
}

.rlr-section__mt,
.rlr-section__my {
  margin-top: var(--section-spacing-xl);
}

.rlr-section__mb {
  margin-bottom: var(--spacing-14);
}

.rlr-section__content {
  margin-top: var(--spacing-17);
}

.rlr-section__content--lg-top {
  margin-top: var(--spacing-12);
}

.rlr-section__content--md-top {
  margin-top: var(--spacing-19);
}

.rlr-section-header {
  padding-bottom: var(--spacing-12);
}

@media (min-width: 576px) {
  .rlr-section-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }
}

.rlr-section-header>.rlr-section__title {
  padding-bottom: 0;
  width: 70%;
}

.rlr-section-header .button-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 575.98px) {
  .rlr-section-header .button-row {
    margin: 0.5rem 0 0;
  }
}

.rlr-section-header .button-row .rlr-button--carousel {
  margin-left: var(--spacing-4);
}

.rlr-section-header .button-group--cells {
  display: none;
}

.rlr-section__title {
  padding-bottom: var(--spacing-12);
}

.rlr-section__title--sub {
  color: var(--body-color);
  font-weight: 300;
}

.rlr-section__title--sub-white {
  color: var(--white);
}

.rlr-section__title--centered {
  text-align: center;
}

.rlr-section__title--light-brand {
  color: var(--brand);
  font-weight: 200;
}

.rlr-section .rlr-carousel__header {
  margin-bottom: 4rem;
}

/*!  ==============================================================
<!-- 5.8 Page Title ==== -->
============================================================== **/
.rlr-page-title {
  grid-column-gap: var(--spacing-7);
  display: grid;
  grid-template-columns: 5.25rem auto;
}

.rlr-page-title__icon {
  background-color: var(--brand-02);
  border-radius: 0.25rem;
  color: var(--brand);
  font-size: 3rem;
  height: 5.25rem;
}

.rlr-page-title__icon,
.rlr-page-title__icon i {
  display: grid;
  place-items: center;
}

.rlr-page-title__title {
  color: var(--brand);
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}

.rlr-page-title__desc {
  margin-bottom: var(--spacing-2);
}

/*!  ==============================================================
<!-- 5.9 Form control ==== -->
============================================================== **/
.form-control {
  --form-control-field-height: 3rem;
  border: 2px solid var(--form-border-color);
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  color: var(--heading-color);
  height: var(--form-control-field-height);
  padding: 0 var(--spacing-5);
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.form-control--light {
  border-color: var(--form-border-color-light);
}

@media (max-width: 768px) {
  .form-control {
    font-size: 1rem;
  }
}

.form-control:active,
.form-control:focus {
  border-color: unset;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}

.form-control::-webkit-input-placeholder {
  color: var(--body-color);
}

.form-control::-moz-placeholder {
  color: var(--body-color);
}

.form-control:-ms-input-placeholder {
  color: var(--body-color);
}

.form-control::-ms-input-placeholder {
  color: var(--body-color);
}

.form-control::placeholder {
  color: var(--body-color);
}

.form-control--validated {
  background: url(../assets/images/greentick.png) no-repeat 95% 9px;
}

.form-control--date {
  background: url(../assets/images/calendar.png) 96% 50% no-repeat;
}

.form-control--text-area {
  height: var(--spacing-18);
  padding: var(--spacing-3) var(--spacing-5);
}

/*!  ==============================================================
<!-- 5.10 Fieldrow ==== -->
============================================================== **/
.rlr-fieldrow {
  margin-top: var(--spacing-8);
  position: relative;
  -webkit-transition: height 0.3s ease-in;
  -o-transition: height 0.3s ease-in;
  transition: height 0.3s ease-in;
}

.rlr-fieldrow__form-element {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rlr-fieldrow__item:not(:first-child) {
  margin-top: var(--spacing-14);
}

.rlr-fieldrow__options:not(:last-child) {
  margin-bottom: var(--spacing-9);
}

.rlr-fieldrow__options--lg-spacer:not(:last-child) {
  margin-bottom: var(--spacing-14);
}

.rlr-fieldrow__item--multiple {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rlr-fieldrow__item--inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--spacing-8);
}

.rlr-fieldrow__tooltip {
  background: url(../assets/images/help.png) no-repeat 50% 50%;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 14px;
  width: 20px;
  z-index: 2;
}

.rlr-fieldrow__map {
  height: 332px;
  margin-top: var(--spacing-8);
}

.rlr-fieldrow__map>* {
  height: 20rem;
  width: 100%;
}

.rlr-fieldrow__clone--expand {
  margin-top: var(--spacing-9);
}

.rlr-fieldrow__clone--expand .rlr-fieldrow__options:nth-last-child(2) {
  margin-bottom: 0;
}

/*!  ==============================================================
<!-- 5.11 Form label ==== -->
============================================================== **/
.rlr-form-label {
  color: var(--heading-color);
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 0.5rem;
}

.rlr-form-label--light {
  color: var(--body-color);
}

.rlr-form-label--dark {
  color: var(--heading-color);
}

.rlr-form-label--checkbox,
.rlr-form-label--radio {
  color: var(--heading-color);
  font-weight: 400;
  line-height: 1.5;
  padding: var(--spacing-1) 0 0 var(--spacing-3);
}

/*!  ==============================================================
<!-- 5.12 Form select ==== -->
============================================================== **/
.rlr-form-select {
  background-image: url(../assets/svg/dropdown.svg);
  background-size: auto;
  border: 2px solid var(--form-border-color);
  border-radius: var(--spacing-3);
  height: 48px;
  padding: 0 var(--spacing-5);
}

@media (max-width: 768px) {
  .rlr-form-select {
    font-size: 1rem;
  }
}

.rlr-form-select:focus {
  border-color: unset;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*!  ==============================================================
<!-- 5.13 Radio ==== -->
============================================================== **/
.rlr-form-check-input[type="radio"] {
  border: 2px solid var(--body-color);
  height: var(--spacing-6);
  width: var(--spacing-6);
}

.rlr-form-check-input[type="radio"]:checked {
  background: url(../assets/images/checkbox-bg.png) no-repeat 50% 50%;
  border-color: var(--brand);
}

.rlr-radios {
  padding-left: 0;
}

.rlr-radios--no-bottom-spacer {
  margin-bottom: 0;
}

/*!  ==============================================================
<!-- 5.14 Checkbox ==== -->
============================================================== **/
.rlr-form-check-input {
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.rlr-form-check-input[type="checkbox"] {
  border: 2px solid var(--body-color);
  border-radius: var(--spacing-2);
  height: var(--spacing-6);
  margin-top: var(--spacing-2);
  width: var(--spacing-6);
}

.rlr-form-check-input[type="checkbox"]:checked {
  background: url(../assets/images/tick.png) no-repeat 50% 50% var(--brand);
  border: 0;
}

.rlr-form-check-input[type="checkbox"]+label {
  padding: var(--spacing-2) 0 0 var(--spacing-3);
}

.rlr-form-check-input:active {
  -webkit-filter: none;
  filter: none;
}

.rlr-form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rlr-checkboxes {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
  padding-left: 0;
}

@media (max-width: 575.98px) {
  .rlr-checkboxes {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rlr-checkboxes .form-check-inline {
  width: 44%;
}

@media (max-width: 576px) {
  .rlr-checkboxes .form-check-inline {
    width: 100%;
  }
}

.rlr-checkboxes .form-check-block {
  width: 100%;
}

/*!  ==============================================================
<!-- 5.15 Pagination ==== -->
============================================================== **/
.rlr-pagination__list.pagination {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  justify-content: space-between;
}

.rlr-pagination__child-list {
  gap: var(--spacing-1);
}

.rlr-pagination__page-item--form:first-child .page-link,
.rlr-pagination__page-item--form:last-child .page-link {
  border-radius: 3rem;
}

.rlr-pagination__page-item--form.disabled {
  pointer-events: none;
}

.rlr-pagination__page-item--form.disabled .page-link,
.rlr-pagination__page-item--form.disabled .rlr-pagination__page-link--form:focus {
  background: var(--white);
  border: 1px solid var(--gray-04);
  color: var(--gray-04);
}

.rlr-pagination__page-item:first-child .page-link,
.rlr-pagination__page-item:last-child .page-link {
  border-radius: 50%;
}

.rlr-pagination__page-item.active .page-link {
  background-color: var(--brand-00);
  color: var(--brand);
}

.rlr-pagination__page-item.disabled .page-link {
  color: var(--neutral-color);
}

.rlr-pagination__page-item.disabled .page-link svg path {
  stroke: var(--neutral-color);
}

.rlr-pagination__page-link {
  color: var(--body-color);
}

.rlr-pagination__page-link--form {
  background: var(--brand-00);
  border: 1px solid var(--brand);
  border-radius: 3rem;
  color: var(--brand);
  padding: 0.875rem 2rem;
  text-align: center;
}

.rlr-pagination__page-link--form.page-link:focus,
.rlr-pagination__page-link--form:hover {
  background: var(--brand-00);
  border: 1px solid var(--brand);
  color: var(--brand);
}

.rlr-pagination__page-link--form.page-link:focus,
.rlr-pagination__page-link.page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rlr-pagination__page-link--counter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
}

.rlr-pagination__page-link--counter:focus,
.rlr-pagination__page-link--counter:hover {
  background-color: var(--brand-00);
  color: var(--brand);
}

.rlr-pagination__page-link--next,
.rlr-pagination__page-link--prev {
  background: transparent;
  background: none;
  border: none;
  color: var(--neutral-color);
  font-weight: 400;
  line-height: normal;
  padding: 0;
}

.rlr-pagination__page-link--next:focus,
.rlr-pagination__page-link--next:hover,
.rlr-pagination__page-link--prev:focus,
.rlr-pagination__page-link--prev:hover {
  background: none;
  color: var(--body-color);
}

.rlr-pagination__page-link--next:focus svg path,
.rlr-pagination__page-link--next:hover svg path,
.rlr-pagination__page-link--prev:focus svg path,
.rlr-pagination__page-link--prev:hover svg path {
  stroke: var(--body-color);
}

.rlr-pagination__page-link--next span,
.rlr-pagination__page-link--prev span {
  display: none;
}

@media (min-width: 576px) {

  .rlr-pagination__page-link--next span,
  .rlr-pagination__page-link--prev span {
    display: inline-block;
  }
}

.rlr-pagination__page-link--next svg path,
.rlr-pagination__page-link--prev svg path {
  stroke: var(--neutral-color);
}

.rlr-pagination__page-link--prev span {
  margin-left: var(--spacing-3);
}

.rlr-pagination__page-link--next span {
  margin-right: var(--spacing-3);
}

/*!  ==============================================================
<!-- 5.16 Step ==== -->
============================================================== **/
.rlr-step {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1199.98px) {
  .rlr-step {
    width: 7.75rem;
  }
}

.rlr-step__bullet {
  background-color: var(--white);
  border: 1px solid var(--gray-04);
  border-radius: 50%;
  height: 0.5rem;
  margin-left: var(--spacing-7);
  width: 0.5rem;
}

.rlr-step__bullet--active {
  background: var(--brand);
  border: 1px solid var(--brand);
}

@media (max-width: 1199.98px) {
  .rlr-step__bullet {
    margin-bottom: var(--spacing-5);
  }
}

.rlr-step__icon {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: var(--body-color);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
}

.rlr-step__icon--active {
  background: var(--brand);
}

.rlr-step__icon--active svg>rect {
  fill: var(--brand);
}

@media (max-width: 1199.98px) {
  .rlr-step__icon {
    margin-bottom: var(--spacing-3);
  }
}

.rlr-step__text {
  display: inline-block;
}

.rlr-step__text span {
  display: block;
}

@media (max-width: 1199.98px) {
  .rlr-step__text {
    visibility: hidden;
  }
}

.rlr-step__text .type-sub-title {
  color: var(--body-color);
  margin-bottom: var(--spacing-3);
}

@media (max-width: 1199.98px) {
  .rlr-step__text .type-sub-title {
    font-size: var(--spacing-5);
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    visibility: visible;
    white-space: nowrap;
  }
}

@media (max-width: 575.98px) {
  .rlr-step__text .type-sub-title {
    visibility: hidden;
  }
}

.rlr-step__text--active,
.rlr-step__text--active.type-sub-title {
  color: var(--brand);
}

@media (max-width: 575.98px) {
  .rlr-step__text--active.type-sub-title {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .rlr-step {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .rlr-step__bullet {
    margin-left: var(--spacing-7);
  }

  .rlr-step__text {
    margin-right: var(--spacing-8);
    text-align: right;
  }

  .rlr-step__text,
  .rlr-step__text--active {
    visibility: visible;
  }

  .rlr-step--dashboard-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.rlr-step--dashboard-nav .rlr-step__icon {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: var(--white);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 1.5rem;
  height: 3.5rem;
  justify-content: center;
  line-height: 0;
  width: 3.5rem;
}

.rlr-step--dashboard-nav .rlr-step__icon--active {
  background: var(--brand);
  color: var(--white);
}

.rlr-step--dashboard-nav .rlr-step__text {
  margin-left: var(--spacing-3);
}

.rlr-step--dashboard-nav .rlr-step__text .type-sub-title {
  font-weight: 400;
  margin-bottom: 0;
}

/*!  ==============================================================
<!-- 5.17 Photo uploader ==== -->
============================================================== **/
.rlr-drop-region {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  border: 2px dashed var(--neutral-color);
  border-radius: 8px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  justify-content: center;
  padding: var(--spacing-15) 0;
  width: 100%;
}

.rlr-drop-region__image-input {
  display: none;
}

.rlr-drop-region__add-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
}

.rlr-drop-region__add-section__text {
  color: var(--body-color);
  font-weight: 300;
  margin-top: var(--spacing-5);
}

.rlr-drop-region--js-active {
  margin-bottom: var(--spacing-9);
}

.rlr-view-region__image-view img {
  border-radius: 8px;
  display: block;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
}

.rlr-view-region__image-view:before {
  background: transparent;
  border-radius: 8px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.rlr-view-region__image-view:hover:before {
  background: rgba(0, 0, 0, 0.7);
}

.rlr-view-region__image-view:hover .rlr-view-region__meatball {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-view-region__strack.splide__track {
  margin-top: var(--spacing-8);
  overflow: visible;
  overflow-x: clip;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .rlr-view-region__strack.splide__track {
      overflow-x: hidden;
    }
  }
}

.rlr-view-region .splide__arrow {
  background: transparent;
  border: 0;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  float: right;
  opacity: 0;
}

.rlr-view-region .splide__arrow,
.rlr-view-region .splide__arrow svg {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.rlr-view-region .splide__arrow svg {
  fill: var(--white);
}

.rlr-view-region .splide__arrow svg path {
  stroke: var(--body-color);
}

.rlr-view-region .splide__arrow:disabled {
  opacity: 0;
}

.rlr-view-region:hover .splide__arrow {
  opacity: 1;
}

.rlr-view-region__control {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--spacing-5) var(--spacing-2) var(--spacing-5);
  position: absolute;
  width: 100%;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .rlr-view-region__control {
      display: none;
    }
  }
}

.rlr-view-region__meatball {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: none;
  height: var(--spacing-7);
  -webkit-transition: display 0.2s ease-in-out;
  -o-transition: display 0.2s ease-in-out;
  transition: display 0.2s ease-in-out;
}

.rlr-view-region__meatball span {
  background-color: var(--brand-00);
  border-radius: 50%;
  height: var(--spacing-2);
  margin-left: var(--spacing-2);
  width: var(--spacing-2);
}

.rlr-view-region__captions {
  color: var(--white);
}

.rlr-view-region__action-button {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  right: var(--spacing-5);
  top: calc(100% - var(--spacing-3));
  -webkit-transition: display 0.2s ease-in-out;
  -o-transition: display 0.2s ease-in-out;
  transition: display 0.2s ease-in-out;
}

.rlr-view-region__action-button--js-hide {
  display: none;
}

.rlr-view-region__action-button--js-show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-view-region:hover .rlr-view-region__arrow.splide__arrow {
  background: var(--brand);
  border-color: transparent;
  opacity: 1;
}

.rlr-view-region:hover .rlr-view-region__arrow.splide__arrow svg path {
  stroke: var(--white);
}

.rlr-view-input {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid var(--body-color);
  border-radius: 4px;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin: var(--spacing-9) 0 0;
  padding-right: var(--spacing-4);
}

.rlr-view-input__form-control {
  border: none;
}

.rlr-view-input__submit {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: var(--brand);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: var(--spacing-7);
  justify-content: center;
  width: var(--spacing-7);
}

.rlr-view-input--js-hide {
  display: none;
}

/*!  ==============================================================
<!-- 5.18 Action button ==== -->
============================================================== **/
.rlr-action-button,
.rlr-view-input--js-show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-action-button {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
}

.rlr-action-button__custom {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--white);
  color: var(--body-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-action-button__icon {
  margin-right: var(--spacing-5);
}

/*!  ==============================================================
<!-- 5.19 Typography ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 5.20 Input group ==== -->
============================================================== **/
.rlr-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.rlr-input-group__datefield .form-input {
  background: url(../assets/svg/icon-calendar.svg) no-repeat calc(100% - 1rem);
  padding-right: var(--spacing-15);
}

.rlr-input-group__datefield .form-input:invalid::-webkit-datetime-edit {
  color: var(--neutral-color);
}

.rlr-input-group__datefield .form-input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.rlr-input-group__numberfield .form-input::-webkit-input-placeholder {
  color: var(--body-color);
}

.rlr-input-group__numberfield .form-input::-moz-placeholder {
  color: var(--body-color);
}

.rlr-input-group__numberfield .form-input:-ms-input-placeholder {
  color: var(--body-color);
}

.rlr-input-group__numberfield .form-input::-ms-input-placeholder {
  color: var(--body-color);
}

.rlr-input-group__numberfield .form-input::placeholder {
  color: var(--body-color);
}

.rlr-input-group__numberfield .form-input::-webkit-inner-spin-button,
.rlr-input-group__numberfield .form-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.rlr-input-group__iconset {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-grow: 1;
  padding: var(--spacing-1);
}

.rlr-input-group__iconset--absolute {
  position: absolute;
  right: var(--spacing-4);
  top: var(--spacing-4);
}

.rlr-input-group__button {
  background: none;
  border: 0;
  cursor: pointer;
  line-height: 1.25rem;
  line-height: 0;
}

.rlr-input-group__button .rlr-svg-icon {
  fill: #5bd7cd;
}

.rlr-input-group__button .rlr-svg-icon:active:not([disabled]),
.rlr-input-group__button .rlr-svg-icon:focus:not([disabled]),
.rlr-input-group__button .rlr-svg-icon:hover:not([disabled]) {
  fill: var(--brand-08);
}

.rlr-input-group__button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.rlr-input-group__button:disabled .rlr-svg-icon {
  fill: var(--neutral-color);
}

.rlr-input-groups {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: var(--spacing-8);
  padding-left: 0;
}

.rlr-input-groups--daterange {
  gap: 0;
  width: 100%;
}

.rlr-input-groups--daterange .rlr-input-group__datefield {
  width: 100%;
}

.rlr-input-groups rlr-input-group__numberfield[data-name="Adults"] {
  display: none;
}

/*!  ==============================================================
<!-- 5.21 Input range ==== -->
============================================================== **/
.rlr-range-slider {
  height: 6em;
  margin: auto;
  position: relative;
  text-align: center;
}

.rlr-range-slider .form-control {
  border: 2px solid var(--gray-04);
}

.rlr-range-slider__price-box {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-column-gap: 32%;
  -moz-column-gap: 32%;
  column-gap: 32%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.rlr-range-slider ::-webkit-inner-spin-button {
  display: none;
}

.rlr-range-slider input[type="number"]:invalid {
  border: 2px solid var(--danger-color);
}

.rlr-range-slider input[type="number"]:out-of-range {
  border: 2px solid var(--danger-color);
}

.rlr-range-slider input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.rlr-range-slider input[type="range"]::-webkit-slider-runnable-track {
  background: var(--brand);
  border-radius: 1px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  height: 8px;
  left: 0;
  right: 0;
  top: 8px;
  -webkit-transition: 2s;
  transition: 2s;
  width: 100%;
}

.rlr-range-slider input[type="range"]:focus {
  outline: none;
}

.rlr-range-slider input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--brand);
}

.rlr-range-slider input[type="range"]:focus::-ms-fill-lower {
  background: #aa8889;
}

.rlr-range-slider input[type="range"]:focus::-ms-fill-upper {
  background: #b10f8e;
}

.rlr-range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: var(--white);
  border-radius: 2rem;
  -webkit-box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  cursor: pointer;
  height: 1.2rem;
  margin-top: -0.5rem;
  position: relative;
  width: 1.5rem;
  z-index: 2;
}

.rlr-range-slider input[type="range"]::-ms-thumb {
  background: var(--white);
  border-radius: 2rem;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  cursor: pointer;
  height: 18px;
  position: relative;
  width: 18px;
  z-index: 2;
}

.rlr-range-slider input[type="range"]::-moz-range-track {
  background: var(--brand);
  border: 0;
  border-radius: 1px;
  box-shadow: none;
  cursor: pointer;
  height: 5px;
  -moz-transition: 0.2s;
  transition: 0.2s;
  width: 100%;
}

.rlr-range-slider input[type="range"]::-moz-range-thumb {
  border-radius: 2rem;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  cursor: pointer;
  height: 18px;
  position: relative;
  width: 18px;
  z-index: 2;
}

.rlr-range-slider input[type="range"]::-ms-track {
  background: purple;
  border-color: transparent;
  color: transparent;
  cursor: pointer;
  height: 5px;
  -ms-transition: 0.2s;
  transition: 0.2s;
  width: 100%;
}

/*!  ==============================================================
<!-- 5.22 Breadcrumb ==== -->
============================================================== **/
.rlr-breadcrumb__items {
  margin-bottom: 0;
}

.rlr-breadcrumb__item+.rlr-breadcrumb__item:before {
  content: ">";
  margin: 2px 6px 0;
}

.rlr-breadcrumb__item {
  color: var(--neutral-color);
  font-weight: 400;
}

.rlr-breadcrumb__item.breadcrumb-item.active {
  color: var(--heading-color);
  cursor: auto;
}

.rlr-breadcrumb__item a {
  color: var(--neutral-color);
  text-decoration: none;
}

.rlr-breadcrumb__item a:hover {
  color: var(--heading-color);
}

.rlr-breadcrumb__innerpages .rlr-breadcrumb__item:before {
  color: var(--brand-07);
}

.rlr-breadcrumb__innerpages .rlr-breadcrumb__item.breadcrumb-item.active {
  color: var(--brand-10);
}

.rlr-breadcrumb__innerpages .rlr-breadcrumb__item a {
  color: var(--brand-07);
}

.rlr-breadcrumb__innerpages .rlr-breadcrumb__item a:hover {
  color: var(--brand-08);
}

/*!  ==============================================================
<!-- 5.23 Review star ==== -->
============================================================== **/
.rlr-review-stars {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--neutral-color);
  -webkit-column-gap: var(--spacing-2);
  -moz-column-gap: var(--spacing-2);
  column-gap: var(--spacing-2);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.rlr-review-stars .rlr-icon-font {
  color: var(--warning-color);
}

.rlr-review-stars__group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.rlr-review-stars__group--small .rlr-icon-font {
  font-size: 1rem;
}

.rlr-review-stars__group--large .rlr-icon-font {
  font-size: 1.5rem;
}

/*!  ==============================================================
<!-- 5.24 Media ==== -->
============================================================== **/
.rlr-media--wrapper {
  position: relative;
}

.rlr-media__image-view img {
  border-radius: 0.5rem;
  display: block;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
}

.rlr-media__arrow.splide__arrow {
  background: transparent;
  border: 1px solid var(--body-color);
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  float: right;
  height: 3.5rem;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 3.5rem;
}

@media (max-width: 767.98px) {
  .rlr-media__arrow.splide__arrow {
    display: none;
  }
}

.rlr-media__arrow.splide__arrow svg {
  fill: none;
  height: auto;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: auto;
}

.rlr-media__arrow.splide__arrow svg path {
  stroke: var(--body-color);
}

.rlr-media__pagination--hide.splide__pagination {
  display: none;
}

.rlr-media__page-counter {
  color: var(--heading-color);
}

.rlr-media__custom-pagination {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--white);
  border-radius: 0.5rem;
  bottom: var(--spacing-7);
  -webkit-column-gap: var(--spacing-3);
  -moz-column-gap: var(--spacing-3);
  column-gap: var(--spacing-3);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: var(--spacing-3) var(--spacing-4);
  position: absolute;
  right: var(--spacing-7);
}

@media (min-width: 576px) {
  .rlr-media__custom-pagination {
    padding: var(--spacing-5) var(--spacing-7);
  }
}

.rlr-media:hover .rlr-media__arrow.splide__arrow {
  background: var(--white);
  border-color: transparent;
  opacity: 1;
}

.rlr-media:hover .rlr-media__arrow.splide__arrow svg path {
  stroke: var(--brand);
}

.rlr-media--sidebar {
  display: block;
}

@media (max-width: 767.98px) {
  .rlr-media--sidebar .rlr-media__strack {
    display: none;
  }
}

.rlr-media__thumb {
  -webkit-filter: sepia(0.4);
  filter: sepia(0.4);
}

.rlr-media__thumb:hover {
  -webkit-filter: sepia(0);
  filter: sepia(0);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

/*!  ==============================================================
<!-- 5.25 Image ==== -->
============================================================== **/
.rlr-img {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  position: relative;
}

.rlr-img img {
  border-radius: var(--spacing-5);
}

.rlr-img--dotted:after {
  background-image: url(../assets/images/dotted.png);
  content: "";
  height: 344px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translate(75%, -10%);
  -ms-transform: translate(75%, -10%);
  transform: translate(75%, -10%);
  width: 64px;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .rlr-img--dotted:after {
    display: none;
  }
}

.rlr-img--dotted-reversed:after {
  background-image: url(../assets/images/dotted-2.png);
  bottom: 0;
  content: "";
  height: 319px;
  left: 0;
  position: absolute;
  -webkit-transform: translate(-60%, 10%);
  -ms-transform: translate(-60%, 10%);
  transform: translate(-60%, 10%);
  width: 88px;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .rlr-img--dotted-reversed:after {
    display: none;
  }
}

/*!  ==============================================================
<!-- 5.26 Secondary menu ==== -->
============================================================== **/
.rlr-product-detail-secondary-menu {
  background: var(--white);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.04);
  overflow: auto;
  padding: 0 var(--spacing-5);
  position: sticky;
  top: 70px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.rlr-product-detail-secondary-menu__tabitems {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-column-gap: var(--spacing-5);
  -moz-column-gap: var(--spacing-5);
  column-gap: var(--spacing-5);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: space-between;
  margin-top: var(--spacing-8);
  padding-left: 0;
}

@media (min-width: 576px) {
  .rlr-product-detail-secondary-menu__tabitems {
    width: 100%;
  }
}

.rlr-product-detail-secondary-menu__tabitem {
  color: var(--body-color);
  cursor: pointer;
  list-style: none;
  overflow: hidden;
  position: relative;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  white-space: nowrap;
}

.rlr-product-detail-secondary-menu__tabitem:after {
  background: var(--brand);
  bottom: 0;
  content: "";
  height: 0.25rem;
  left: -1.678rem;
  position: absolute;
  -webkit-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  width: 0;
}

.rlr-product-detail-secondary-menu__tabitem.is-active {
  color: var(--brand);
  position: relative;
}

.rlr-product-detail-secondary-menu__tabitem.is-active:after {
  left: 0;
  width: 1.678rem;
}

.rlr-product-detail-secondary-menu__tabitem:hover {
  color: var(--brand);
}

/*!  ==============================================================
<!-- 5.27 Read more ==== -->
============================================================== **/
.rlr-readmore-desc__content {
  color: var(--heading-color);
  letter-spacing: 0.5px;
  margin-bottom: 0;
  overflow: visible;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rlr-readmore-desc__content.is-more {
  max-height: 6rem;
  overflow: hidden;
}

.rlr-readmore-desc__readmore {
  color: var(--neutral-color);
  cursor: pointer;
  display: none;
  margin-top: 16px;
}

.rlr-readmore-desc__readmore.is-more {
  display: block;
}

/*!  ==============================================================
<!-- 5.28 Icon text ==== -->
============================================================== **/
.rlr-icon-text {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-3);
  -moz-column-gap: var(--spacing-3);
  column-gap: var(--spacing-3);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style: none;
  padding-left: 0;
}

.rlr-icon-text__block {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.875rem;
}

.rlr-icon-text__block span {
  font-weight: 400;
}

.rlr-icon-text__text-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rlr-icon-text__title {
  line-height: 1.43;
}

.rlr-icon-text__subtext {
  font-weight: 400;
  line-height: 1.43;
}

.rlr-icon-text--anchor,
.rlr-icon-text a {
  color: var(--body-color);
  font-weight: 400;
  text-decoration: none;
}

.rlr-icon-text--anchor:hover,
.rlr-icon-text a:hover {
  color: var(--brand);
}

/*!  ==============================================================
<!-- 5.29 Review card ==== -->
============================================================== **/
.rlr-review-card {
  background: var(--white);
  border: 2px solid var(--gray-02);
  border-radius: var(--spacing-5);
}

.rlr-review-card__contact {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  background: var(--gray-00);
  border-bottom: 1px solid var(--gray-03);
  border-top-left-radius: var(--spacing-5);
  border-top-right-radius: var(--spacing-5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-4) var(--spacing-5);
}

.rlr-review-card__details {
  padding: var(--spacing-10) var(--spacing-5);
}

.rlr-review-card__title {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  border-bottom: 1px solid var(--gray-03);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--spacing-7);
  padding-bottom: var(--spacing-5);
}

.rlr-review-card__title-review {
  color: var(--heading-color);
}

.rlr-review-card__review-date {
  color: var(--body-color);
  letter-spacing: 0.5px;
}

/*!  ==============================================================
<!-- 5.30 Avatar ==== -->
============================================================== **/
.rlr-avatar__media {
  max-height: var(--spacing-11);
}

.rlr-avatar__media--rounded {
  border-radius: 50%;
}

.rlr-avatar__name {
  color: var(--body-color);
  font-weight: 300;
  margin-left: var(--spacing-5);
}

/*!  ==============================================================
<!-- 5.31 Rating star ==== -->
============================================================== **/
.rlr-rating-stars {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.rlr-rating-stars__label {
  color: var(--heading-color);
  font-weight: 500;
}

.rlr-rating-stars__iconset {
  -webkit-column-gap: var(--spacing-3);
  -moz-column-gap: var(--spacing-3);
  column-gap: var(--spacing-3);
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.rlr-rating-stars__iconset .rlr-icon-font {
  color: var(--warning-color);
}

/*!  ==============================================================
<!-- 5.32 Recommend button ==== -->
============================================================== **/
.rlr-recommendation-button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.rlr-recommendation-button__label {
  color: var(--heading-color);
  font-weight: 500;
}

.rlr-recommendation-button__buttons {
  -webkit-column-gap: var(--spacing-4);
  -moz-column-gap: var(--spacing-4);
  column-gap: var(--spacing-4);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.rlr-recommendation-button__hidden {
  display: none;
}

.rlr-recommendation-button__button {
  background-color: transparent;
  border-radius: 50%;
  padding: 1rem 1.2rem 0.6rem;
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.rlr-recommendation-button__button--approve {
  border: 2px solid var(--brand-02);
}

.rlr-recommendation-button__button--approve .rlr-icon-font {
  color: var(--brand);
}

.rlr-recommendation-button__button--approve--active,
.rlr-recommendation-button__button--approve:hover {
  background-color: var(--brand-02);
}

.rlr-recommendation-button__button--approve.active_class {
  background-color: var(--brand-02);
}

.rlr-recommendation-button__button--approve.active,
.rlr-recommendation-button__button--approve:active,
.rlr-recommendation-button__button--approve:target {
  background-color: var(--brand-03);
}

.rlr-recommendation-button__button--disapprove {
  border: 2px solid var(--error-02);
}

.rlr-recommendation-button__button--disapprove .rlr-icon-font {
  color: var(--error-06);
}

.rlr-recommendation-button__button--disapprove--active,
.rlr-recommendation-button__button--disapprove:hover {
  background-color: var(--error-02);
}

.rlr-recommendation-button__button--disapprove.active_class {
  background-color: var(--error-02);
}

.rlr-recommendation-button__button--disapprove.active,
.rlr-recommendation-button__button--disapprove:active,
.rlr-recommendation-button__button--disapprove:target {
  background-color: var(--error-04);
}

/*!  ==============================================================
<!-- 5.33 Share ==== -->
============================================================== **/
.rlr-share {
  background-color: var(--white);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 6px 6px 4px hsla(0, 0%, 71%, 0.25);
  box-shadow: 0 6px 6px 4px hsla(0, 0%, 71%, 0.25);
  max-width: 23.438rem;
  min-width: 22.5rem;
  padding: var(--spacing-8) var(--spacing-7) var(--spacing-10) var(--spacing-7);
}

.rlr-share__items {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0;
}

.rlr-share__list {
  list-style: none;
  /* text-decoration: none; */
}

.rlr-share__item {
  cursor: pointer;
  width: 5rem;
}

.rlr-share__item .rlr-icon-font {
  font-size: 2rem;
}

.rlr-share__title {
  color: var(--heading-color);
  margin-bottom: var(--spacing-8);
  text-align: center;
}

.rlr-share__divider {
  color: var(--gray-03);
  margin-bottom: var(--spacing-9);
  opacity: 1;
}

.rlr-share__button-wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.rlr-share__button-cancel {
  border: 1px solid var(--gray-04);
  color: var(--neutral-color);
}

/*!  ==============================================================
<!-- 5.34 Popover ==== -->
============================================================== **/
.rlr-popover--hide {
  display: none;
}

.rlr-popover__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.rlr-popover__header .rlr-icon-font {
  color: var(--success-color);
  font-size: 2rem;
  line-height: 0;
}

.rlr-popover__header .rlr-icon-font:hover {
  color: var(--success-07);
}

.rlr-popover__header button {
  padding: 1rem 0;
}

.rlr-popover__checkbox-wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.rlr-popover-button {
  background: var(--gray-01);
}

.rlr-popover-button.is-active,
.rlr-popover-button:active,
.rlr-popover-button:hover {
  background: var(--gray-01);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--brand);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rlr-popover--travellers .rlr-input-groups .rlr-popover__numberfield.rlr-input-group {
  -webkit-box-flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.rlr-popover--travellers .rlr-input-groups .rlr-popover__numberfield.rlr-input-group:not(:last-child) {
  border-bottom: 1px solid var(--gray-02);
}

.rlr-popover--travellers .rlr-form-label {
  margin-bottom: 0;
}

.rlr-popover--travellers .form-label--light {
  color: var(--body-color);
  margin-bottom: 0;
}

.rlr-popover--travellers .rlr-booking-card__counter {
  text-align: center;
}

.rlr-popover--travellers .rlr-input-group {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rlr-popover--travellers .rlr-input-group__iconset {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.rlr-popover--travellers .form-input {
  border: 0;
  color: var(--heading-color);
  margin: 0;
  padding: 0;
  text-align: center;
  width: 37px;
}

.rlr-popover--travellers .form-input::-webkit-input-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-popover--travellers .form-input::-moz-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-popover--travellers .form-input:-ms-input-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-popover--travellers .form-input::-ms-input-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-popover--travellers .form-input::placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-popover-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: var(--spacing-8);
  padding-left: 0;
}

.rlr-popover-wrapper .rlr-input-groups {
  gap: var(--spacing-3);
}

.tippy-box[data-theme="rlr-custom-tippy"] {
  border-radius: var(--spacing-8);
  min-width: 22.5rem;
}

.tippy-box[data-theme="rlr-custom-tippy"] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme="rlr-custom-tippy"] .tippy-arrow {
  color: var(--white);
}

.tippy-box[data-theme="rlr-booking-tippy"],
.tippy-box[data-theme="rlr-custom-tippy"] .tippy-arrow {
  -webkit-box-shadow: 0 6px 6px 4px hsla(0, 0%, 71%, 0.25);
  box-shadow: 0 6px 6px 4px hsla(0, 0%, 71%, 0.25);
}

.tippy-box[data-theme="rlr-booking-tippy"] {
  background: var(--white);
  border-radius: var(--spacing-5);
  color: var(--heading-color);
  min-width: 22.5rem;
  padding: var(--spacing-8) var(--spacing-7);
}

.tippy-box[data-theme="rlr-booking-tippy"] .tippy-arrow {
  color: var(--white);
}

.tippy-box[data-theme="rlr-autocomplete-tippy"] {
  background: var(--white);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 6px 6px 4px hsla(0, 0%, 71%, 0.25);
  box-shadow: 0 6px 6px 4px hsla(0, 0%, 71%, 0.25);
  color: var(--heading-color);
  padding: var(--spacing-9) var(--spacing-13);
}

/*!  ==============================================================
<!-- 5.35 Copylink ==== -->
============================================================== **/
.rlr-copylink__wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  -webkit-column-gap: var(--spacing-2);
  -moz-column-gap: var(--spacing-2);
  column-gap: var(--spacing-2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.rlr-copylink__wrapper .rlr-icon-font {
  color: var(--gray-06);
  cursor: pointer;
}

.rlr-copylink__wrapper .rlr-icon-font:hover {
  color: var(--brand);
}

.rlr-copylink__title {
  color: var(--heading-color);
  margin-bottom: 0.375rem;
}

.rlr-copylink__input {
  border: 2px solid var(--body-color);
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: var(--body-color);
  font-weight: 300;
  margin-right: var(--spacing-5);
  padding: 0.625rem 0.875rem;
  width: 100%;
}

.rlr-copylink__input::-webkit-input-placeholder {
  color: var(--body-color);
}

.rlr-copylink__input::-moz-placeholder {
  color: var(--body-color);
}

.rlr-copylink__input:-ms-input-placeholder {
  color: var(--body-color);
}

.rlr-copylink__input::-ms-input-placeholder {
  color: var(--body-color);
}

.rlr-copylink__input::placeholder {
  color: var(--body-color);
}

/*!  ==============================================================
<!-- 5.36 Calendar ==== -->
============================================================== **/
.flatpickr-calendar {
  padding: 0.5rem 0.075rem 1rem;
}

.flatpickr-months .flatpickr-month {
  height: 3rem;
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  position: relative;
}

.flatpickr-current-month {
  padding: 1rem;
}

.flatpickr-current-month .numInputWrapper {
  display: none;
}

.flatpickr-current-month span.cur-month {
  font-weight: 500;
}

.rangeMode .flatpickr-day {
  margin-top: var(--spacing-3);
}

.flatpickr-day {
  border: 1px solid var(--gray-00);
  font-size: 1rem;
  font-weight: 500;
}

.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
  border-radius: 0.5rem 0 0 0.5rem;
}

.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
  border-radius: 0 0.5rem 0.5rem 0;
}

.flatpickr-day.flatpickr-disabled {
  color: var(--gray-04);
  cursor: not-allowed;
}

.shortcut-buttons-flatpickr-buttons>.shortcut-buttons-flatpickr-button {
  background-color: var(--brand);
  border: 0;
  border-radius: var(--spacing-3);
  color: var(--white);
  display: block;
  margin: 2px;
  padding: var(--spacing-2) var(--spacing-4);
  text-align: center;
}

.light.shortcut-buttons-flatpickr-wrapper {
  background-color: var(--gray-01);
  color: var(--body-color);
}

/*!  ==============================================================
<!-- 5.37 Icon card ==== -->
============================================================== **/
.rlr-icon-text__card {
  background-color: var(--white);
  border: 1px solid var(--gray-03);
  border-radius: var(--spacing-5);
  -webkit-column-gap: var(--spacing-5);
  -moz-column-gap: var(--spacing-5);
  column-gap: var(--spacing-5);
  padding: var(--spacing-5);
  width: 100%;
}

.rlr-icon-text__card--active,
.rlr-icon-text__card:hover {
  background-color: var(--brand-0);
  border: 1px solid var(--brand-03);
}

.rlr-icon-text__card.active,
.rlr-icon-text__card:active,
.rlr-icon-text__card:target {
  background-color: var(--brand-03);
}

.rlr-icon-text__card .rlr-icon-font {
  font-size: 1.5rem;
}

.rlr-icon-text__card-title {
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.rlr-icon-text.is-active {
  background-color: var(--brand-00);
}

/*!  ==============================================================
<!-- 5.38 Tooltip ==== -->
============================================================== **/
.rlr-text-card {
  background-color: var(--white);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.06);
  padding: var(--spacing-8);
}

.rlr-text-card__title {
  color: var(--brand-09);
  letter-spacing: 0;
  margin-bottom: var(--spacing-5);
}

.rlr-text-card__desc {
  color: var(--body-color);
  margin-bottom: 0;
}

.rlr-text-card--numbered .rlr-text-card__title {
  background-color: var(--brand);
  border-radius: 50%;
  color: var(--white);
  display: grid;
  height: 2.5rem;
  letter-spacing: 0;
  margin-bottom: var(--spacing-7);
  place-items: center;
  width: 2.5rem;
}

/*!  ==============================================================
<!-- 5.39 Contact Detail Card ==== -->
============================================================== **/
.rlr-contact-detail-item {
  grid-column-gap: var(--spacing-7);
  background-color: var(--white);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.07);
  display: grid;
  grid-template-columns: 4.5rem auto;
  padding: var(--spacing-7);
}

.rlr-contact-detail-item__icon {
  background-color: var(--brand-01);
  display: grid;
  font-size: 2rem;
  height: 4.5rem;
  place-items: center;
}

.rlr-contact-detail-item__icon i {
  color: var(--brand);
  display: grid;
  place-items: center;
}

.rlr-contact-detail-item__title {
  color: var(--gray-05);
  line-height: var(--spacing-6);
  margin-bottom: var(--spacing-5);
}

.rlr-contact-detail-item__desc {
  color: var(--gray-09);
  line-height: 1.875rem;
  margin-bottom: 0;
}

/*!  ==============================================================
<!-- 5.40 Contact Details ==== -->
============================================================== **/
.rlr-contact-details__details {
  grid-row-gap: var(--spacing-7);
  display: grid;
  padding-left: var(--spacing-7);
  padding-right: var(--spacing-7);
}

.rlr-contact-details__map {
  position: relative;
  top: -2.5rem;
  z-index: -1;
}

.rlr-contact-details__map iframe {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 0;
  border-radius: var(--spacing-5);
  -webkit-filter: grayscale(99%);
  filter: grayscale(99%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: gray;
  filter: gray;
  height: 27.3125rem;
  width: 100%;
}

.rlr-contact-details--v2 .rlr-contact-details__details {
  -webkit-box-align: center;
  -ms-flex-align: center;
  grid-gap: var(--spacing-7);
  align-items: center;
  border: 1px solid var(--gray-03);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: var(--spacing-7);
}

.rlr-contact-details--v2 .rlr-contact-details__details .rlr-contact-detail-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 100%;
  padding: 0;
}

.rlr-contact-details--v2 .rlr-contact-details__details .rlr-contact-detail-item:not(:last-child) {
  border-right: 1px solid var(--gray-03);
}

@media (max-width: 991.98px) {
  .rlr-contact-details--v2 .rlr-contact-details__details {
    grid-template-columns: repeat(1, 1fr);
  }

  .rlr-contact-details--v2 .rlr-contact-details__details .rlr-contact-detail-item:not(:last-child) {
    border-bottom: 1px solid var(--gray-03);
    border-right: none;
    padding-bottom: var(--spacing-7);
  }
}

.rlr-contact-details--v2 .rlr-contact-details__map {
  top: 0;
}

.rlr-contact-details--v2 .rlr-contact-details__map iframe {
  border: 0;
  height: 48rem;
}

/*!  ==============================================================
<!-- 5.41 Product card ==== -->
============================================================== **/
.rlr-product-card {
  background: #fbfbfb;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(14, 85, 124, 0.16);
  box-shadow: 0 2px 4px 0 rgba(14, 85, 124, 0.16);
  position: relative;
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;

}

.rlr-product-card__image-wrapper {
  border-radius: 0.5rem;
  margin-bottom: 0;
  padding: var(--spacing-5);
  position: relative;
  width: 100%;
}

.rlr-product-card__image-wrapper .ph-picture,
.rlr-product-card__image-wrapper img {
  border-radius: 0.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.rlr-product-card__badge {
  left: var(--spacing-8);
  position: absolute;
  top: var(--spacing-8);
}

.rlr-product-card__detail-wrapper {
  padding: var(--spacing-4) var(--spacing-5);
}

.rlr-product-card__header {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: flex-start;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--spacing-8);
  position: relative;
  z-index: 2;
}

.rlr-product-card__title {
  color: var(--heading-color);
  line-height: 1.5;
  margin-bottom: var(--spacing-3);
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.rlr-product-card__anchor,
.rlr-product-card__anchor-cat,
.rlr-product-card__anchor-sub-cat,
.rlr-product-card__anchor-title {
  text-decoration: none;
}

.rlr-product-card__anchor {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.rlr-product-card__anchor--featured {
  bottom: 4rem;
}

.rlr-product-card__sub-title {
  color: var(--neutral-color);
  letter-spacing: 0.5px;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.rlr-product-card__sub-title:hover {
  color: var(--body-color);
}

.rlr-product-card__from,
.rlr-product-card__rating-text,
.rlr-product-card__relates {
  color: var(--neutral-color);
  font-size: 12px !important;
}

.rlr-product-card__price {
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

.rlr-product-card__price mark {
  background: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(80%, transparent), color-stop(20%, var(--maximum-yellow-red-bg)));
  background-image: -o-linear-gradient(transparent 80%, var(--maximum-yellow-red-bg) 20%);
  background-image: linear-gradient(transparent 80%, var(--maximum-yellow-red-bg) 20%);
  background-size: 10% 86%;
  color: var(--heading-color);
  display: inline-block;
  padding: 0;
  -webkit-transition: color 0.3s ease-in-out, background-image 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out, background-image 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-image 0.3s ease-in-out;
}

.rlr-product-card__prices {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.25rem;
}

.rlr-product-card__details {
  -webkit-box-orient: horizontal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: var(--spacing-9);
}

.rlr-product-card__details,
.rlr-product-card__ratings {
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.rlr-product-card__ratings {
  -webkit-box-orient: vertical;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: var(--spacing-3);
}

.rlr-product-card__footer .rlr-icon-font {
  color: var(--brand);
}

.rlr-product-card__icon-text-list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-column-gap: var(--spacing-7);
  -moz-column-gap: var(--spacing-7);
  column-gap: var(--spacing-7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-top: var(--spacing-3);
  padding: 0;
}

.rlr-product-card__icon-text-list .rlr-product-card__icon-text {
  font-weight: 400;
}

.rlr-product-card__icon-text {
  color: var(--body-color);
  line-height: 1.25;
}

.rlr-product-card:hover {
  -webkit-box-shadow: 0 2px 8px 2px rgba(14, 85, 124, 0.16);
  box-shadow: 0 2px 8px 2px rgba(14, 85, 124, 0.16);
}

.rlr-product-card:hover .rlr-product-card__price mark,
.rlr-product-card:hover .rlr-product-card__title {
  color: var(--brand);
}

.rlr-product-card:hover .rlr-product-card__price mark {
  background-image: none;
}

.rlr-product-card .ph-item {
  min-height: 618px;
}

.rlr-product-card--featured .rlr-product-detail-header__helptext {
  color: var(--white);
}

.rlr-product-card--featured__inner {
  opacity: 1;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 1;
  /* position: relative; */
}

.rlr-product-card--featured:hover .rlr-product-card--featured__inner {
  opacity: 1;
  z-index: 1;
}

.rlr-product-card--featured__body {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.rlr-product-card--featured__body .rlr-product-detail-header__actions {
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-product-card--featured .rlr-button {
  margin-left: var(--spacing-5);
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .rlr-product-card--featured .rlr-button {
    margin-left: var(--spacing-2);
  }
}

.rlr-product-card--featured .rlr-button--brand {
  background-color: var(--brand);
  padding: 0 1rem;
  font-size: 13px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 7px;
}

.rlr-product-card--featured .rlr-product-card__image-wrapper {
  padding: 0;
}

.rlr-product-card--featured .rlr-product-card__image-wrapper img {
  height: 200px;
}

.rlr-product-card__image-wrapper_new img {
  height: 150px !important;
}

.rlr-product-card--featured__summary {
  padding: 20px;
  text-align: center;
}

.rlr-product-card--featured__summary .type-h6-semi-bold {
  padding-bottom: var(--spacing-4);
}

.rlr-product-card .card-img-overlay {
  border-radius: calc(0.25rem - 1px);
  bottom: 0;
  left: 0;
  padding: var(--spacing-5);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.rlr-product-card .rlr-product-card--featured__duration .body,
.rlr-product-card .rlr-product-card--featured__duration .type-h4 {
  color: var(--white);
}

.rlr-product-card .rlr-product-card--featured__duration .type-h4 {
  text-decoration: underline;
}

.rlr-product-card--v3 {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rlr-product-card--v3 .rlr-product-card__details,
.rlr-product-card--v3 .rlr-product-card__header {
  margin-bottom: var(--spacing-5);
}

.rlr-product-card--v3 .rlr-product-card__title {
  line-height: 1.5;
}

.rlr-product-card--v3 .rlr-button--circle {
  font-size: 1.5rem;
}

.rlr-product-card--v3 .rlr-review-stars {
  font-size: 0.75rem;
}

.rlr-product-card--v3 .rlr-product-detail-header__button-wrapper {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
}

.rlr-product-card--v3 .rlr-wishlist-button--light {
  color: var(--white);
}

.rlr-product-card--v3 .rlr-wishlist-button--light.is-active,
.rlr-product-card--v3 .rlr-wishlist-button--light:active,
.rlr-product-card--v3 .rlr-wishlist-button--light:hover {
  color: var(--accent-red);
}

.rlr-product-card--v3 .rlr-wishlist-button--light.active {
  color: var(--accent-red);
}

.rlr-product-card--v3 .rlr-product-detail-header__helptext {
  color: var(--white);
  top: 60%;
}

.rlr-product-card--v3 .splide__arrow {
  background-color: var(--white);
  border-radius: 2rem;
  color: var(--body-color);
}

.rlr-product-card--v3 .swiper-button-disabled {
  opacity: 0;
}

.rlr-js-product-card-swiper.swiper {
  margin: -13px !important;
  padding: 13px !important;
}

/*!  ==============================================================
<!-- 5.42 Search results header ==== -->
============================================================== **/
.rlr-search-results-header__wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-color: var(--white);
  border: 1px solid var(--gray-03);
  border-radius: var(--spacing-6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 20px;
  row-gap: var(--spacing-5);
}

.rlr-search-results-header__value {
  color: var(--heading-color);
  font-weight: 400;
  line-height: 1.5;
}

.rlr-search-results-header__value strong {
  color: var(--brand);
  text-decoration: underline;
}

.rlr-search-results-header__sorting-wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-7);
  -moz-column-gap: var(--spacing-7);
  column-gap: var(--spacing-7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.rlr-search-results-header__label {
  color: var(--body-color);
  font-weight: 300;
  margin-bottom: 0;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .rlr-search-results-header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
  }
}

/*!  ==============================================================
<!-- 5.43 Dropdown menu ==== -->
============================================================== **/
.rlr-dropdown__button {
  color: var(--heading-color);
  font-weight: 500;
  padding: 0;
}

.rlr-dropdown__button:after {
  background: url(../assets/svg/icon-down-arrow.svg) no-repeat 100% 100%;
  border: none;
  content: "";
  height: 1.5rem;
  margin-left: var(--spacing-7);
  -webkit-transform: scale(0.6) scaleX(1.2);
  -ms-transform: scale(0.6) scaleX(1.2);
  transform: scale(0.6) scaleX(1.2);
  vertical-align: middle;
  width: 1.5rem;
}

.rlr-dropdown__button:focus {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rlr-dropdown__menu {
  background-color: var(--white);
  border: none;
  border-radius: var(--spacing-3);
  -webkit-box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.05), 0 12px 16px -4px rgba(16, 24, 40, 0.1);
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.05), 0 12px 16px -4px rgba(16, 24, 40, 0.1);
  overflow: hidden;
  padding: 0;
}

@media (max-width: 767.98px) {
  .rlr-dropdown__menu {
    max-height: 20rem;
    overflow-y: scroll;
  }
}

.rlr-dropdown__item {
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.25;
  padding: var(--spacing-4) var(--spacing-5);
}

.rlr-dropdown__item.active,
.rlr-dropdown__item:hover {
  background-color: var(--brand-00);
  color: var(--body-color);
}

.rlr-dropdown__divider {
  background: var(--gray-03);
  margin: 0;
}

/*!  ==============================================================
<!-- 5.44 Banner input group ==== -->
============================================================== **/
.rlr-banner-input-group {
  -webkit-box-flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 var(--spacing-5) 0 0;
  position: relative;
}

@media (min-width: 1200px) {
  .rlr-banner-input-group {
    padding: 0 var(--spacing-12) 0 0;
  }
}

.rlr-banner-input-group__label mark {
  background: transparent;
  color: var(--heading-color);
  display: inline-block;
  letter-spacing: 0.56px;
  padding: 0;
  -webkit-transition: color 0.3s ease-in-out, background-image 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out, background-image 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-image 0.3s ease-in-out;
}

.rlr-banner-input-group ul.rlr-autocomplete {
  padding-left: 0;
}

.rlr-banner-input-group__input-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-3);
  -moz-column-gap: var(--spacing-3);
  column-gap: var(--spacing-3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: var(--spacing-5);
}

.rlr-banner-input-group__input-wrapper .rlr-icon-font {
  font-size: 1rem;
  line-height: 1rem;
}

.rlr-banner-input-group__input {
  background-color: transparent;
  background-position: 98% 50%;
  background-repeat: no-repeat;
  border: none;
  font-weight: 300;
  line-height: 1.11;
  overflow: hidden;
  padding: 0;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rlr-banner-input-group__input.eee_home_search_flatpickr.form-control {
  height: auto;
}

.rlr-banner-input-group__input::-webkit-input-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-banner-input-group__input::-moz-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-banner-input-group__input:-ms-input-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-banner-input-group__input::-ms-input-placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-banner-input-group__input::placeholder {
  color: var(--neutral-color);
  text-decoration: underline;
}

.rlr-banner-input-group__input:focus {
  background: transparent;
  border: none;
  outline: none;
}

.rlr-banner-input-group--activity-dropdown,
.rlr-banner-input-group--location-dropdown {
  background-color: var(--white);
  border-radius: 0 0 1rem 1rem;
  max-height: 512px;
  min-width: 0;
  overflow-y: scroll;
  top: 92px;
  width: 100%;
}

.rlr-banner-input-group--activity-dropdown li {
  margin: 0;
}

/*!  ==============================================================
<!-- 5.45 Autocomplete ==== -->
============================================================== **/
.rlr-autocomplete,
.rlr-js-autocomplete {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.04), 0 20px 24px -4px rgba(16, 24, 40, 0.1);
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.04), 0 20px 24px -4px rgba(16, 24, 40, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  row-gap: var(--spacing-3);
  z-index: 20;
}

.rlr-autocomplete__item,
.rlr-js-autocomplete__item {
  cursor: pointer;
  list-style: none;
  padding: var(--spacing-3) var(--spacing-5);
}

.rlr-autocomplete__item:hover,
.rlr-js-autocomplete__item:hover {
  background-color: var(--brand-01);
}

.rlr-autocomplete__item:last-child:hover,
.rlr-js-autocomplete__item:last-child:hover {
  border-radius: 0 0 1rem 1rem;
}

.rlr-autocomplete__item-wrapper,
.rlr-js-autocomplete__item-wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--body-color);
  -webkit-column-gap: var(--spacing-7);
  -moz-column-gap: var(--spacing-7);
  column-gap: var(--spacing-7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-weight: 400;
  line-height: 1.11;
  text-decoration: none;
  vertical-align: middle;
}

.rlr-autocomplete__item-wrapper:hover,
.rlr-js-autocomplete__item-wrapper:hover {
  color: var(--body-color);
}

.rlr-autocomplete__text-wrapper,
.rlr-js-autocomplete__text-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: var(--spacing-3);
}

.rlr-autocomplete__text,
.rlr-js-autocomplete__text {
  color: var(--heading-color);
  line-height: 1.11;
}

.rlr-autocomplete__sub-text,
.rlr-js-autocomplete__sub-text {
  color: var(--heading-color);
  letter-spacing: 0.5px;
  margin-bottom: var(--spacing-2);
}

.rlr-autocomplete--hidden,
.rlr-js-autocomplete--hidden {
  display: none;
}

/*!  ==============================================================
<!-- 5.46 Category card ==== -->
============================================================== **/
.rlr-category-card {
  background-color: var(--white);
  border: 1px solid var(--gray-04);
  border-radius: var(--spacing-3);
  -webkit-box-shadow: 0 2px 4px 0 rgba(14, 85, 124, 0.16);
  box-shadow: 0 2px 4px 0 rgba(14, 85, 124, 0.16);
  -webkit-box-shadow: 0 2px 4px rgba(14, 86, 124, 0.16);
  box-shadow: 0 2px 4px rgba(14, 86, 124, 0.16);
  padding: var(--spacing-8);
  -webkit-transition: -webkit-box-shadow 0.2s linear;
  transition: -webkit-box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
}

.rlr-category-card:hover {
  -webkit-box-shadow: 0 1px 2px -2px rgba(14, 85, 124, 0.46);
  box-shadow: 0 1px 2px -2px rgba(14, 85, 124, 0.46);
}

.rlr-category-card__badge {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--spacing-10);
}

.rlr-category-card__icon {
  color: var(--brand);
  line-height: 0;
  margin-bottom: var(--spacing-5);
}

.rlr-category-card__icon .rlr-icon-font {
  font-size: 2rem;
}

.rlr-category-card__icon svg {
  fill: var(--brand);
}

.rlr-category-card__title {
  color: var(--heading-color);
  text-decoration: none;
}

.rlr-category-card__title h4 {
  font-weight: 400;
}

.rlr-category-card__title h4:hover {
  color: var(--brand-08);
}

.rlr-category-card__sub-title {
  color: var(--neutral-color);
  letter-spacing: 0.5px;
  text-align: left;
  text-decoration: none;
}

.rlr-category-card__sub-title:hover {
  color: var(--neutral-color);
}

/*!  ==============================================================
<!-- 5.47 Banner ==== -->
============================================================== **/
.rlr-banner__media .rlr-banner-splide--fullwidth__form {
  -ms-flex-item-align: auto;
  align-self: auto;
  margin: 0 !important;
}

.rlr-banner__media .outter {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.rlr-banner__media .video-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .rlr-banner__media .video-container {
    height: 80vh;
  }
}

.rlr-banner__media video {
  height: 100vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.rlr-banner__media video::-webkit-media-controls {
  background: rgba(0, 0, 0, 0.3);
}

.rlr-banner__media .type-d1 {
  font-size: calc(1.725rem + 5.7vw);
  font-weight: 700;
}

@media (min-width: 1200px) {
  .rlr-banner__media .type-d1 {
    font-size: 6rem;
  }
}

.rlr-banner__media .rlr-callout {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 2rem auto auto;
  position: relative;
}

.rlr-banner__media .rlr-callout .rlr-callout__text {
  display: none;
}

@media (min-width: 1200px) {
  .rlr-banner__media .rlr-callout .rlr-callout__text {
    display: inline;
  }
}

.rlr-banner__media .rlr-callout h1,
.rlr-banner__media .rlr-callout p {
  color: var(--white);
}

@media (min-width: 1400px) {
  .rlr-banner__media .rlr-callout {
    gap: var(--spacing-12);
  }

  .rlr-banner__media .rlr-callout__text {
    text-align: center;
  }
}

.rlr-banner-splide {
  position: relative;
}

.rlr-banner-splide__slide {
  grid-column-gap: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  position: relative;
  row-gap: 0;
}

.rlr-banner-splide__image-wrapper {
  border-radius: 1.5rem;
  overflow: hidden;
  height: 450px;
}

.rlr-banner-splide__content-wrapper,
.rlr-banner-splide__image-wrapper,
.rlr-banner-splide__overlay,
.rlr-banner-splide__search-layout-container {
  grid-area: 1/1/6/6;
}

.rlr-banner-splide__overlay {
  background: rgba(0, 0, 0, 0.22);
  height: 100%;
  width: 100%;
}

.rlr-banner-splide__banner-img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.rlr-banner-splide__content-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-7) var(--spacing-8);
  position: relative;
  row-gap: var(--spacing-9);
}

@media (min-width: 768px) {
  .rlr-banner-splide__content-wrapper {
    padding: var(--spacing-10) var(--spacing-11);
  }
}

@media (min-width: 1200px) {
  .rlr-banner-splide__content-wrapper {
    padding: var(--spacing-15) var(--spacing-17) var(--spacing-18) var(--spacing-17);
  }
}

.rlr-banner-splide__header {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  gap: var(--spacing-3);
}

.rlr-banner-splide__slogan {
  color: var(--white);
  letter-spacing: -2.56px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

.rlr-banner-splide__sub-title {
  color: var(--white);
  font-weight: 400;
  margin-bottom: 10px;
}

.rlr-banner-splide__sub-title:before {
  background-color: var(--white);
  content: "";
  display: inline-block;
  height: var(--spacing-1);
  margin-right: var(--spacing-5);
  vertical-align: middle;
  width: 1.5rem;
}

.rlr-banner-splide__content-desc {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: var(--spacing-8);
}

@media (min-width: 768px) {
  .rlr-banner-splide__content-desc {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
  }
}

.rlr-banner-splide__content-desc-right {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 23rem;
}

@media (min-width: 576px) {
  .rlr-banner-splide__content-desc-right {
    margin-left: var(--spacing-7);
  }
}

.rlr-banner-splide__payment-option {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: var(--spacing-5);
}

@media (min-width: 576px) {
  .rlr-banner-splide__payment-option {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .rlr-banner-splide__payment-option {
    width: 40%;
  }
}

.rlr-banner-splide__payment-option .flaticon-credit-cards-payment {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 2rem;
  height: 4rem;
  justify-content: center;
  position: relative;
  width: 4rem;
}

.rlr-banner-splide__payment-option .flaticon-credit-cards-payment:after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 4rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 4rem;
  z-index: -1;
}

.rlr-banner-splide__payment-desc {
  color: var(--white);
  font-weight: 300;
}

.rlr-banner-splide__temperature {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: var(--spacing-8);
}

.rlr-banner-splide__temperature span {
  color: var(--white);
  font-weight: 400;
}

.rlr-banner-splide__book-now.rlr-button {
  color: var(--white);
  font-weight: 600;
  letter-spacing: normal;
  padding-left: 0;
  text-decoration: underline;
}

.rlr-banner-splide__book-now.rlr-button:hover {
  color: var(--brand-03);
  text-decoration: none;
  -webkit-transition: all 2s linear;
  -o-transition: all 2s linear;
  transition: all 2s linear;
}

.rlr-banner-splide__arrows {
  -webkit-column-gap: 0.594rem;
  -moz-column-gap: 0.594rem;
  column-gap: 0.594rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-banner-splide__arrow {
  background: transparent;
  border: none;
}

.rlr-banner-splide__arrow i {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 2.906rem;
  font-weight: 100;
  height: 2.906rem;
  justify-content: center;
  width: 2.906rem;
}

.rlr-banner-splide__arrow i:hover {
  opacity: 0.5;
}

.rlr-banner-splide__search-layout {
  position: relative;
  top: 100%;
}

@media (min-width: 1200px) {
  .rlr-banner-splide__search-layout {
    left: 5%;
    top: calc(100% - var(--spacing-15));
    width: 90%;
  }
}

@media (min-width: 1400px) {
  .rlr-banner-splide__search-layout {
    left: 10%;
    width: 80%;
  }
}

.rlr-banner-splide__search-layout .rlr-banner-input-group__input {
  margin-right: 0;
}

.rlr-banner-splide--v3 .rlr-banner-splide__content-wrapper {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rlr-banner-splide--fullwidth .rlr-banner-splide__slide {
  border-radius: 0;
}

@media (min-width: 992px) {
  .rlr-banner-splide--fullwidth .rlr-banner-splide__slide {
    height: calc(100vh - 200px);
  }
}

.rlr-banner-splide--fullwidth .rlr-banner-splide__content-wrapper,
.rlr-banner-splide--fullwidth .rlr-banner-splide__image-wrapper {
  border-radius: 0;
}

.rlr-banner-splide--fullwidth .rlr-banner-splide__content-wrapper {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-top: calc(var(--spacing-7) + var(--spacing-18));
}

@media (min-width: 768px) {
  .rlr-banner-splide--fullwidth .rlr-banner-splide__content-wrapper {
    padding-top: calc(var(--spacing-10) + var(--spacing-18));
  }
}

@media (min-width: 1200px) {
  .rlr-banner-splide--fullwidth .rlr-banner-splide__content-wrapper {
    padding-top: calc(var(--spacing-15) + var(--spacing-18));
  }
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slogan,
.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__sub-title {
  color: var(--white);
  text-align: center;
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slogan {
  letter-spacing: 1px;
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slide {
  border-radius: 0;
  height: auto;
}

@media (min-width: 768px) {
  .rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slide {
    height: 100vh;
  }
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__content-wrapper,
.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__image-wrapper {
  border-radius: 0;
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__content-wrapper {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: calc(var(--spacing-7) + var(--spacing-18));
}

@media (min-width: 768px) {
  .rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__content-wrapper {
    padding-top: calc(var(--spacing-10) + var(--spacing-18));
  }
}

@media (min-width: 1200px) {
  .rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__content-wrapper {
    padding-top: calc(var(--spacing-15) + var(--spacing-18));
  }
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-search--hero-full-screen {
  left: 0;
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-font {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-column-gap: 7rem;
  -moz-column-gap: 7rem;
  column-gap: 7rem;
  display: none;
  justify-content: center;
  margin-top: 2rem;
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-font .rlr-icon-font {
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 50%;
  color: var(--white);
  display: none;
  display: inline-block;
  font-size: 3rem;
  padding: 0.75rem 1.5rem;
  -webkit-transition: background 0.2s, -webkit-transform 0.1s ease-out;
  transition: background 0.2s, -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out, background 0.2s;
  transition: transform 0.1s ease-out, background 0.2s;
  transition: transform 0.1s ease-out, background 0.2s, -webkit-transform 0.1s ease-out;
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-font .rlr-icon-font:hover {
  background: var(--brand);
  color: var(--white);
  -webkit-transform: scale(0.93);
  -ms-transform: scale(0.93);
  transform: scale(0.93);
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-font .rlr-icon-text__block span {
  color: var(--white);
}

@media (min-width: 992px) {
  .rlr-banner-splide--fullwidth-v2 .rlr-banner-font {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.rlr-banner-splide--fullwidth {
  align-self: center;
  display: grid;
  grid-template-areas: overlap;
  grid-template-columns: 1fr;
  grid-template-rows: 3 1fr;
  justify-self: center;
}

.rlr-banner-splide--fullwidth__slider {
  grid-area: overlap;
}

.rlr-banner-splide--fullwidth__form {
  align-self: self-end;
  grid-area: overlap;
  justify-self: center;
  margin: var(--spacing-18);
}

@media (min-width: 992px) {
  .rlr-banner-splide--fullwidth__form {
    margin-bottom: var(--spacing-18);
  }

  .rlr-banner-splide--fullwidth .rlr-banner-search {
    margin: 0 auto;
    max-width: 80%;
  }
}

@media (max-width: 991.98px) {
  .rlr-banner-splide--fullwidth .rlr-banner-splide__content-wrapper {
    display: none;
  }
}

/*!  ==============================================================
<!-- 5.48 Blog card ==== -->
============================================================== **/
.rlr-postcard {
  background: var(--white);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 2px 8px 2px rgba(14, 86, 124, 0.08);
  box-shadow: 0 2px 8px 2px rgba(14, 86, 124, 0.08);
  color: var(--body-color);
  display: block;
  margin-bottom: var(--spacing-8);
  padding: var(--spacing-5);
  text-decoration: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 991px) {
  .rlr-postcard {
    border-radius: 0.5rem;
    padding: 0.25rem;
  }
}

.rlr-postcard:hover {
  -webkit-box-shadow: 0 2px 8px 2px rgba(14, 86, 124, 0.25);
  box-shadow: 0 2px 8px 2px rgba(14, 86, 124, 0.25);
  color: var(--body-color);
}

.rlr-postcard__thumbnail {
  border-radius: var(--spacing-5);
  width: 100%;
}

@media (max-width: 991px) {
  .rlr-postcard__thumbnail {
    border-radius: 0.25rem;
  }
}

.rlr-postcard__summary {
  padding: var(--spacing-5);
}

.rlr-postcard__author {
  color: var(--brand-08);
}

.rlr-postcard__title {
  color: var(--heading-color);
  margin: 0.5rem 0 1.5rem;
}

@media (max-width: 768px) {
  .rlr-postcard__title {
    margin: 0.25rem 0 0.5rem;
  }
}

.rlr-postcard__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 1.5rem 0 0;
  padding: 0;
}

.rlr-postcard__tags li {
  background: url(../assets/images/checkmark.png) no-repeat 0 8px;
  color: var(--body-color);
  list-style: none;
  margin: 0;
  padding: 0 1.5rem;
}

.rlr-postcard .rlr-product-card__footer {
  margin-top: var(--spacing-10);
}

.rlr-postcard .rlr-product-card__title:hover {
  color: var(--brand);
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.rlr-postcard p {
  word-break: break-all;
}

/*!  ==============================================================
<!-- 5.49 Support ==== -->
============================================================== **/
.rlr-support-card {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding: var(--spacing-13) var(--spacing-11);
}

.rlr-support-card__content {
  text-align: center;
}

.rlr-support-card__img-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 104px;
  justify-content: center;
  margin: 0 auto var(--spacing-11);
  width: 104px;
}

.rlr-support-card__img-wrapper img {
  width: 56px;
}

.rlr-support-card__title {
  color: #fff;
  margin-bottom: var(--spacing-5);
}

.rlr-support-card__text {
  color: var(--white);
}

.rlr-support-card__subtitle {
  color: var(--brand-01);
}

.rlr-support-card--sale {
  background-color: var(--brand);
}

@media screen and (max-width: 991px) {
  .rlr-support-card--sale {
    margin-bottom: 16px;
  }
}

.rlr-support-card--help {
  background-color: var(--heading-color);
}

.rlr-support-card--help .rlr-support-card__subtitle {
  color: var(--neutral-color);
}

/*!  ==============================================================
<!-- 5.50 Subscribe ==== -->
============================================================== **/
.mc4wp-form-fields,
.rlr-subscribe {
  border-radius: 16px;
  -webkit-box-shadow: 0 32px 84px rgba(14, 86, 124, 0.16);
  box-shadow: 0 32px 84px rgba(14, 86, 124, 0.16);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

@media (max-width: 991.98px) {

  .mc4wp-form-fields,
  .rlr-subscribe {
    border-radius: 10px;
  }
}

.mc4wp-form-fields__input,
.rlr-subscribe__input {
  -webkit-box-flex: 1;
  background: url(../assets/images/icon-email.png) no-repeat 20px 50%;
  border: 0 !important;
  display: block;
  -ms-flex: 1;
  flex: 1;
  height: 80px;
  outline: 0;
  padding: 0 0 0 60px;
  width: calc(100% - 150px);
}

@media (max-width: 575.98px) {

  .mc4wp-form-fields__input,
  .rlr-subscribe__input {
    width: calc(100% - 100px);
  }
}

@media (max-width: 991px) {

  .mc4wp-form-fields__input,
  .rlr-subscribe__input {
    height: 50px;
  }
}

.mc4wp-form-fields .btn,
.rlr-subscribe .btn {
  background: var(--brand);
  border: 0;
  border-radius: 0;
  color: var(--white);
  height: 80px;
  white-space: nowrap;
}

@media (max-width: 575.98px) {

  .mc4wp-form-fields .btn,
  .rlr-subscribe .btn {
    padding: 6px 0;
    width: 100px;
  }
}

@media (max-width: 991px) {

  .mc4wp-form-fields .btn,
  .rlr-subscribe .btn {
    font-size: 1rem;
    height: 50px;
  }
}

/*!  ==============================================================
<!-- 5.51 Team ==== -->
============================================================== **/
.rlr-team-card {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 768px) {
  .rlr-team-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rlr-team-card__thumb {
  margin-right: var(--spacing-8);
  position: relative;
  width: 40%;
}

@media (max-width: 768px) {
  .rlr-team-card__thumb {
    margin: 0 0 20px;
    width: 80%;
  }
}

.rlr-team-card__thumb img {
  border-radius: var(--spacing-7);
  overflow: hidden;
  width: 100%;
}

.rlr-team-card__thumb .rlr-slide-count {
  background: var(--white);
  border-radius: 0 1.25rem;
  bottom: 0;
  color: var(--heading-color);
  height: 4.5rem;
  left: 0;
  line-height: 4.5rem;
  position: absolute;
  text-align: center;
  width: 4.5rem;
}

@media (max-width: 768px) {
  .rlr-team-card__thumb .rlr-slide-count {
    font-size: 1.125rem;
    height: 3rem;
    line-height: 3rem;
    width: 3rem;
  }
}

.rlr-team-card__summary {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.rlr-team-card__summary .rlr-team-card__designation {
  color: var(--brand);
  margin-bottom: var(--spacing-5);
}

@media (max-width: 991px) {
  .rlr-team-card__summary .rlr-team-card__designation {
    margin-bottom: 5px;
  }
}

.rlr-team-card__summary .rlr-team-card__name {
  color: var(--heading-color);
}

.rlr-team-card__summary .rlr-team-card__about-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 100px 0 120px;
}

@media (max-width: 1200px) {
  .rlr-team-card__summary .rlr-team-card__about-row {
    margin: 50px 0;
  }
}

@media (max-width: 991px) {
  .rlr-team-card__summary .rlr-team-card__about-row {
    margin: 20px 0;
  }
}

.rlr-team-card__summary .rlr-team-card__about-row .rlr-team-card__aboutbox {
  color: var(--body-color);
  padding: 0 15px 15px 0;
  width: 50%;
}

@media (max-width: 991px) {
  .rlr-team-card__summary .rlr-team-card__about-row .rlr-team-card__aboutbox {
    padding: 0 0 5px;
    width: 100%;
  }
}

.rlr-team-card__summary .rlr-team-card__sociallinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
}

@media (max-width: 991px) {
  .rlr-team-card__summary .rlr-team-card__sociallinks {
    font-size: 15px;
  }
}

.rlr-team-card__summary .rlr-team-card__sociallinks li {
  display: inline-block;
  list-style: none;
  margin: 0 30px 0 0;
  padding: 0;
}

.rlr-team-card__summary .rlr-team-card__sociallinks li a {
  color: var(--black);
  text-decoration: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.rlr-team-card__summary .rlr-team-card__sociallinks li a:hover {
  color: var(--brand);
}

.rlr-team-card--v2 {
  display: block;
  margin-bottom: var(--spacing-8);
  text-align: center;
}

@media (min-width: 1200px) {
  .rlr-team-card--v2 {
    margin-bottom: 0;
  }
}

.rlr-team-card--v2 .rlr-team-card__thumb {
  margin: 0 0 var(--spacing-8);
  width: 100%;
}

.rlr-team-card--v2 .rlr-team-card__thumb img {
  border-radius: 50%;
  margin: 0;
  max-width: 15rem;
}

.rlr-team-card--v2 .rlr-team-card__title>a {
  margin-bottom: 6px;
  text-decoration: none;
}

.rlr-team-card--v2 .rlr-team-card__title>a:hover {
  color: var(--brand);
}

.rlr-team-card--v2 .rlr-team-card__subtitle {
  color: var(--gray-10);
  margin-bottom: 7px;
}

.rlr-team-card--v2 .rlr-team-card__desc {
  color: var(--gray-05);
  margin-bottom: 0;
}

.rlr-break:before {
  content: "\a";
  white-space: pre;
}

/*!  ==============================================================
<!-- 5.52 CTA ==== -->
============================================================== **/
.rlr-cta {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background: url(../assets/svg/wave.svg) no-repeat 14% 54%;
  background-color: var(--brand);
  border-radius: var(--spacing-7);
  color: #bcf0ec;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 90px;
  text-align: center;
}

.rlr-cta svg {
  fill: var(--brand-01);
  stroke: --brand-01;
}

@media (max-width: 1200px) {
  .rlr-cta {
    padding: 60px 20px;
  }
}

@media (max-width: 768px) {
  .rlr-cta {
    padding: 30px 10px;
  }
}

.rlr-cta h2 {
  color: var(--white);
  margin-bottom: var(--spacing-8);
}

@media (max-width: 768px) {
  .rlr-cta h2 {
    margin-bottom: var(--spacing-4);
  }
}

.rlr-cta p {
  color: var(--brand-01);
  line-height: var(--spacing-10);
  margin-bottom: var(--spacing-8);
}

@media (max-width: 768px) {
  .rlr-cta p {
    margin-bottom: var(--spacing-4);
  }
}

.rlr-cta .rlr-button {
  -ms-flex-item-align: center;
  align-self: center;
  color: var(--brand);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.rlr-cta .rlr-button:hover {
  -webkit-box-shadow: 0 14px 50px rgba(14, 86, 124, 0.25);
  box-shadow: 0 14px 50px rgba(14, 86, 124, 0.25);
  opacity: 0.9;
}

.rlr-cta--no-button {
  border-radius: 0;
  padding: var(--spacing-10);
}

.rlr-cta--no-button h2,
.rlr-cta--no-button p {
  margin-bottom: 0;
}

/*!  ==============================================================
<!-- 5.53 Gallery ==== -->
============================================================== **/
.rlr-gallery--newsletter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  .rlr-gallery--newsletter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rlr-gallery--newsletter__thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  .rlr-gallery--newsletter__thumbs {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .rlr-gallery--newsletter__thumbs {
    padding: 0;
  }
}

.rlr-gallery--newsletter .rlr-gallery--newsletter__thumbs__col-1 {
  width: 48%;
}

.rlr-gallery--newsletter .rlr-gallery--newsletter__thumbs__col-1 img {
  border-radius: 8px;
  width: 100%;
}

.rlr-gallery--newsletter .rlr-gallery--newsletter__thumbs__col-2 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.rlr-gallery--newsletter .rlr-gallery--newsletter__thumbs__col-2 img {
  border-radius: 8px;
  padding: 0 0 25px 25px;
  width: 100%;
}

/*!  ==============================================================
<!-- 5.54 Newsletter ==== -->
============================================================== **/
.rlr-newsletter__content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media (min-width: 576px) {
  .rlr-newsletter__content {
    padding: 0 32px;
  }
}

.rlr-newsletter__content .rlr-newsletter__subtitle {
  color: var(--brand);
  margin-bottom: 20px;
}

.rlr-newsletter__content .rlr-newsletter__title {
  font-weight: 600;
  margin-bottom: var(--spacing-8);
}

.rlr-newsletter__content p {
  margin-bottom: 40px;
}

@media (max-width: 1200px) {
  .rlr-newsletter__content p {
    margin-bottom: 20px;
  }
}

.rlr-newsletter__content .rlr-newsletter__ps {
  margin-top: 48px;
}

/*!  ==============================================================
<!-- 5.55 Destination card ==== -->
============================================================== **/
.rlr-destination-card {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  word-wrap: break-word;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.056)), color-stop(58.08%, rgba(0, 0, 0, 0.336)), to(rgba(0, 0, 0, 0.56))), #6c757d;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.056) 0, rgba(0, 0, 0, 0.336) 58.08%, rgba(0, 0, 0, 0.56) 100%), #6c757d;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.056), rgba(0, 0, 0, 0.336) 58.08%, rgba(0, 0, 0, 0.56)), #6c757d;
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  border-radius: 0.5rem;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.rlr-destination-card:after {
  background: rgba(0, 0, 0, 0.3);
  content: "\a";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
}

.rlr-destination-card:hover:after {
  opacity: 1;
}

.rlr-destination-card__img {
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 250px;
}

.rlr-destination-card__info {
  z-index: 2;
}

.rlr-destination-card__info--left {
  left: var(--spacing-5);
  position: absolute;
}

.rlr-destination-card__info--bottom {
  bottom: var(--spacing-5);
  position: absolute;
}

.rlr-destination-card__info--main,
.rlr-destination-card__info--sub {
  color: var(--white);
}

.rlr-destination-card__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
}

.rlr-destination-card .rlr-badge--abs-dest {
  opacity: 0;
}

.rlr-destination-card:hover {
  -webkit-box-shadow: 0 6px 13px #00000030;
  box-shadow: 0 6px 13px #00000030;
  color: var(--white);
  -webkit-transition: -webkit-box-shadow 0.5s;
  transition: -webkit-box-shadow 0.5s;
  -o-transition: box-shadow 0.5s;
  transition: box-shadow 0.5s;
  transition: box-shadow 0.5s, -webkit-box-shadow 0.5s;
}

.rlr-destination-card:hover .rlr-badge--abs-dest {
  opacity: 1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
}

/*!  ==============================================================
<!-- 5.56 Wishlist ==== -->
============================================================== **/
.rlr-wishlist {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 2rem;
  justify-content: center;
}

.rlr-wishlist-button {
  background: var(--gray-01);
  color: var(--neutral-color);
}

.rlr-wishlist-button.is-active,
.rlr-wishlist-button:active,
.rlr-wishlist-button:hover {
  -webkit-box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
  box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
  color: var(--brand);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rlr-wishlist-button.active {
  -webkit-box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
  box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
  color: var(--brand);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rlr-wishlist-button--no-bg {
  background: transparent;
  color: var(--white);
}

.rlr-wishlist-button--light {
  background: transparent;
  color: var(--gray-04);
}

.rlr-wishlist-button--light.is-active,
.rlr-wishlist-button--light:active,
.rlr-wishlist-button--light:hover {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--brand);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rlr-wishlist--no-bg-button {
  background: transparent;
  color: var(--white);
}

.rlr-wishlist--no-bg-button.is-active,
.rlr-wishlist--no-bg-button:active,
.rlr-wishlist--no-bg-button:hover {
  background: transparent;
  -webkit-box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
  box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
  color: var(--brand);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rlr-wishlist .rlr-icon-font {
  line-height: 0.5;
}

/*!  ==============================================================
<!-- 5.57 Header Search ==== -->
============================================================== **/
.rlr-header-search {
  --search-icon-color: var(--heading-color);
  position: relative;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: var(--spacing-8);
}

.rlr-header-search__wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1199.98px) {
  .rlr-header-search__wrapper {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}

.rlr-header-search__wrapper li,
.rlr-header-search__wrapper ul {
  list-style: none;
}

@media (max-width: 991.98px) {
  .rlr-header-search__wrapper ul {
    padding-left: 1rem;
  }
}

.rlr-header-search__wrapper ul i span {
  display: none;
  font-style: normal;
}

@media (min-width: 992px) {
  .rlr-header-search__wrapper ul i span {
    display: none;
  }
}

.rlr-header-search__wrapper .rlr-header-search__results {
  background-color: var(--white);
  border-radius: 0 0 1rem 1rem;
  margin-left: 2rem;
  padding-left: 0;
  position: absolute;
  -webkit-transform: translate(-8px, 60px);
  -ms-transform: translate(-8px, 60px);
  transform: translate(-8px, 60px);
  z-index: 99999;
}

.rlr-header-search__wrapper .rlr-header-search__results .rlr-icon-font {
  color: var(--search-icon-color);
  font-size: 1.5rem;
  line-height: 0;
}

.rlr-header-search .form-control {
  border: 0;
}

.rlr-header-search .rlr-banner-input-group__input::-webkit-input-placeholder {
  color: var(--white);
}

.rlr-header-search .rlr-banner-input-group__input::-moz-placeholder {
  color: var(--white);
}

.rlr-header-search .rlr-banner-input-group__input:-ms-input-placeholder {
  color: var(--white);
}

.rlr-header-search .rlr-banner-input-group__input::-ms-input-placeholder {
  color: var(--white);
}

.rlr-header-search .rlr-banner-input-group__input::placeholder {
  color: var(--white);
}

.rlr-header-search.open {
  width: calc(100% - 20px);
}

@media (min-width: 1200px) {
  .rlr-header-search.open {
    width: calc(100% - 20px);
  }
}

.rlr-header-search.open:before {
  margin: 20px 0 20px 30px;
  position: absolute;
}

.rlr-header-search.open .form-control {
  border-bottom: 2px solid var(--body-color);
  border-radius: 0;
}

.rlr-header-search.open .rlr-banner-input-group__input {
  padding-left: 0.5rem;
}

.rlr-header-search.open .rlr-banner-input-group__input::-webkit-input-placeholder {
  color: var(--neutral-color);
  text-decoration: none;
}

.rlr-header-search.open .rlr-banner-input-group__input::-moz-placeholder {
  color: var(--neutral-color);
  text-decoration: none;
}

.rlr-header-search.open .rlr-banner-input-group__input:-ms-input-placeholder {
  color: var(--neutral-color);
  text-decoration: none;
}

.rlr-header-search.open .rlr-banner-input-group__input::-ms-input-placeholder {
  color: var(--neutral-color);
  text-decoration: none;
}

.rlr-header-search.open .rlr-banner-input-group__input::placeholder {
  color: var(--neutral-color);
  text-decoration: none;
}

.rlr-header-search__button {
  display: block;
}

@media (min-width: 992px) {
  .rlr-header-search__button {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
}

.rlr-header-search__icon {
  display: block;
  margin-left: 5px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.rlr-header-search__icon .rlr-icon-font {
  color: var(--search-icon-color);
  font-size: 1.5rem;
}

.open .rlr-header-search {
  margin: 0;
}

.rlr-header-search input[type="search" i]::-webkit-search-cancel-button {
  display: none;
}

/*!  ==============================================================
<!-- 5.58 Tooltip ==== -->
============================================================== **/
.is-hidden,
.rlr-fieldrow__item .rlr-js-tool-tip {
  display: none;
}

@media (min-width: 1200px) {
  .rlr-fieldrow__item:hover .rlr-js-tool-tip {
    display: block;
  }
}

.rlr-fieldrow__item .rlr-icon-font.flaticon-help {
  color: var(--neutral-color);
}

.tippy-box[data-theme~="light"] {
  background-color: var(--white);
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  color: var(--heading-color);
  letter-spacing: 0.0125rem;
  line-height: 1.4;
  padding: var(--spacing-5);
}

.tippy-box[data-theme~="light"] .type-lead-semibold {
  color: var(--brand);
}

.tippy-box[data-theme~="light"][data-placement^="top"]>.tippy-arrow:before {
  border-top-color: var(--white);
}

.tippy-box[data-theme~="light"][data-placement^="bottom"]>.tippy-arrow:before {
  border-bottom-color: var(--white);
}

.tippy-box[data-theme~="light"][data-placement^="left"]>.tippy-arrow:before {
  border-left-color: var(--white);
}

.tippy-box[data-theme~="light"][data-placement^="right"]>.tippy-arrow:before {
  border-right-color: var(--white);
}

.tippy-box[data-theme~="light"]>.tippy-backdrop {
  background-color: var(--white);
}

.tippy-box[data-theme~="light"]>.tippy-svg-arrow {
  fill: var(--white);
}

/*!  ==============================================================
<!-- 5.59 Rev Slider ==== -->
============================================================== **/
.rev-slidebg img {
  border-radius: 2rem;
}

/*!  ==============================================================
<!-- 5.60 WooCommerce ==== -->
============================================================== **/
.rlr-order-dashboard-nav__link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  grid-gap: 0.8125rem;
  align-items: center;
  color: var(--gray-07);
  display: grid;
  grid-template-columns: 3rem auto;
  margin-bottom: var(--spacing-4);
  text-decoration: none;
}

.rlr-order-dashboard-nav__link-icon {
  font-size: 24px;
  height: 3rem;
  width: 3rem;
}

.rlr-order-dashboard-nav__link:hover {
  color: var(--brand-09);
}

.rlr-order-dashboard-nav__link:hover span {
  background-color: var(--brand-02);
  border-radius: 50%;
}

.rlr-order-dashboard-nav .active {
  color: var(--brand-09);
}

.rlr-order-dashboard-nav .active span {
  background-color: var(--brand-02);
  border-radius: 50%;
}

/*!  ==============================================================
<!-- 5.61 Data Table ==== -->
============================================================== **/
.rlr-data-table {
  border-radius: var(--spacing-6);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  list-style: none;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.rlr-data-table__title {
  color: var(--brand-09);
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: var(--spacing-8);
}

.rlr-data-table__header {
  border: none;
  padding: 0;
}

.rlr-data-table__header-row {
  background-color: var(--gray-01) !important;
  border-bottom: 0;
  color: var(--gray-05);
}

.rlr-data-table__header-row th {
  padding: var(--spacing-8) var(--spacing-9);
}

@media (min-width: 992px) {
  .rlr-data-table__header-row th {
    padding: var(--spacing-6) var(--spacing-7);
  }
}

@media (max-width: 767.98px) {
  .rlr-data-table__header-row th {
    padding: var(--spacing-3) var(--spacing-6);
  }

  .rlr-data-table__header {
    display: none;
  }
}

.rlr-data-table tbody {
  border-bottom: 1px solid var(--gray-03);
}

@media (max-width: 767.98px) {
  .rlr-data-table__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: end;
  }
}

.rlr-data-table__row td {
  padding: var(--spacing-8) var(--spacing-9);
}

@media (min-width: 992px) {
  .rlr-data-table__row td {
    padding: var(--spacing-6) var(--spacing-7);
  }
}

@media (max-width: 767.98px) {
  .rlr-data-table__row td {
    padding: var(--spacing-3) var(--spacing-6);
  }
}

.rlr-data-table__row:not(:last-child) {
  border-bottom: 1px solid var(--gray-03);
}

.rlr-data-table__row a,
.rlr-data-table__row p {
  letter-spacing: 0.5px;
  margin: 0;
}

.rlr-data-table__cell {
  color: var(--gray-07);
}

@media (max-width: 767.98px) {
  .rlr-data-table__cell:before {
    color: var(--gray-05);
    content: attr(data-title) ":";
    float: left;
    font-weight: 700;
  }
}

.rlr-data-table__cell a {
  color: inherit;
  text-decoration: none;
}

.rlr-data-table__cell .num-tems {
  color: var(--gray-05);
}

.rlr-data-table__cell-totlal {
  color: var(--gray-05);
  font-weight: 600;
}

.rlr-data-table__cell .view {
  color: var(--brand);
  text-decoration: none;
}

.rlr-data-table__cell .view:hover {
  color: var(--brand);
}

.rlr-data-table__amount {
  font-weight: 600;
}

/*!  ==============================================================
<!-- 5.62 Sidebar Menu ==== -->
============================================================== **/
.rlr-sidebar-menu__sub-menu {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../assets/svg/icon-down-arrow.svg) 100% no-repeat;
  border: 1px solid var(--gray-03);
  color: var(--body-color);
  -o-text-overflow: "";
  text-overflow: "";
  width: 100%;
}

.rlr-sidebar-menu__link {
  grid-gap: 0.8125rem;
  color: var(--gray-07);
  display: grid;
  grid-template-columns: 3rem auto;
  margin-bottom: var(--spacing-4);
  text-decoration: none;
}

.rlr-sidebar-menu__link,
.rlr-sidebar-menu__link-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rlr-sidebar-menu__link-icon {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  height: 3rem;
  justify-content: center;
  line-height: 0;
  width: 3rem;
}

.rlr-sidebar-menu__link:hover {
  color: var(--brand-09);
}

.rlr-sidebar-menu__link:hover span {
  background-color: var(--brand-02);
  border-radius: 50%;
}

.rlr-sidebar-menu .active {
  color: var(--brand-09);
}

.rlr-sidebar-menu .active span {
  background-color: var(--brand-02);
  border-radius: 50%;
}

.rlr-sidebar-menu__wrapper {
  max-width: 295px;
}

@media (max-width: 991.98px) {
  .rlr-sidebar-menu__wrapper {
    margin-bottom: 0;
    max-width: 992px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .rlr-sidebar-menu__mobile {
    display: none;
  }
}

.rlr-sidebar-menu__desktop {
  list-style: none;
}

@media (max-width: 991.98px) {
  .rlr-sidebar-menu__desktop {
    display: none;
  }
}

/*!  ==============================================================
<!-- 5.63 Lightbox ==== -->
============================================================== **/
.rlr-lightbox--gallery__wrapper {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-gap: var(--spacing-7);
  -moz-column-gap: var(--spacing-7);
  column-gap: var(--spacing-7);
}

@media (min-width: 768px) {
  .rlr-lightbox--gallery__wrapper {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 1400px) {
  .rlr-lightbox--gallery__wrapper {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

.rlr-lightbox--gallery__img {
  display: block;
  max-width: 100%;
}

.rlr-lightbox--gallery__figure {
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
  display: grid;
  grid-template-rows: 1fr auto;
  margin: 0;
  margin-bottom: var(--spacing-7);
}

.rlr-lightbox--gallery__figure span {
  color: var(--white);
  text-decoration: none;
}

.rlr-lightbox--gallery__figure>img {
  border-radius: var(--spacing-3);
  grid-column: 1;
  grid-row: 1/-1;
}

.rlr-lightbox--gallery__figure--animated {
  position: relative;
}

.rlr-lightbox--gallery__figure--animated:after,
.rlr-lightbox--gallery__figure--animated:before {
  border: 2px solid var(--heading-color);
  content: "";
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  width: 100%;
  z-index: -1;
}

.rlr-lightbox--gallery__figure--animated:before {
  background-color: var(--heading-color);
  left: -15px;
  top: -15px;
}

.rlr-lightbox--gallery__figure--animated:after {
  bottom: -15px;
  right: -15px;
}

.rlr-lightbox--gallery__figure--animated:hover:before {
  left: 15px;
  top: 15px;
}

.rlr-lightbox--gallery__figure--animated:hover:after {
  bottom: 15px;
  right: 15px;
}

.rlr-lightbox--gallery__figcaption {
  background-color: rgba(0, 0, 0, 0.418);
  border-radius: 0 0 0 var(--spacing-3);
  grid-column: 1;
  grid-row: 2;
  justify-self: start;
  padding: 0.2em 0.5em;
}

.rlr-lightbox--gallery__landscape {
  grid-column-end: span 2;
}

.rlr-lightbox--gallery iframe {
  border: 0;
  display: none !important;
}

/*!  ==============================================================
<!-- 5.64 Dynamic Filter ==== -->
============================================================== **/
.rlr-js-dynamic-filter-swiper .swiper-slide {
  width: auto;
}

@media (min-width: 992px) {

  .rlr-js-dynamic-filter-swiper:hover .splide__arrow--next,
  .rlr-js-dynamic-filter-swiper:hover .splide__arrow--prev {
    background-color: var(--gray-05);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .rlr-js-dynamic-filter-swiper:hover .splide__arrow--next i,
  .rlr-js-dynamic-filter-swiper:hover .splide__arrow--prev i {
    color: var(--white);
  }
}

.rlr-js-dynamic-filter-swiper .splide__arrow--next,
.rlr-js-dynamic-filter-swiper .splide__arrow--prev {
  border-radius: 2rem;
}

.rlr-js-dynamic-filter-swiper .splide__arrow--next {
  right: 0;
}

.rlr-js-dynamic-filter-swiper .splide__arrow--prev {
  left: 0;
}

/*!  ==============================================================
<!-- 6. Layouts ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 6.1 Masonary Grid ==== -->
============================================================== **/
/*!  ==============================================================
<!-- Masonary Grid ==== -->
============================================================== **/
.rlr-masonary-grid__container {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  display: grid;
  grid-template-areas: "one" "two" "three" "four" "five" "six";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

@media (min-width: 768px) {
  .rlr-masonary-grid__container {
    grid-template-areas: "one one three three three" "two two three three three" "four four four four four" "five five six six six";
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .rlr-masonary-grid__container {
    grid-template-areas: "one one three three three three" "two two three three three three" "four four four four four four" "five five six six six six";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .rlr-masonary-grid__container {
    grid-template-areas: "one one one three three three three four four four four four" "two two two three three three three five five six six six";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.rlr-masonary-grid__one {
  grid-area: one;
}

.rlr-masonary-grid__two {
  grid-area: two;
}

.rlr-masonary-grid__three {
  grid-area: three;
}

.rlr-masonary-grid__four {
  grid-area: four;
}

.rlr-masonary-grid__five {
  grid-area: five;
}

.rlr-masonary-grid__six {
  grid-area: six;
}

/*!  ==============================================================
<!-- 6.2 Product Form ==== -->
============================================================== **/
.rlr-product-form .rlr-fieldrow__clone--expand {
  height: 100%;
}

.rlr-product-form hr {
  background-color: var(--neutral-color);
  margin-bottom: var(--spacing-9);
  margin-top: var(--spacing-14);
}

.rlr-product-form--hide {
  display: none;
}

.rlr-product-form--show {
  display: block;
}

.rlr-product-form .rlr-error {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--error-01);
  background-image: none;
  border-radius: var(--spacing-3);
  color: var(--danger-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: var(--spacing-5);
  padding: var(--spacing-2) var(--spacing-5);
  padding-left: var(--spacing-5);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.rlr-date-selection__text {
  margin-bottom: var(--spacing-3);
}

@media (min-width: 768px) {
  .rlr-photo-uploader--inline {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-column-gap: var(--spacing-15);
    -moz-column-gap: var(--spacing-15);
    column-gap: var(--spacing-15);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }
}

.rlr-input-groups .rlr-input-wrapper:not(.rlr-custom-input--numberfield) {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

@media (min-width: 576px) {
  .rlr-input-groups .rlr-input-wrapper:not(.rlr-custom-input--numberfield) {
    -webkit-box-flex: 2;
    -ms-flex: 2 0 calc(50% - var(--spacing-8));
    flex: 2 0 calc(50% - var(--spacing-8));
  }
}

.rlr-custom-inputs {
  gap: 0 var(--spacing-8);
}

@media (min-width: 576px) {
  .rlr-custom-inputs .rlr-input-group:not(.rlr-custom-input--numberfield) {
    -webkit-box-flex: 2;
    -ms-flex: 2 0 calc(50% - var(--spacing-8));
    flex: 2 0 calc(50% - var(--spacing-8));
  }
}

.rlr-custom-inputs .rlr-input-group:not(.rlr-custom-input--numberfield) span {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: var(--brand);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  top: var(--spacing-4);
  width: 1.5rem;
}

.rlr-custom-inputs .rlr-custom-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.rlr-custom-inputs .rlr-custom-input--hidden {
  display: none;
}

@media (max-width: 575.98px) {
  .rlr-custom-inputs .rlr-custom-input--option {
    margin-bottom: var(--spacing-8);
  }
}

@media (min-width: 576px) {
  .rlr-custom-inputs .rlr-custom-input {
    -webkit-box-flex: 2;
    -ms-flex: 2 0 calc(50% - var(--spacing-8));
    flex: 2 0 calc(50% - var(--spacing-8));
  }
}

.rlr-js-input-groups__hide {
  display: none;
}

/*!  ==============================================================
<!-- 6.3 Progress steps ==== -->
============================================================== **/
.rlr-progress__steps {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 0;
  position: relative;
}

.rlr-progress__steps:after {
  background: var(--gray-02);
  content: "";
  height: calc(100% - var(--spacing-7) * 2 - var(--spacing-14));
  position: absolute;
  right: calc(var(--spacing-2) - 1px);
  top: var(--spacing-7);
  width: 2px;
  z-index: -1;
}

@media (max-width: 1199.98px) {
  .rlr-progress__steps:after {
    height: 2px;
    left: var(--spacing-7);
    top: calc(var(--spacing-2) - 1px);
    width: calc(100% - 7.75rem);
  }

  .rlr-progress__steps {
    -webkit-column-gap: var(--spacing-9);
    -moz-column-gap: var(--spacing-9);
    column-gap: var(--spacing-9);
  }
}

@media (max-width: 575.98px) {
  .rlr-progress__steps {
    -webkit-column-gap: var(--spacing-7);
    -moz-column-gap: var(--spacing-7);
    column-gap: var(--spacing-7);
  }
}

@media (max-width: 1199.98px) {
  .rlr-progress {
    overflow-x: auto;
  }
}

@media (min-width: 1200px) {
  .rlr-progress__steps {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rlr-progress .rlr-step {
    margin-bottom: var(--spacing-14);
  }
}

/*!  ==============================================================
<!-- 6.4 Product details ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 6.5 Overview details ==== -->
============================================================== **/
.rlr-overview-detail__description {
  margin-bottom: var(--spacing-11);
}

.rlr-overview-detail__icon-groupset {
  -webkit-box-flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

@media (min-width: 992px) {
  .rlr-overview-detail__icon-groupset {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.rlr-overview-detail__icons {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 14px;
}

.rlr-overview-detail__icons .rlr-icon-font {
  color: var(--brand);
  font-size: 14px;
}

.rlr-overview-detail__icon-group {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: var(--spacing-7);
  row-gap: 1.25rem;
  width: 50%;
}

@media (min-width: 768px) {
  .rlr-overview-detail__icon-group {
    width: 33.33333%;
  }
}

.rlr-overview-detail__label {
  color: var(--body-color);
  letter-spacing: 0.5px;
}

.rlr-overview-detail__set {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0;
  margin-right: var(--spacing-2);
  padding: var(--spacing-2);
  width: 2rem;
}

.rlr-overview-detail__icon-name {
  color: var(--heading-color);
}

/*!  ==============================================================
<!-- 6.6 Secondary menu description ==== -->
============================================================== **/
.rlr-secondary-menu-desc {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: var(--spacing-5);
  margin-top: var(--spacing-5);
}

@media (min-width: 992px) {
  .rlr-secondary-menu-desc {
    gap: var(--spacing-8);
    margin-top: var(--spacing-12);
  }
}

.rlr-secondary-menu-desc__icon {
  padding: var(--spacing-5);
}

.rlr-secondary-menu-desc__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  border-bottom: 1px solid var(--gray-03);
  flex-grow: 1;
  padding-bottom: var(--spacing-5);
}

.rlr-secondary-menu-desc__show-more {
  color: var(--neutral-color);
  cursor: pointer;
  display: block;
  letter-spacing: 1px;
  line-height: 1.75;
}

.rlr-secondary-menu-desc__list-group {
  margin-top: var(--spacing-11);
}

.rlr-secondary-menu-desc__list:not(:last-child) {
  margin-bottom: var(--spacing-7);
}

.rlr-secondary-menu-desc__footer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-7);
}

.rlr-secondary-menu-desc__review-link {
  color: var(--neutral-color);
  cursor: pointer;
  letter-spacing: 1px;
  line-height: 1.75;
  text-decoration: none;
}

.rlr-secondary-menu-desc .rlr-icon-text span,
.rlr-secondary-menu-desc__review-link:hover {
  color: var(--heading-color);
}

.rlr-secondary-menu-desc .rlr-icon-text .rlr-icon-font.flaticon-check-rounded {
  color: var(--brand);
}

.rlr-secondary-menu-desc .rlr-icon-text .rlr-icon-font.flaticon-cross-rounded {
  color: var(--danger-color);
}

@media (min-width: 768px) {
  .rlr-secondary-menu-desc {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

/*!  ==============================================================
<!-- 6.7 Product detail header ==== -->
============================================================== **/
.rlr-product-detail-header {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-01);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: var(--spacing-5);
  position: relative;
}

@media (min-width: 576px) {
  .rlr-product-detail-header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.rlr-product-detail-header__actions {
  -webkit-column-gap: var(--spacing-3);
  -moz-column-gap: var(--spacing-3);
  column-gap: var(--spacing-3);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.rlr-product-detail-header__actions .rlr-icon-font {
  font-size: 1.5rem;
}

.rlr-product-detail-header__actions .rlr-popover-button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  color: var(--neutral-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.rlr-product-detail-header__actions .rlr-popover-button:hover {
  color: var(--brand);
}

.rlr-product-detail-header__title {
  margin-bottom: var(--spacing-5);
}

.rlr-product-detail-header__button-wrapper {
  position: relative;
}

.rlr-product-detail-header__helptext {
  bottom: 52px;
  display: inline;
  opacity: 0;
  padding: 0 0.5rem;
  position: absolute;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.rlr-product-detail-header__helptext:active,
.rlr-product-detail-header__helptext:hover {
  -webkit-box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
  box-shadow: 1px 2px 2px 1px hsla(0, 0%, 69%, 0.35);
}

.rlr-product-detail-header__helptext--featured {
  color: var(--white);
}

/*!  ==============================================================
<!-- 6.8 Itinerary details ==== -->
============================================================== **/
.rlr-itinerary__media-group {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: var(--spacing-8);
  margin-top: var(--spacing-11);
}

.rlr-itinerary__media,
.rlr-itinerary__media-group {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-itinerary__media {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 11rem;
  width: calc(33.33333% - var(--spacing-8));
}

.rlr-itinerary__media img {
  border-radius: var(--spacing-3);
}

.rlr-itinerary__media-title {
  color: var(--neutral-color);
  letter-spacing: 0.5px;
}

.rlr-itinerary__media-title--active {
  color: var(--brand);
}

.rlr-itinerary__title {
  color: var(--heading-color);
}

.rlr-itinerary__room-details,
.rlr-itinerary__tabbed-content {
  margin-top: var(--spacing-9);
}

.rlr-itinerary__room-type {
  color: var(--neutral-color);
  font-weight: 400;
  width: 30%;
}

.rlr-itinerary__room-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-itinerary__room-price span {
  letter-spacing: 0.5px;
}

/*!  ==============================================================
<!-- 6.9 Tabbed field ==== -->
============================================================== **/
.rlr-nav-tabs__items {
  border-bottom-color: var(--gray-03);
}

.rlr-nav-tabs__link.nav-link {
  color: var(--heading-color);
  font-weight: 500;
  padding-left: 0;
}

.rlr-nav-tabs__link.nav-link.active,
.rlr-nav-tabs__link.nav-link:hover {
  border-color: transparent;
  border-bottom-color: var(--gray-03);
  color: var(--neutral-color);
}

.rlr-tab-content {
  margin-top: var(--spacing-7);
}

/*!  ==============================================================
<!-- 6.10 Review Form ==== -->
============================================================== **/
.rlr-review-form__cancel,
.rlr-review-form__submit {
  font-weight: 400;
  margin-top: var(--spacing-14);
  padding: 0.875rem var(--spacing-10);
  text-align: center;
}

.rlr-review-form__cancel {
  border: 1px solid var(--gray-04);
  float: left;
}

.rlr-review-form__cancel,
.rlr-review-form__cancel:hover {
  color: var(--neutral-color);
}

.rlr-review-form__submit {
  color: var(--white);
  float: right;
}

.rlr-review-form__submit:hover {
  color: var(--white);
}

.rlr-review-form__hidden-legend {
  display: none;
}

/*!  ==============================================================
<!-- 6.11 Booking form ==== -->
============================================================== **/
.rlr-booking-card {
  background: var(--gray-00);
  border-radius: var(--spacing-3);
  padding: var(--spacing-5) var(--spacing-7) var(--spacing-9);
  position: sticky;
  top: 70px;
}

.rlr-booking-card__legend--hide {
  display: none;
}

.rlr-booking-card .rlr-badge {
  --badge-bg-color: var(--brand-bg);
  --badge-color: var(--brand);
}

.rlr-booking-card__offer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: var(--spacing-2);
}

.rlr-booking-card__offer,
.rlr-booking-card__price {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-booking-card__price {
  margin-bottom: 0;
}

.rlr-booking-card__current-price {
  color: var(--heading-color);
  line-height: 3rem;
}

.rlr-booking-card--low-price {
  margin-right: var(--spacing-2);
}

.rlr-booking-card__total-price {
  color: var(--heading-color);
  line-height: 1.29;
}

.rlr-booking-card__del-price {
  color: var(--error-07);
}

.rlr-booking-card__info {
  color: var(--neutral-color);
  font-weight: 300;
}

.rlr-booking-card__price-type {
  color: var(--body-color);
}

.rlr-booking-card__price-type--result {
  color: var(--brand);
}

.rlr-booking-card__form-item.rlr-fieldrow__item {
  margin-top: var(--spacing-8);
}

.rlr-booking-card .rlr-fieldrow {
  margin-top: 0;
}

.rlr-booking-card .form-input {
  background-color: var(--gray-00);
  border: 2px solid var(--gray-04);
  font-weight: 400;
  letter-spacing: 0.5px;
}

.rlr-booking-card .form-input::-webkit-input-placeholder {
  color: var(--neutral-color);
}

.rlr-booking-card .form-input::-moz-placeholder {
  color: var(--neutral-color);
}

.rlr-booking-card .form-input:-ms-input-placeholder {
  color: var(--neutral-color);
}

.rlr-booking-card .form-input::-ms-input-placeholder {
  color: var(--neutral-color);
}

.rlr-booking-card .form-input::placeholder {
  color: var(--neutral-color);
}

.rlr-booking-card input[type="button"] {
  text-align: left;
}

.rlr-booking-card .rlr-input-group__iconset .form-control,
.rlr-booking-card .rlr-input-group__iconset .form-input {
  background-color: transparent;
  border: 0;
}

.rlr-booking-card__label {
  color: var(--body-color);
  letter-spacing: 0.5px;
}

.rlr-booking-card__counter {
  color: var(--heading-color);
  margin: 0 var(--spacing-3);
  vertical-align: middle;
}

.rlr-booking-card__results {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--spacing-10);
}

.rlr-booking-card__results--found {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rlr-booking-card__results--checking.ph-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  background: var(--gray-00);
  border: none;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0;
}

.rlr-booking-card__results--checking.ph-item .rlr-booking-card__ph-12 {
  margin: 0;
  padding: 0;
}

.rlr-booking-card__results--checking.ph-item .rlr-booking-card__ph-12 .rlr-booking-card__placeholder-row--item {
  background-color: var(--gray-03);
}

.rlr-booking-card__result-title {
  color: var(--heading-color);
  line-height: 1.5;
}

.rlr-booking-card__result-desc {
  color: var(--body-color);
  font-size: 0.875rem;
  margin-top: var(--spacing-2);
  text-align: center;
}

.rlr-booking-card__result-list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  row-gap: var(--spacing-5);
  width: 100%;
}

.rlr-booking-card__result-list .rlr-icon-font {
  color: var(--heading-color);
}

.rlr-booking-card__result-list .rlr-icon-text {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}

.rlr-booking-card__result-list .rlr-icon-text__text-wrapper {
  width: 100%;
}

.rlr-booking-card__result-list .rlr-icon-text__text-wrapper a {
  color: var(--heading-color);
  float: right;
  text-decoration: none;
}

.rlr-booking-card__float-right {
  float: right;
}

.rlr-booking-card__message {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rlr-booking-card__message .rlr-icon-text__title {
  color: var(--heading-color);
  line-height: 1.5;
}

.rlr-booking-card__message .rlr-icon-text__subtext {
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.5;
}

.rlr-booking-card__button.rlr-button {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(9%, var(--brand)), color-stop(102%, #12b569));
  background-image: -o-linear-gradient(left, var(--brand) 9%, #12b569 102%);
  background-image: linear-gradient(to right, var(--brand) 9%, #12b569 102%);
  border-radius: var(--spacing-3);
  color: var(--white);
  display: block;
  margin-top: var(--spacing-8);
  padding: var(--spacing-4) 0;
  text-align: center;
  width: 100%;
}

.rlr-booking-card__button.rlr-button:hover {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(9%, var(--brand-09)), color-stop(102%, #12b569));
  background-image: -o-linear-gradient(left, var(--brand-09) 9%, #12b569 102%);
  background-image: linear-gradient(to right, var(--brand-09) 9%, #12b569 102%);
}

.rlr-booking-card.ph-item {
  background: var(--gray-00);
  margin-bottom: 0;
  padding: var(--spacing-5) var(--spacing-7) var(--spacing-9);
}

.rlr-booking-card.ph-item .ph-col-12 {
  margin: 0;
  padding: 0;
}

.rlr-booking-card.ph-item .rlr-booking-card__placeholder-row {
  margin-top: 0;
}

.rlr-booking-card.ph-item .rlr-booking-card__placeholder-row:not(:last-child) {
  margin-bottom: 4.625rem;
}

.rlr-booking-card.ph-item .rlr-booking-card__placeholder-row:nth-last-child(2) {
  margin-bottom: var(--spacing-8);
}

.rlr-booking-card.ph-item .rlr-booking-card__placeholder-row--item {
  background-color: var(--gray-03);
  margin-top: 7.5px;
  padding: 0;
}

.rlr-booking-card.ph-item .rlr-booking-card__placeholder-row--item:first-child {
  margin-top: 0;
}

.rlr-booking-card.ph-item .rlr-booking-card__placeholder-row--item.rlr-booking-card__placeholder-row--button {
  border-radius: var(--spacing-3);
  height: 3.25rem;
}

.rlr-booking-card.ph-item .rlr-booking-card__placeholder-row--item.ph-avatar {
  height: 3.5rem;
  margin-top: 0;
  width: 3.5rem;
}

.rlr-booking-card__empty {
  padding: 0;
}

.rlr-booking-card__small {
  height: 1.375rem;
}

.rlr-booking-card__big {
  height: 0.562rem;
}

/*!  ==============================================================
<!-- 6.12 Product filter ==== -->
============================================================== **/
.rlr-product-filters__filters-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-color: var(--white);
  border: 1px solid var(--gray-03);
  border-radius: var(--spacing-5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: var(--spacing-8) var(--spacing-10);
  row-gap: var(--spacing-9);
}

.rlr-product-filters__label {
  color: var(--body-color);
}

.rlr-product-filters__checkbox-label {
  color: var(--heading-color);
  letter-spacing: 0.5px;
}

.rlr-product-filters .form-input::-webkit-input-placeholder {
  color: var(--neutral-color);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.rlr-product-filters .form-input::-moz-placeholder {
  color: var(--neutral-color);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.rlr-product-filters .form-input:-ms-input-placeholder {
  color: var(--neutral-color);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.rlr-product-filters .form-input::-ms-input-placeholder {
  color: var(--neutral-color);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.rlr-product-filters .form-input::placeholder {
  color: var(--neutral-color);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.rlr-product-filters__checkbox.form-check-input {
  border: 2px solid var(--gray-04);
  border-radius: 8px;
  height: 1.5rem;
  width: 1.5rem;
}

.rlr-product-filters__checkbox.form-check-input:checked {
  border-color: var(--brand);
}

.rlr-product-filters__checkbox.form-check-input+label {
  padding: var(--spacing-2) 0 0 var(--spacing-5);
}

.rlr-product-filters__hidden {
  display: none;
}

.rlr-product-filters__range-slider {
  margin-top: var(--spacing-8);
}

/*!  ==============================================================
<!-- 6.13 Banner search ==== -->
============================================================== **/
.rlr-banner-search {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  background-color: var(--white);
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.07);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-8) var(--spacing-5);
  position: relative;
  row-gap: var(--spacing-12);
}

@media (min-width: 1200px) {
  .rlr-banner-search {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: var(--spacing-8) var(--spacing-13);

  }
}

.rlr-banner-search__input-wrapper {
  -webkit-box-flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  row-gap: var(--spacing-12);
}

@media (min-width: 768px) {
  .rlr-banner-search__input-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.rlr-banner-search__submit-button {
  background: var(--brand);
  border: none;
  border-radius: var(--spacing-5);
  height: var(--spacing-16);
}

@media (min-width: 1200px) {
  .rlr-banner-search__submit-button {
    width: var(--spacing-16);
  }
}

.rlr-banner-search__submit-button .rlr-icon-font {
  color: var(--white);
  display: grid;
  font-size: 2rem;
  place-items: center;
}

@media (min-width: 992px) {
  .rlr-banner-search__banner-input:not(:last-child) {
    margin-right: var(--spacing-8);
  }

  .rlr-banner-search__banner-input:not(:last-child):after {
    background-color: var(--gray-04);
    content: "";
    height: 3.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0.063rem;
  }
}

.rlr-banner-search--hero-half-mast {
  margin-bottom: var(--spacing-18);
  z-index: 2;
}

@media (min-width: 1200px) {
  .rlr-banner-search--hero-half-mast {
    bottom: var(--spacing-15);
    left: 5%;
    margin-bottom: var(--spacing-10);
    width: 90%;
  }
}

@media (min-width: 1400px) {
  .rlr-banner-search--hero-half-mast {
    left: 10%;
    width: 80%;
  }
}

.rlr-banner-search--hero-full-screen {
  z-index: 2;
}

@media (min-width: 1200px) {
  .rlr-banner-search--hero-full-screen {
    bottom: 28.57143vh;
    left: -3%;
    margin: 0 auto;
    width: 80%;
  }

  .rlr-banner-search--hero-full-screen--v2 {
    bottom: 40vh;
    left: 0;
  }

  .rlr-banner-search--rounded {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 15rem;
    padding: var(--spacing-5) var(--spacing-10);
  }

  .rlr-banner-search--rounded .rlr-banner-search__submit-button {
    border-radius: var(--spacing-15);
    height: var(--spacing-11);
    width: var(--spacing-11);
  }

  .rlr-banner-search--rounded .rlr-banner-search__submit-button .rlr-icon-font {
    font-size: 1.5rem;
  }
}

.rlr-banner-search--rounded .rlr-banner-input-group--activity-dropdown,
.rlr-banner-search--rounded .rlr-banner-input-group--location-dropdown {
  top: 83px;
}

.rlr-banner-search--rounded .rlr-banner-input-group__label mark {
  font-size: 1.25rem;
}

.rlr-banner-search--rounded .rlr-banner-input-group__input-wrapper {
  margin-top: var(--spacing-2);
}

/*!  ==============================================================
<!-- 6.14 Header layout ==== -->
============================================================== **/
.rlr-header-layout__navigation--fullwidth {
  left: 50%;
  position: fixed;
  top: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;
}

.rlr-header-layout__navigation--fullwidth .navigation {
  background: transparent;
}

.rlr-header-layout__navigation--fullwidth .rlr-navigation__link {
  color: var(--white);
}

.rlr-header-layout__navigation--fullwidth .rlr-navigation__link svg {
  fill: var(--white);
}

.rlr-header-layout__navigation--fullwidth .rlr-navigation__button-login {
  color: var(--white);
}

.rlr-header-layout--fullwidth {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1400px) {
  .rlr-header-layout--fullwidth .rlr-header-layout__search-layout {
    bottom: 12rem;
    position: absolute;
  }
}

/*!  ==============================================================
<!-- 6.15 Account ==== -->
============================================================== **/
.rlr-authforms {
  font-size: 14px;
}

.rlr-authforms__header {
  padding: 20px;
  text-align: center;
}

.rlr-authforms__header h3 {
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.rlr-authforms__header p {
  color: #667085;
  margin: 0;
}

.rlr-authforms__header p a {
  color: #323d54;
  display: block;
  text-decoration: none;
}

.rlr-authforms__header img {
  height: var(--spacing-15);
  margin-bottom: var(--spacing-8);
}

.rlr-authforms__inputgroup {
  margin-bottom: 20px;
}

.rlr-authforms__forgotpassword {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  margin: 20px 0;
}

.rlr-authforms__forgotpassword a {
  color: #22968e;
  text-decoration: none;
}

.rlr-authforms__back {
  padding: 10px 0;
  text-align: center;
}

.rlr-authforms__back a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  color: #667085;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.rlr-authforms__back a img {
  margin-right: 7px;
}

.rlr-authforms .rlr-button {
  margin-bottom: var(--spacing-5);
}

.rlr-authforms__notes {
  color: #667085;
  font-size: 14px;
  text-align: center;
}

.rlr-authforms__notes a {
  color: #22968e;
  text-decoration: none;
}

.rlr-form-label--font-inherit {
  font-size: inherit;
}

.rlr-form-label--bold {
  font-weight: 500;
}

.rlr-form-input--shadow {
  -webkit-box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.help-text {
  color: #667085;
  font-size: 14px;
  margin-top: 2px;
}

/*!  ==============================================================
<!-- 6.16 Footer ==== -->
============================================================== **/
.rlr-footer {
  padding-bottom: var(--spacing-3);
}

.rlr-footer .separate {
  display: inline-block;
  margin: 0 5px;
}

.rlr-footer a {
  color: var(--heading-color);
  text-decoration: none;
  font-size: 14px;
}

.rlr-footer a:hover {
  color: var(--brand);
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

.rlr-footer__quicklinks {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  border-bottom: 1px solid var(--gray-03);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  padding: 0 0 var(--spacing-9);
}

@media (max-width: 991px) {
  .rlr-footer__quicklinks {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 20px 0;
    text-align: center;
  }
}

.rlr-footer__quicklinks--title {
  background: url(../assets/images/icon-plus.png) no-repeat 0 0;
  color: var(--heading-color);
  display: inline-block;
  min-height: 34px;
  padding: 0 0 0 2.5rem;
}

@media (max-width: 991px) {
  .rlr-footer__quicklinks--title {
    display: inline-block;
  }
}

.rlr-footer__quicklinks--expore {
  color: #546179;
}

@media (max-width: 991px) {
  .rlr-footer__quicklinks--expore {
    display: block;
    margin: 10px 0;
  }
}

.rlr-footer__getintouch {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  border-bottom: 1px solid var(--gray-03);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-9) 0;
}

@media (max-width: 991px) {
  .rlr-footer__getintouch {
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  .rlr-footer__getintouch {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.rlr-footer__getintouch__col--title h4 {
  color: var(--brand);
}

.rlr-footer__getintouch__col--title p {
  margin: 0;
}

.rlr-footer__getintouch__col--address {
  background: url(../assets/images/icon-globe.png) no-repeat 0 0;
  padding: 0 0 0 110px;
}

@media (min-width: 768px) {
  .rlr-footer__getintouch__col--address {
    width: 272px;
  }
}

@media (max-width: 768px) {
  .rlr-footer__getintouch__col--address {
    background-size: 60px;
    margin-top: 20px;
    padding-left: 75px;
    text-align: left;
  }
}

.rlr-footer__getintouch__col--address h4 {
  font-weight: 400;
}

@media (max-width: 768px) {
  .rlr-footer__getintouch__col--address h4 {
    margin: 0;
  }
}

.rlr-footer__getintouch__col--address a {
  background: url(../assets/images/ele-arrow-right.png) no-repeat 0 bottom;
  display: inline-block;
  font-weight: 500;
  padding-bottom: var(--spacing-9);
}

@media (max-width: 768px) {
  .rlr-footer__getintouch__col--address a {
    background-size: 20px auto;
    padding-bottom: 20px;
  }
}

.rlr-footer__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: var(--spacing-9) 0;
}

@media (max-width: 991px) {
  .rlr-footer__menu {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 0;
  }
}

@media (max-width: 575px) {
  .rlr-footer__menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rlr-footer__menu__col {
  width: 20%;
}

@media (max-width: 991px) {
  .rlr-footer__menu__col {
    width: 33%;
  }
}

@media (max-width: 575px) {
  .rlr-footer__menu__col {
    margin-bottom: 20px;
    width: 100%;
  }
}

.rlr-footer__menu__col h4 {
  color: var(--heading-color);
}

.rlr-footer__menu__col ul {
  margin: 0;
  padding: 0;
}

@media (max-width: 575px) {
  .rlr-footer__menu__col ul {
    display: block;
  }
}

.rlr-footer__menu__col ul li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0;
}

@media (max-width: 575px) {
  .rlr-footer__menu__col ul li {
    display: inline-block;
  }
}

.rlr-footer__menu__col ul li:last-child a:after {
  display: none;
}

.rlr-footer__menu__col ul a {
  color: var(--body-color);
}

@media (max-width: 575px) {
  .rlr-footer__menu__col ul a {
    display: inline-block;
    padding: 0 10px 0 0;
  }

  .rlr-footer__menu__col ul a:after {
    background-color: #ccc;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 4px;
    left: 6px;
    position: relative;
    vertical-align: middle;
    width: 4px;
  }
}

.rlr-footer__menu__col ul a:hover {
  color: var(--brand);
}

.rlr-footer__menu__col--lg {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 991px) {
  .rlr-footer__menu__col--lg {
    margin: 50px 0 0;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .rlr-footer__menu__col--lg {
    margin-top: 0;
  }
}

.rlr-footer__menu__col--lg .rlr-footer__menu__col__letstalk {
  background: url(../assets/images/ele-arrow-right.png) no-repeat right 50%;
  display: inline-block;
  padding: 10px 50px 10px 0;
}

@media (max-width: 991px) {
  .rlr-footer__menu__col--lg .rlr-footer__menu__col__letstalk {
    background-size: 20px auto;
    padding: 2px 30px 10px 0;
  }
}

.rlr-footer__menu__col__subscribe {
  border-radius: 16px;
  -webkit-box-shadow: 0 32px 84px rgba(14, 86, 124, 0.16);
  box-shadow: 0 32px 84px rgba(14, 86, 124, 0.16);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

@media (max-width: 991px) {
  .rlr-footer__menu__col__subscribe {
    border-radius: 10px;
  }
}

.rlr-footer__menu__col__subscribe input.input {
  -webkit-box-flex: 1;
  background: url(../assets/images/icon-email.png) no-repeat 20px 50%;
  border: 0 !important;
  display: block;
  -ms-flex: 1;
  flex: 1;
  height: 80px;
  outline: 0;
  padding: 0 0 0 60px;
}

@media (max-width: 991px) {
  .rlr-footer__menu__col__subscribe input.input {
    height: 50px;
  }
}

.rlr-footer__menu__col__subscribe .btn {
  background: var(--brand);
  border: 0;
  border-radius: 0;
  color: var(--white);
  height: 80px;
  width: 150px;
}

@media (max-width: 991px) {
  .rlr-footer__menu__col__subscribe .btn {
    height: 50px;
  }
}

.rlr-footer__legal {
  padding: var(--spacing-9) 0;
  padding-bottom: 20px;
}

.rlr-footer__legal--inline {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

@media (min-width: 768px) {
  .rlr-footer__legal--inline {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.rlr-footer__legal--inline li {
  list-style: none;
}

.rlr-footer__legal--inline li a {
  color: var(--gray-05);
}

@media (max-width: 575px) {
  .rlr-footer__legal {
    padding: 0;
    padding-bottom: 20px;
  }
}

.rlr-footer__legal__row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .rlr-footer__legal__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .rlr-footer__legal__row .rlr-footer__legal__row__col {
    padding: 3px;
  }

  .rlr-footer__legal__row .rlr-footer__legal__row__col img {
    max-width: 200px;
  }
}

.rlr-footer__legal__row--top {
  border-bottom: 1px solid var(--gray-03);
  font-weight: 400;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.rlr-footer__legal--v2 {
  bottom: 28px;
  position: absolute;
}

.rlr-footer__legal--v2 .rlr-footer__legal__row {
  padding: 2rem 0;
}

.rlr-footer__legal--v2 .rlr-footer__legal__row__col span {
  color: var(--gray-05);
}

.rlr-footer--sticky-bottom {
  bottom: 0;
  position: absolute;
  width: 100%;
}

/*!  ==============================================================
<!-- 6.17 Blog Single ==== -->
============================================================== **/
.rlr-article {
  padding-top: var(--spacing-10);
}

.rlr-article a,
.rlr-article button {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.rlr-article__header {
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  width: 960px;
}

.rlr-article__header__timestamp {
  color: var(--brand-07);
  margin-bottom: var(--spacing-4);
}

.rlr-article__header h1 {
  color: var(--gray-10);
  margin-bottom: var(--spacing-7);
}

.rlr-article__header h6 {
  color: var(--gray-06);
}

.rlr-article__featured-photo {
  padding: var(--spacing-10) 0;
  text-align: center;
}

@media (max-width: 575px) {
  .rlr-article__featured-photo {
    padding: 15px 0;
  }
}

.rlr-article__featured-photo img {
  border-radius: var(--spacing-8);
  width: 100%;
}

.rlr-article__wrapper {
  border-bottom: 1px solid var(--gray-02);
  margin: auto;
  max-width: 720px;
  padding-bottom: var(--spacing-12);
  padding-top: var(--spacing-5);
}

.rlr-article__wrapper h2,
.rlr-article__wrapper h5 {
  color: var(--gray-10);
}

.rlr-article__author {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  border-top: 1px solid #e9ecef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 25px 0;
}

@media (max-width: 575px) {
  .rlr-article__author {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rlr-article__author__about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 575px) {
  .rlr-article__author__about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
}

.rlr-article__author__about img {
  margin-right: 15px;
}

@media (max-width: 575px) {
  .rlr-article__author__about img {
    margin: 0;
  }
}

.rlr-article__author__about .rlr-article__author__name {
  display: block;
}

.rlr-article__author__about .rlr-article__author__designation {
  color: var(--body-color);
  display: block;
}

.share-items {
  padding: 10px 50px;
}
.share_btn{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px !important;
  color: #000 !important;
}
.rlr-article__author .rlr-share__items {
  gap: var(--spacing-1);
}

.rlr-article__author__share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.rlr-article__author__share .rlr-button {
  background-color: var(--off-white);
  border: 0;
  height: 50px;
  width: 50px;
}

@media (max-width: 575px) {
  .rlr-article__author__share .rlr-button {
    background-size: 28px auto;
    height: 30px;
    margin-top: 10px;
    width: 30px;
  }
}

.rlr-article__author__share .rlr-button:hover {
  opacity: 0.9;
}

.rlr-article__author__share svg {
  fill: var(--white);
  stroke: var(--white);
}

.rlr-article__author__share__fav {
  border: 0;
  height: 50px;
  width: 30px;
}

@media (max-width: 575px) {
  .rlr-article__author__share__fav {
    background-size: 28px auto;
    height: 30px;
    margin-top: 10px;
    width: 30px;
  }
}

.rlr-article__author__share__fav:hover {
  opacity: 0.9;
}

.rlr-article .rlr-comments {
  margin: 40px 0;
}

.rlr-article .rlr-comments__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}

.rlr-article .rlr-comments__single__photo {
  margin-right: 12px;
}

.rlr-article .rlr-comments__single__photo img {
  border-radius: 50%;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 40px;
}

.rlr-article .rlr-comments__single__details {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.rlr-article .rlr-comments__single__details .rlr-comments__single__nrow {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  color: var(--body-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.rlr-article .rlr-comments__single__comment {
  background: #fbfbfb;
  border-radius: 0 8px 8px;
  -webkit-box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  line-height: 130%;
  padding: 10px 14px;
}

.rlr-article .rlr-comments__single__comment p:last-child {
  margin-bottom: 0;
}

.rlr-article .rlr-comments__single__likerow {
  padding: 6px 0;
  text-align: right;
}

.rlr-article .rlr-comments__single__likerow button {
  background-color: var(--brand-00);
  background-position: 10px 50%;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 16px;
  color: #344054;
  height: 26px;
  line-height: 26px;
  padding: 0 10px 0 30px;
}

.rlr-article .rlr-comments__single__likerow button:hover {
  background-color: var(--brand-01);
}

.rlr-article .rlr-comments__single__likerow button.like,
.rlr-article .rlr-comments__single__likerow button.love {
  color: var(--neutral-color);
  position: relative;
}

.rlr-article .rlr-comments__single__likerow button.like:active,
.rlr-article .rlr-comments__single__likerow button.like:hover,
.rlr-article .rlr-comments__single__likerow button.love:active,
.rlr-article .rlr-comments__single__likerow button.love:hover {
  color: var(--brand);
}

.rlr-article .rlr-comments__single__likerow button.like:before,
.rlr-article .rlr-comments__single__likerow button.love:before {
  font-family: flaticon;
  left: 10px;
  position: absolute;
  top: 0;
}

.rlr-article .rlr-comments__single__likerow button.love:before {
  content: "";
}

.rlr-article .rlr-comments__single__likerow button.like:before {
  content: "";
}

.rlr-article .content {
  color: var(--body-color);
}

.rlr-article .content__highlight {
  margin-bottom: 20px;
}

.rlr-article .content__highlight p:last-child {
  margin-bottom: 0;
}

.rlr-article .content a {
  color: var(--body-color);
}

.rlr-article .content h2,
.rlr-article .content h3 {
  color: var(--heading-color);
  margin: var(--spacing-8) 0 20px;
}

.rlr-article .content h5 {
  color: var(--heading-color);
  margin-bottom: var(--spacing-5);
}

.rlr-article .content blockquote {
  border-left: 2px solid #22968e;
  color: var(--heading-color);
  display: block;
  margin: var(--spacing-10) 0;
  padding: 5px 20px;
}

.rlr-article .content blockquote .content-quote__author {
  color: var(--body-color);
  font-style: normal;
}

.rlr-article .content hr {
  background: #e9ecef;
  margin: var(--spacing-8) 0;
}

.rlr-article .content figure {
  display: block;
  padding: var(--spacing-10) 0 var(--spacing-8);
}

.rlr-article .content figure img {
  border-radius: var(--spacing-8);
  width: 100%;
}

.rlr-article .content figure figcaption {
  color: var(--body-color);
  margin-top: --spacing-5;
}

@media (max-width: 575px) {
  .rlr-article .content figure figcaption {
    margin-top: --spacing-4;
  }
}

.rlr-article .content figure figcaption a {
  text-decoration: underline;
}

.rlr-article .content ol,
.rlr-article .content ul {
  margin: 0 0 0 17px;
  padding: 0;
}

.rlr-article .content ol li,
.rlr-article .content ul li {
  margin-bottom: 3px;
}

.rlr-article .content__conclusion {
  background: #f9fafb;
  border-radius: 16px;
  margin: 30px 0;
  padding: 32px 32px 40px;
}

@media (max-width: 575px) {
  .rlr-article .content__conclusion {
    padding: 20px;
  }
}

.rlr-article .content__conclusion h3 {
  margin: 0 0 15px;
}

.rlr-article .content__conclusion p:last-child {
  margin-bottom: 0;
}

.rlr-article .rlr-article__badges {
  margin-top: var(--spacing-12);
}

.rlr-article .rlr-article__badges .rlr-badge {
  margin-right: var(--spacing-5);
}

/*!  ==============================================================
<!-- 6.18 Comment form ==== -->
============================================================== **/
.rlr-comment-form .rlr-fieldrow__item:not(:first-child) {
  margin-top: var(--spacing-9);
}

.rlr-comment-form .form-control,
.rlr-comment-form .rlr-form-check-input[type="checkbox"] {
  border: 2px solid var(--gray-04);
}

.rlr-comment-form .rlr-button:hover {
  background-color: var(--brand);
  color: var(--brand-02);
}

/*!  ==============================================================
<!-- 6.19 Sidebar ==== -->
============================================================== **/
.rlr-sidebar {
  background: #fff;
  border: 1px solid #e9ecef;
  border-radius: 16px;
  margin-right: 20px;
  padding: 35px 45px;
}

@media (max-width: 991px) {
  .rlr-sidebar {
    border-radius: 6px;
    margin-right: 5px;
    padding: 15px 10px;
  }
}

@media (max-width: 768px) {
  .rlr-sidebar {
    margin: 0 0 30px;
  }
}

.rlr-sidebar h4 {
  color: var(--brand);
}

.rlr-sidebar__widget {
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .rlr-sidebar__widget {
    margin-bottom: 20px;
  }
}

.rlr-sidebar--search h4,
.rlr-sidebar .wp-block-search__inside-wrapper h4 {
  color: var(--body-color);
}

.rlr-sidebar--search .search-panel,
.rlr-sidebar .wp-block-search__inside-wrapper .search-panel {
  position: relative;
}

.rlr-sidebar--search input,
.rlr-sidebar .wp-block-search__inside-wrapper input {
  border: 2px solid #e9ecef;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 48px;
  padding: 0 15px;
  width: 100%;
}

.rlr-sidebar--search button,
.rlr-sidebar .wp-block-search__inside-wrapper button {
  background: url(../assets/images/search-24.png) no-repeat 50% 50%;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  bottom: 0;
  right: 0;
  top: 0;
}

.rlr-sidebar--search button:hover,
.rlr-sidebar .wp-block-search__inside-wrapper button:hover {
  background: var(--brand);
  border: 2px solid var(--brand);
  color: var(--white);
  -webkit-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;
}

.rlr-sidebar--search .wp-block-search .wp-block-search__button,
.rlr-sidebar .wp-block-search__inside-wrapper .wp-block-search .wp-block-search__button {
  background: url(../assets/images/search-24.png) no-repeat 50% 50%;
  border: 0;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
}

.rlr-sidebar .wp-block-tag-cloud a {
  border: 1px solid #dce5eb;
  border-radius: 8px;
  color: #546179;
  display: inline-block;
  list-style: none;
  margin: 0 4px 8px 0;
  padding: 4px 10px;
  text-decoration: none;
}

.rlr-sidebar .wp-block-tag-cloud a:hover {
  background-color: #dce5eb;
}

.rlr-sidebar .wp-block-archives,
.rlr-sidebar .wp-block-categories,
.rlr-sidebar .wp-block-latest-posts__list {
  margin: 0;
  padding: 0;
}

.rlr-sidebar .wp-block-archives li,
.rlr-sidebar .wp-block-categories li,
.rlr-sidebar .wp-block-latest-posts__list li {
  border-bottom: 1px solid #e9ecef;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.rlr-sidebar .wp-block-archives li a,
.rlr-sidebar .wp-block-categories li a,
.rlr-sidebar .wp-block-latest-posts__list li a {
  color: var(--body-color);
  display: block;
  padding: 10px 0;
  text-decoration: none;
}

.rlr-sidebar .wp-block-archives li a:hover,
.rlr-sidebar .wp-block-categories li a:hover,
.rlr-sidebar .wp-block-latest-posts__list li a:hover {
  color: #1d293f;
}

.rlr-sidebar .wp-block-latest-comments {
  padding-left: 0;
}

.rlr-sidebar .wp-block-latest-comments__comment {
  margin-bottom: 0;
}

.rlr-sidebar .wp-block-latest-comments__comment-meta a {
  border-bottom: 1px solid #e9ecef;
  color: var(--body-color);
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 10px 0;
  text-decoration: none;
}

.rlr-sidebar .wp-block-latest-comments__comment-meta a:hover {
  color: #1d293f;
}

.rlr-sidebar--posts ul {
  margin: 0;
  padding: 0;
}

.rlr-sidebar--posts ul li {
  display: block;
  list-style: none;
  margin: 0 0 16px;
  padding: 0;
}

.rlr-sidebar--posts ul li a {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: var(--body-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
}

.rlr-sidebar--posts ul li a:hover {
  color: #1d293f;
}

.rlr-sidebar--posts ul li a img {
  border-radius: 10px;
  margin-right: 10px;
  width: 40px;
}

.rlr-sidebar--posts ul li a .rlr-sidebar--posts__column {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.rlr-sidebar--posts ul li a .rlr-sidebar--posts__column span {
  display: block;
  font-size: 14px;
}

.rlr-sidebar .wp-block-group__inner-container>h2,
.rlr-sidebar .wp-block-search__label {
  color: var(--brand);
  font-weight: 600;
}

/*!  ==============================================================
<!-- 6.20 Support section ==== -->
============================================================== **/
.rlr-support .rlr-section__heading {
  margin-bottom: 4.5rem;
  text-align: center;
}

.rlr-support .rlr-section__heading--sub {
  color: var(--body-color);
  line-height: 36px;
}

@media (max-width: 1199.98px) {
  .rlr-support-card {
    margin: var(--spacing-8) 0;
  }
}

/*!  ==============================================================
<!-- 6.21 Page banner ==== -->
============================================================== **/
.rlr-page-banner__inner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  max-height: 26.25rem;
  min-height: 26.25rem;
  padding: var(--spacing-12);
  position: relative;
  vertical-align: middle;
  z-index: 1;
}

.rlr-page-banner__inner:before {
  z-index: -1;
}

.rlr-page-banner__inner--image-bg {
  /* background: url(../assets/images/artem-kniaz-bC7zaV3j7S4-unsplash.jpg) no-repeat 50% fixed; */
  background-attachment: scroll;
  background-size: cover;
  color: #fff;
  overflow: hidden;
}

.rlr-page-banner .rlr-breadcrumb__item a,
.rlr-page-banner .rlr-section__title--sub {
  color: var(--white);
}

.rlr-page-banner .rlr-breadcrumb__item,
.rlr-page-banner .rlr-breadcrumb__item.breadcrumb-item.active {
  color: var(--neutral);
}

/*!  ==============================================================
<!-- 6.22 Page header ==== -->
============================================================== **/
.rlr-page-header {
  background-color: var(--brand-01);
  padding: var(--spacing-15) var(--spacing-8);
}

.rlr-page-header .rlr-breadcrumb__innerpages {
  margin-bottom: var(--spacing-4);
}

.rlr-page-header__title {
  color: var(--brand-10);
  margin-bottom: var(--spacing-7);
}

.rlr-page-header__subtitle {
  color: var(--brand-08);
}

/*!  ==============================================================
<!-- 6.23 About Hero ==== -->
============================================================== **/
.rlr-about-hero__content {
  display: grid;
  grid-template: "wrapper";
  place-content: center;
}

@media (min-width: 992px) {
  .rlr-about-hero__content {
    overflow: hidden;
    place-items: center;
  }
}

.rlr-about-hero__content>* {
  grid-area: wrapper;
  max-width: 1600px;
}

.rlr-about-hero__content img {
  aspect-ratio: 16/9;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.rlr-about-hero__content-inner {
  padding: var(--spacing-7) var(--spacing-12);
}

@media (min-width: 768px) {
  .rlr-about-hero__content-inner {
    padding: 11rem 5rem 0;
  }
}

.rlr-about-hero__title {
  color: var(--white);
}

.rlr-about-hero__bg {
  border-radius: var(--spacing-8);
  display: block;
  height: 646px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.rlr-about-hero__cards {
  display: grid;
  gap: var(--spacing-8);
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 991.98px) {
  .rlr-about-hero__cards {
    gap: var(--spacing-6);
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 575.98px) {
  .rlr-about-hero__cards {
    gap: var(--spacing-4);
  }
}

.rlr-about-hero-card {
  background-color: var(--brand-00);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 43px 66px 0 rgba(0, 0, 0, 0.07);
  padding: var(--spacing-10);
}

.rlr-about-hero-card__title {
  color: var(--heading-color);
  margin-bottom: var(--spacing-7);
}

.rlr-about-hero-card__desc {
  color: var(--gray-07);
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .rlr-about-hero-card {
    padding: var(--spacing-8);
  }

  .rlr-about-hero-card__title {
    margin-bottom: var(--spacing-4);
  }
}

/*!  ==============================================================
<!-- 6.24 Contact Form ==== -->
============================================================== **/
.rlr-contact-form__header {
  margin-bottom: var(--spacing-10);
}

.rlr-contact-form__input-group {
  margin-bottom: var(--spacing-7);
}

.rlr-contact-form__label {
  color: var(--body-color);
  line-height: 1.25rem;
  margin-bottom: var(--spacing-3);
}

.rlr-contact-form .form-control {
  border: 1px solid var(--gray-04);
  color: var(--gray-05);
}

.rlr-contact-form .form-control::-webkit-input-placeholder {
  color: var(--gray-05);
}

.rlr-contact-form .form-control::-moz-placeholder {
  color: var(--gray-05);
}

.rlr-contact-form .form-control:-ms-input-placeholder {
  color: var(--gray-05);
}

.rlr-contact-form .form-control::-ms-input-placeholder {
  color: var(--gray-05);
}

.rlr-contact-form .form-control::placeholder {
  color: var(--gray-05);
}

.rlr-contact-form .rlr-button--medium {
  float: right;
  padding: 1.0625rem 1.25rem;
}

/*!  ==============================================================
<!-- 6.25 Features ==== -->
============================================================== **/
.rlr-features {
  padding: var(--spacing-15) 0;
}

.rlr-features__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-12);
}

@media (max-width: 992px) {
  .rlr-features__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rlr-features__header-text {
  max-width: 100%;
  width: 768px;
}

.rlr-features__header-text .type-h6 {
  color: var(--gray-06);
  line-height: 1.6;
}

.rlr-features__header-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--spacing-4);
}

@media (max-width: 991px) {
  .rlr-features__header-btns {
    margin-left: auto;
    margin-top: var(--spacing-4);
  }
}

.rlr-features__subtitle {
  color: var(--warning-07);
  margin-bottom: var(--spacing-4);
}

.rlr-features__title {
  margin-bottom: var(--spacing-6);
}

.rlr-features__body-img img {
  width: 100%;
}

.rlr-features__body-text {
  list-style: none;
  padding-top: var(--spacing-7);
}

.rlr-features__text-item {
  display: grid;
  gap: var(--spacing-5);
  grid-template-columns: 3rem auto;
  margin-bottom: var(--spacing-10);
}

.rlr-features__list-icon {
  background-color: var(--warning-04);
  border: var(--spacing-3) solid var(--warning-02);
  border-radius: 50%;
  color: var(--warning-07);
  display: grid;
  height: 3rem;
  place-items: center;
  width: 3rem;
}

.rlr-features__list-icon i {
  display: grid;
  font-size: 1rem;
  place-items: center;
}

.rlr-features__list-desc {
  color: var(--gray-06);
  margin-bottom: var(--spacing-6);
}

.rlr-features__link {
  text-decoration: none;
}

.rlr-features__link,
.rlr-features__link:hover {
  color: var(--warning-07);
}

.rlr-features__link i {
  position: relative;
  top: 3px;
}

.rlr-features--v2 .rlr-features__body-img {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .rlr-features--v2 .rlr-features__body-img {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.rlr-features--v2 .rlr-features__body-img img {
  max-width: 100%;
  width: 560px;
}

/*!  ==============================================================
<!-- 6.26 Header Banner ==== -->
============================================================== **/
.rlr-header-banner {
  padding-bottom: var(--spacing-12);
  padding-top: var(--spacing-12);
}

.rlr-header-banner__title {
  color: var(--black);
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: var(--spacing-7);
}

.rlr-header-banner__img {
  width: 100%;
}

@media (max-width: 767) {
  .rlr-header-banner__img {
    display: none;
  }
}

.rlr-header-banner__text-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
}

.rlr-header-banner__installation {
  grid-gap: var(--spacing-7);
  display: grid;
  grid-template-columns: 4rem auto;
  max-width: 100%;
  width: 480px;
}

.rlr-header-banner__cardcontainer {
  background-color: var(--warning-04);
  border-radius: 50%;
  color: var(--gray-09);
  display: grid;
  height: 4rem;
  place-items: center;
  width: 4rem;
}

.rlr-header-banner__cardcontainer i {
  display: grid;
  font-size: 2rem;
  place-items: center;
}

.rlr-header-banner__link {
  color: var(--warning-07);
  font-size: 1.75rem;
  font-weight: 700;
  padding: var(--spacing-5) 0;
  text-decoration: underline;
}

.rlr-header-banner .animated-underline {
  color: var(--black);
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.rlr-header-banner .animated-underline__line {
  bottom: -20px;
  left: -32px;
  overflow: hidden;
  position: absolute;
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  width: 0;
}

@media (max-width: 767) {
  .rlr-header-banner .animated-underline__line img {
    width: clamp(150px, 3vw, 100%);
  }
}

.rlr-header-banner .animated-underline__text {
  position: relative;
  z-index: 10;
}

.rlr-header-banner .animated-underline .flaticon-right-arrows {
  color: var(--warning-07);
  position: relative;
  top: 12px;
  -webkit-transition: margin 0.3s ease-in-out;
  -o-transition: margin 0.3s ease-in-out;
  transition: margin 0.3s ease-in-out;
}

.rlr-header-banner .animated-underline:hover .animated-underline__line {
  width: 100%;
}

.rlr-header-banner .animated-underline:hover .flaticon-right-arrows {
  margin-left: 10px;
}

/*!  ==============================================================
<!-- 6.27 Logo Slider ==== -->
============================================================== **/
@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }
}

.rlr-logos-slider {
  padding: var(--spacing-2) 0;
}

.rlr-logos-slider__title {
  color: var(--gray-06);
  margin-bottom: var(--spacing-8);
  text-align: center;
}

.rlr-logos-slider__items {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.rlr-logos-slider__items:after,
.rlr-logos-slider__items:before {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  width: 200px;
  z-index: 2;
}

.rlr-logos-slider__items:after {
  background: -webkit-gradient(linear, right top, left top, from(var(--white)), to(#fff0));
  background: -o-linear-gradient(right, var(--white), #fff0);
  background: linear-gradient(to left, var(--white), #fff0);
  right: 0;
  top: 0;
}

.rlr-logos-slider__items:before {
  background: -webkit-gradient(linear, left top, right top, from(var(--white)), to(#fff0));
  background: -o-linear-gradient(left, var(--white), #fff0);
  background: linear-gradient(to right, var(--white), #fff0);
  left: 0;
  top: 0;
}

.rlr-logos-slider__items .slide-track {
  -webkit-animation: scroll 25s linear infinite;
  animation: scroll 25s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3000px;
}

.rlr-logos-slider__items .slide {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 300px;
}

.rlr-logos-slider__items .slide img,
.rlr-logos-slider__items .slide svg {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  height: 64px;
}

.rlr-logos-slider__items .slide img:hover,
.rlr-logos-slider__items .slide svg:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/*!  ==============================================================
<!-- 6.28 Metrics ==== -->
============================================================== **/
.rlr-metrics {
  padding: var(--spacing-15) 0;
}

.rlr-metrics__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-12);
}

@media (max-width: 992px) {
  .rlr-metrics__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rlr-metrics__header-text {
  max-width: 100%;
  width: 768px;
}

.rlr-metrics__header-text .type-h6 {
  color: var(--gray-06);
}

.rlr-metrics__header-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--spacing-4);
}

@media (max-width: 991px) {
  .rlr-metrics__header-btns {
    margin-left: auto;
    margin-top: var(--spacing-4);
  }
}

.rlr-metrics__subtitle {
  color: var(--warning-07);
  margin-bottom: var(--spacing-4);
}

.rlr-metrics__title {
  margin-bottom: var(--spacing-6);
}

.rlr-metrics__items {
  grid-gap: var(--spacing-8);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 991px) {
  .rlr-metrics__items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .rlr-metrics__items {
    grid-template-columns: repeat(1, 1fr);
  }
}

.rlr-metrics__item-title {
  color: var(--warning-07);
  margin-bottom: var(--spacing-4);
}

.rlr-metrics__item-subtitle {
  color: var(--gray-10);
  margin-bottom: var(--spacing-4);
}

.rlr-metrics__item .type-body {
  color: var(--gray-06);
}

/*!  ==============================================================
<!-- 6.29 WooCommerce ==== -->
============================================================== **/
.woocommerce {
  margin-top: var(--spacing-14);
}

.woocommerce form .form-row label {
  line-height: unset;
}

.woocommerce form .form-row:not(.form-row-last) {
  margin-bottom: var(--spacing-10);
}

.woocommerce-checkout-review-order-table {
  background-color: var(--brand-00);
  border-radius: var(--spacing-3);
  padding: var(--spacing-8) var(--spacing-5);
}

.woocommerce-checkout-review-order-table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.woocommerce-checkout-review-order-table td {
  width: 100%;
}

.woocommerce-checkout-review-order-table td dl.variation {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.woocommerce-checkout-review-order-table td dl.variation dt.variation-BookingDetails {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.woocommerce-checkout-review-order-table td dl.variation dt.variation-BookingDetails span {
  color: var(--heading-color);
}

.woocommerce-checkout-review-order-table td dl.variation dt.variation-BookingDetails img {
  height: 3rem;
  width: 4.75rem;
}

.woocommerce-checkout-review-order-table thead .product-name {
  color: var(--heading-color);
  font-weight: 400;
  margin-bottom: var(--spacing-8);
}

.woocommerce-checkout-review-order-table .order-total {
  margin-bottom: var(--spacing-3);
  margin-top: var(--spacing-6);
}

.woocommerce-checkout-review-order-table .order-total th {
  white-space: nowrap;
}

.woocommerce .shop_table .product-quantity,
.woocommerce .shop_table td.product-name dl.variation dt,
.woocommerce .shop_table td.product-total,
.woocommerce .shop_table th.product-name,
.woocommerce .shop_table th.product-total {
  display: none;
}

.woocommerce .shop_table td {
  border: 0;
  padding: unset;
}

.woocommerce .shop_table th {
  font-weight: unset;
  line-height: unset;
  padding: unset;
}

.woocommerce .shop_table tbody th,
.woocommerce .shop_table tfoot td,
.woocommerce .shop_table tfoot th {
  border: 0;
}

.woocommerce .shop_table tfoot p {
  margin-bottom: 0;
}

.woocommerce .shop_table tfoot td,
.woocommerce .shop_table tfoot td strong,
.woocommerce .shop_table tfoot th {
  color: var(--gray-07);
  font-weight: 600;
}

.woocommerce .shop_table tfoot th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.woocommerce-info,
.woocommerce .shop_table tfoot th {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.woocommerce-info {
  color: var(--brand-10);
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 5.25rem;
  margin-bottom: var(--spacing-14);
  margin-top: var(--spacing-14);
  min-height: var(--spacing-13);
  position: relative;
}

@media (min-width: 576px) {
  .woocommerce-info {
    padding-left: 1rem;
  }
}

@media (min-width: 992px) {
  .woocommerce-info {
    padding-left: 2rem;
  }
}

@media (min-width: 1200px) {
  .woocommerce-info {
    padding-left: 5rem;
  }
}

@media (min-width: 1680px) {
  .woocommerce-info {
    margin-left: var(--spacing-9);
    padding-left: 6rem;
  }
}

.woocommerce-info .type-h1,
.woocommerce-info .type-h6 {
  letter-spacing: 0;
  margin-bottom: 0;
}

.woocommerce-info .type-h1 {
  color: var(--brand);
  font-weight: 600;
  line-height: 20px;
  margin-bottom: var(--spacing-3);
}

@media (min-width: 576px) {
  .woocommerce-info .type-h1 {
    margin-bottom: var(--spacing-4);
  }
}

@media (min-width: 768px) {
  .woocommerce-info .type-h1 {
    margin-bottom: var(--spacing-6);
  }
}

@media (min-width: 992px) {
  .woocommerce-info .type-h1 {
    margin-bottom: var(--spacing-7);
  }
}

.woocommerce-info .type-h6 {
  line-height: 20px;
}

.woocommerce-SavedPaymentMethods {
  padding-left: 0;
}

.woocommerce-SavedPaymentMethods label {
  color: var(--gray-07) !important;
}

.payment-details__visa {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-4);
  -moz-column-gap: var(--spacing-4);
  column-gap: var(--spacing-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.payment-details__visa:before {
  background: url(../assets/svg/Visa.svg) no-repeat 50% 50%;
  background-size: var(--spacing-13) var(--spacing-10);
  content: "";
  display: block;
  float: left;
  height: var(--spacing-10);
  width: var(--spacing-13);
}

.payment-details__visa input {
  margin-left: auto !important;
}

.payment-details__paypal {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-4);
  -moz-column-gap: var(--spacing-4);
  column-gap: var(--spacing-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.payment-details__paypal:before {
  background: url(../assets/svg/PayPal.svg) no-repeat 50% 50%;
  background-size: var(--spacing-13) var(--spacing-10);
  content: "";
  display: block;
  float: left;
  height: var(--spacing-10);
  width: var(--spacing-13);
}

.payment-details__paypal input {
  margin-left: auto !important;
}

.payment-details__master-card {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-4);
  -moz-column-gap: var(--spacing-4);
  column-gap: var(--spacing-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.payment-details__master-card:before {
  background: url(../assets/svg/Mastercard.svg) no-repeat 50% 50%;
  background-size: var(--spacing-13) var(--spacing-10);
  content: "";
  display: block;
  float: left;
  height: var(--spacing-10);
  width: var(--spacing-13);
}

.payment-details__master-card input {
  margin-left: auto !important;
}

.payment-details__add-card {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-4);
  -moz-column-gap: var(--spacing-4);
  column-gap: var(--spacing-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: var(--spacing-5) 0;
}

.payment-details__add-card:before {
  background: url(../assets/svg/add-card.svg) no-repeat 50% 50%;
  background-size: var(--spacing-13) var(--spacing-10);
  content: "";
  cursor: pointer;
  display: block;
  float: left;
  height: var(--spacing-10);
  width: var(--spacing-13);
}

.payment-details__add-card #wc-stripe-new-payment-method {
  margin-left: auto !important;
}

.payment-details__add-card label {
  font-weight: 500 !important;
  margin: 0;
}

.payment-details__card-details {
  grid-gap: var(--spacing-8);
  display: grid;
  grid-template-columns: 3fr 1.8fr 1fr;
}

@media (max-width: 575.98px) {
  .payment-details__card-details {
    grid-template-columns: 1fr;
  }
}

.wc_payment_method label:not(.payment_box label) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--gray-09);
  -webkit-column-gap: var(--spacing-4);
  -moz-column-gap: var(--spacing-4);
  column-gap: var(--spacing-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  line-height: 1.43;
}

.wc_payment_method {
  border-bottom: 1px solid var(--gray-04);
  padding: var(--spacing-5) 0;
}

.wc_payment_method #wc-stripe-new-payment-method,
.wc_payment_method .input-radio {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--white);
  border: 3px solid var(--gray-04);
  border-radius: 50%;
  cursor: pointer;
  flex-grow: 0;
  float: right;
  height: 2rem;
  width: 2rem;
}

.wc_payment_method #wc-stripe-new-payment-method:checked,
.wc_payment_method .input-radio:checked {
  background: url(../assets/svg/check.svg) no-repeat 50% 50%;
  background-color: var(--brand);
  background-size: 18px 18px;
  border: 1px solid var(--brand);
}

.wc_payment_method .form-row.woocommerce-SavedPaymentMethods-saveNew {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: var(--spacing-4);
  -moz-column-gap: var(--spacing-4);
  column-gap: var(--spacing-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.wc_payment_method .form-row.woocommerce-SavedPaymentMethods-saveNew label {
  font-weight: 300;
  margin-bottom: 0;
}

.woocommerce-checkout-review-order {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: var(--spacing-8);
}

@media (min-width: 992px) {
  .woocommerce-checkout-review-order {
    margin: 0 var(--spacing-7);
  }
}

@media (min-width: 1200px) {
  .woocommerce-checkout-review-order {
    margin: 0 var(--spacing-9);
  }
}

@media (min-width: 992px) {
  .woocommerce-checkout-review-order {
    width: 24.167rem;
  }
}

@media (min-width: 1400px) {
  .woocommerce-checkout-review-order {
    margin: 0 6.5rem 0 4rem;
  }
}

.woocommerce-checkout .woocommerce-checkout-payment#payment {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.woocommerce-billing-fields {
  letter-spacing: 0;
}

.woocommerce-billing-fields h3 {
  color: var(--heading-color);
  margin-bottom: var(--spacing-11);
}

.woocommerce table.shop_table {
  border: none;
  border-collapse: separate;
  border-radius: var(--spacing-7);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  position: sticky;
  text-align: left;
  top: 80px;
  width: 100%;
}

.woocommerce table.shop_table .cart-subtotal td,
.woocommerce table.shop_table .cart-subtotal th {
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.43;
  text-align: left;
}

.woocommerce table.shop_table .cart-subtotal .woocommerce-Price-amount {
  float: right;
}

.woocommerce button.button.alt {
  border: none;
  border-radius: var(--spacing-3);
  color: #fff;
  height: 3.25rem;
  padding: var(--spacing-4);
  text-align: center;
  width: 100%;
}

.woocommerce button.button.alt,
.woocommerce button.button.alt:hover {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(9%, var(--brand)), color-stop(102%, #12b569));
  background-image: -o-linear-gradient(left, var(--brand) 9%, #12b569 102%);
  background-image: linear-gradient(to right, var(--brand) 9%, #12b569 102%);
}

.wc-stripe-elements-field.StripeElement {
  background-color: var(--white);
  border: 1px solid var(--gray-04);
  border-radius: 8px;
  padding: 0.75rem var(--spacing-7);
}

.payment_methods.methods {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
}

.woocommerce-checkout #payment ul.payment_methods li img.stripe-icon {
  margin: -2px 0 0 0.5em;
  max-width: 40px;
  padding: 0 0 0 3px;
  position: relative;
  vertical-align: middle;
}

#stripe-payment-data>p {
  display: none;
}

.woocommerce-checkout .woocommerce-billing-fields .form-row:not(:last-child) {
  margin: 0;
}

.woocommerce-checkout .woocommerce-billing-fields .form-row:last-child {
  margin-bottom: 0;
}

.woocommerce-checkout #payment div.payment_box .form-row:not(.form-row-last) {
  margin: 0;
}

.woocommerce-checkout #payment div.payment_box .form-row:last-child {
  margin-bottom: 0;
}

.payment_box.payment_method_stripe {
  margin-top: 2.625rem;
}

.woocommerce-checkout #payment ul.payment_methods.methods {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.woocommerce-checkout #payment div.form-row {
  padding: 0;
}

.woocommerce-checkout #payment div.form-row.place-order {
  margin: 0;
}

.woocommerce-checkout #payment div.payment_box {
  background-color: unset;
  margin: 2.625rem 0 0;
  padding: 0;
}

.woocommerce-checkout #payment div.payment_box:before {
  content: unset;
}

.woocommerce-checkout #payment ul.payment_methods li input {
  margin: unset;
}

.select2-container .select2-selection--single {
  background-color: var(--white);
  border: 2px solid var(--gray-04);
  border-radius: var(--spacing-3);
  height: unset;
  padding: var(--spacing-3) var(--spacing-4);
}

.select2-container .select2-selection--single .select2-selection__arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#stripe-exp-element {
  position: relative;
}

#stripe-exp-element:after {
  color: var(--gray-05);
  content: "";
  float: right;
  font-family: flaticon;
  font-size: 1.5rem;
  position: absolute;
  right: 0.875rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

span.select2-selection__rendered {
  display: none;
}

.checkout__delivery-details,
.checkout__personal-details {
  -webkit-column-gap: var(--spacing-8);
  -moz-column-gap: var(--spacing-8);
  column-gap: var(--spacing-8);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: var(--spacing-14);
  row-gap: var(--spacing-7);
}

@media (max-width: 575.98px) {

  .checkout__delivery-details,
  .checkout__personal-details {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 576px) {

  .checkout__delivery-details .street-address-1,
  .checkout__personal-details .street-address-1 {
    grid-column: span 2;
  }
}

.coupon-code-title {
  font-weight: 500 !important;
  margin-bottom: var(--spacing-3);
}

.enter-coupon {
  background-color: var(--white);
  border: none !important;
  border-radius: var(--spacing-3);
  color: var(--body-color);
  margin-bottom: var(--spacing-5);
  padding: var(--spacing-6) var(--spacing-7);
  width: 100%;
}

.enter-coupon::-webkit-input-placeholder {
  color: var(--gray-05);
}

.enter-coupon::-moz-placeholder {
  color: var(--gray-05);
}

.enter-coupon:-ms-input-placeholder {
  color: var(--gray-05);
}

.enter-coupon::-ms-input-placeholder {
  color: var(--gray-05);
}

.enter-coupon::placeholder {
  color: var(--gray-05);
}

.enter-coupon:focus {
  outline: 2px solid var(--brand-03);
}

/*!  ==============================================================
<!-- 6.30 My Account ==== -->
============================================================== **/
.rlr-myaccount__wrapper a {
  color: var(--brand);
}

.rlr-myaccount__wrapper a:hover {
  color: var(--brand-09);
}

/*!  ==============================================================
<!-- 7. Pages ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 7.1 Product detail page ==== -->
============================================================== **/
.rlr-product-detail-section {
  margin-top: var(--spacing-9);
}

/*!  ==============================================================
<!-- 7.2 Search results ==== -->
============================================================== **/
.rlr-search-results-page__breadcrumb-section {
  margin: var(--spacing-5) 0;
}

@media (min-width: 768px) {
  .rlr-search-results-page__breadcrumb-section {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }
}

.rlr-search-results-page__breadcrumb-section .rlr-icon-font,
.rlr-search-results-page__breadcrumb-section .rlr-search-results-page__phone {
  color: var(--brand-09);
  font-size: 14px;
}

.rlr-search-results-page__dynamic-filter-section {
  margin-top: var(--spacing-12);
}

.rlr-search-results-page__dynamic-filter-list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-column-gap: var(--spacing-8);
  -moz-column-gap: var(--spacing-8);
  column-gap: var(--spacing-8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: var(--spacing-5);
  padding-left: 0;
}

.rlr-search-results-page__dynamic-filter-list::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.rlr-search-results-page__dynamic-filter-item {
  list-style: none;
}

.rlr-search-results-page__anchor {
  text-decoration: none;
}

.rlr-search-results-page__anchor .rlr-icon-font {
  font-size: 2rem;
}

.rlr-search-results-page__product-details {
  padding-top: var(--spacing-10);
}

@media (max-width: 991.98px) {
  /* .rlr-search-results-page__product-details {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  } */
}

.rlr-search-results-page__card-wrapper {
  margin-bottom: var(--spacing-12);
  row-gap: var(--spacing-8);
}

.rlr-search-results-page__sidebar {
  margin-bottom: var(--spacing-8);
}

@media (min-width: 1200px) {
  .rlr-search-results-page__sidebar {
    margin-bottom: 0;
  }
}

.rlr-search-results-page__divider {
  background-color: var(--gray-07);
  margin: 0;
}

.rlr-search-results-page .rlr-pagination {
  margin-top: var(--spacing-6);
}

/*!  ==============================================================
<!-- 7.3 Home ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 7.4 Checkout ==== -->
============================================================== **/
.rlr-checkout-list {
  -webkit-box-orient: vertical;
  border-bottom: 1px solid #cef3f0;
  border-top: 1px solid #cef3f0;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: var(--spacing-7);
  margin-bottom: 1.062rem;
  margin-top: var(--spacing-9);
  padding: 1.875rem 0;
}

.rlr-checkout-list,
.rlr-checkout-list__item {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rlr-checkout-list__item {
  -webkit-box-orient: horizontal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: flex-start;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
}

.rlr-checkout-list__text {
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.43;
  text-align: left;
}

.rlr-checkout-list__title {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rlr-checkout .country_select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.rlr-checkout .country_select,
.rlr-checkout .input-text {
  border: 1px solid var(--gray-04);
  border-radius: var(--spacing-3);
  color: var(--gray-05);
  letter-spacing: 0.5px;
  padding: var(--spacing-3) var(--spacing-4);
  width: 100%;
}

.rlr-checkout .country_select::-webkit-input-placeholder,
.rlr-checkout .input-text::-webkit-input-placeholder {
  color: var(--gray-05);
  font-weight: 300;
}

.rlr-checkout .country_select::-moz-placeholder,
.rlr-checkout .input-text::-moz-placeholder {
  color: var(--gray-05);
  font-weight: 300;
}

.rlr-checkout .country_select:-ms-input-placeholder,
.rlr-checkout .input-text:-ms-input-placeholder {
  color: var(--gray-05);
  font-weight: 300;
}

.rlr-checkout .country_select::-ms-input-placeholder,
.rlr-checkout .input-text::-ms-input-placeholder {
  color: var(--gray-05);
  font-weight: 300;
}

.rlr-checkout .country_select::placeholder,
.rlr-checkout .input-text::placeholder {
  color: var(--gray-05);
  font-weight: 300;
}

.rlr-checkout .country_select:focus,
.rlr-checkout .input-text:focus {
  outline: 1px solid var(--gray-04);
}

.rlr-checkout .country_select:-webkit-autofill,
.rlr-checkout .country_select:-webkit-autofill:active,
.rlr-checkout .country_select:-webkit-autofill:focus,
.rlr-checkout .country_select:-webkit-autofill:hover,
.rlr-checkout .input-text:-webkit-autofill,
.rlr-checkout .input-text:-webkit-autofill:active,
.rlr-checkout .input-text:-webkit-autofill:focus,
.rlr-checkout .input-text:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--gray-05);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.rlr-checkout #billing_country_field {
  position: relative;
}

.rlr-checkout #billing_country_field:after {
  bottom: 16px;
  color: var(--gray-05);
  content: "";
  font-family: flaticon;
  font-size: 1.25rem;
  font-weight: 600;
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: var(--spacing-4);
  width: 20px;
}

.rlr-checkout .checkout {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 992px) {
  .rlr-checkout .checkout {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.rlr-checkout .checkout #order_review_heading {
  display: none;
}

.rlr-checkout .checkout .col2-set {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 0 var(--spacing-9) 0;
}

@media (min-width: 576px) {
  .rlr-checkout .checkout .col2-set {
    padding: 0 var(--spacing-7);
  }
}

@media (min-width: 992px) {
  .rlr-checkout .checkout .col2-set {
    padding-left: var(--spacing-9);
    padding-right: var(--spacing-9);
    width: 66.66667%;
  }
}

@media (min-width: 1200px) {
  .rlr-checkout .checkout .col2-set {
    padding-left: var(--spacing-14);
    padding-right: var(--spacing-14);
    width: 66.66667%;
  }
}

@media (min-width: 1680px) {
  .rlr-checkout .checkout .col2-set {
    padding-left: 8.5rem;
    padding-right: 6.5rem;
  }
}

.rlr-checkout .checkout .col2-set .col-1 {
  width: 100%;
}

.rlr-checkout .checkout .col2-set .col-2 {
  display: none;
}

.rlr-checkout .checkout__section-title {
  color: var(--gray-04);
  margin-bottom: var(--spacing-8);
  position: relative;
  text-align: center;
}

.rlr-checkout .checkout__section-title:before {
  background-color: var(--gray-03);
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.rlr-checkout .checkout__section-title span {
  background-color: var(--white);
  padding: 0 10px;
  position: relative;
  z-index: 10;
}

.rlr-checkout .cart-item-title {
  color: var(--body-color);
  margin-bottom: var(--spacing-7);
}

.rlr-checkout .cart-item-card {
  background-color: var(--white);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 2px 4px 0 rgba(14, 86, 124, 0.15);
  box-shadow: 0 2px 4px 0 rgba(14, 86, 124, 0.15);
  list-style-type: none;
  margin-bottom: var(--spacing-6);
  padding: var(--spacing-5);
  position: relative;
}

.rlr-checkout .cart-item-card:last-child {
  margin-bottom: var(--spacing-8);
}

.rlr-checkout .cart-item-card__close {
  color: var(--gray-05);
  cursor: pointer;
  position: absolute;
  right: var(--spacing-5);
  top: var(--spacing-5);
  z-index: 100;
}

.rlr-checkout .cart-item-card__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  grid-gap: var(--spacing-4);
  align-items: center;
  display: grid;
  grid-template-columns: 5.1875rem auto;
  margin-bottom: var(--spacing-4);
}

.rlr-checkout .cart-item-card__title {
  color: var(--gray-09);
  margin-bottom: var(--spacing-2);
  max-width: calc(100% - 20px);
}

.rlr-checkout .cart-item-card__date {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  color: var(--gray-05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  margin-bottom: 0;
}

.rlr-checkout .cart-item-card__img {
  border-radius: 10px;
  width: 5.1875rem;
}

.rlr-checkout .cart-item-card__footer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  border-top: 1px solid var(--gray-03);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding-top: var(--spacing-5);
}

.rlr-checkout .cart-item-card__footer p {
  color: var(--gray-07);
  margin-bottom: 0;
}

.rlr-checkout .cart-item-card__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  grid-gap: var(--spacing-4);
  align-items: center;
  color: var(--gray-07);
  display: grid;
  grid-template-columns: 2rem 35% 2rem auto;
  padding-bottom: var(--spacing-4);
  padding-top: var(--spacing-4);
}

@media (max-width: 375px) {
  .rlr-checkout .cart-item-card__item {
    grid-gap: var(--spacing-2);
    grid-template-columns: 2rem 40% 2rem auto;
  }
}

.rlr-checkout .cart-item-card__item p {
  margin-bottom: 0;
}

.rlr-checkout .cart-item-card__item-title p:first-child {
  font-weight: 300;
}

.rlr-checkout .cart-item-card__item-number {
  text-align: center;
}

.rlr-checkout .cart-item-card__item-number .times {
  color: var(--gray-04);
  font-size: 0.75rem;
}

.rlr-checkout .cart-item-card__item-price {
  font-weight: 400;
  text-align: end;
}

.rlr-checkout .cart-item-card__iconcontainer {
  background-color: var(--brand-01);
  border-radius: 50%;
  color: var(--brand-07);
  display: grid;
  font-size: 1.125rem;
  height: 2rem;
  place-items: center;
  width: 2rem;
}

.rlr-checkout .cart-item-card__iconcontainer i {
  display: grid;
  place-items: center;
}

.rlr-checkout .order-total td,
.rlr-checkout .order-total th {
  color: var(--heading-color);
  line-height: 1.5;
}

.rlr-checkout .order-total .woocommerce-Price-amount {
  color: var(--brand);
  float: right;
  font-weight: 600;
}

.rlr-checkout .wc_payment_methods label {
  color: var(--gray-09);
}

.rlr-checkout .payment_method_stripe label {
  color: var(--gray-07);
}

.rlr-checkout .payment_method_stripe .title {
  background-color: #eefbfa;
  border: 1px solid #7cdfd7;
  border-radius: 8px;
  padding: var(--spacing-5);
}

.rlr-checkout .form-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 1200px) {
  .rlr-checkout .form-row.form-row-first {
    float: left;
  }

  .rlr-checkout .form-row.form-row-last {
    float: right;
  }
}

.rlr-checkout .form-row:not(:last-child) {
  margin-bottom: var(--spacing-10);
}

.rlr-checkout .form-row label {
  color: var(--gray-07);
  letter-spacing: 0;
  margin-bottom: var(--spacing-3);
}

.rlr-checkout .form-row label .optional {
  color: var(--gray-05);
}

.rlr-checkout .form-row label .required {
  color: var(--danger-color);
  text-decoration: none;
}

.rlr-checkout .form-row input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid var(--body-color);
  border-radius: var(--spacing-2);
  height: var(--spacing-6);
  margin: 0;
  margin-top: var(--spacing-2);
  min-width: var(--spacing-6);
  width: var(--spacing-6);
}

.rlr-checkout .form-row input[type="checkbox"]:checked {
  /* background: url(../../assets/images/tick.png) no-repeat 50% 50% var(--brand); */
  border: 0;
}

/*!  ==============================================================
<!-- 7.5 Blog listings ==== -->
============================================================== **/
.rlr-listings__header {
  margin-bottom: var(--spacing-8);
}

.rlr-search-results-page__card-wrapper .sticky {
  width: 100%;
}

.rlr-search-results-page__card-wrapper .sticky .rlr-postcard {
  background: var(--brand-00);
}

/*!  ==============================================================
<!-- 7.6 404 Error ==== -->
============================================================== **/
.rlr-error {
  background-image: url(../assets/svg/error-bg.svg);
  background-position: 100%;
  background-repeat: no-repeat;
}

.rlr-error .type-lead-semibold {
  color: var(--brand);
}

.rlr-error__content {
  margin: var(--spacing-18) 0 var(--spacing-19);
}

.rlr-error__search {
  margin-top: var(--spacing-9);
}

/*!  ==============================================================
<!-- 7.7 Account pages ==== -->
============================================================== **/
.rlr-img-bg-block {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: -o-linear-gradient(45deg, #101828 0, #475467 100%);
  background: linear-gradient(45deg, #101828, #475467);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.rlr-img-bg-block__content .type-h1 {
  color: var(--white);
}

.rlr-img-bg-block__content .type-body-medium {
  color: var(--gray-02);
}

/*!  ==============================================================
<!-- 7.8 About page ==== -->
============================================================== **/
.rlr-about .rlr-img--dotted {
  margin: 2rem 0;
}

.rlr-about .rlr-img--dotted-reversed {
  margin-bottom: 2rem;
}

/*!  ==============================================================
<!-- 7.9 Contact page ==== -->
============================================================== **/
/*!  ==============================================================
<!-- 7.10 Landing Page ==== -->
============================================================== **/
.rlr-landing {
  --brand: #0d151e;
  --brand-01: #fefff3;
}

.rlr-landing .rlr-super-text {
  color: var(--warning-07);
}

.rlr-landing .rlr-features__list-icon {
  background: --warning-02;
}

.rlr-landing .rlr-cta {
  border-radius: 0;
}

.rlr-landing .rlr-cta p {
  font-size: 1.2rem;
  font-weight: 400;
}

.rlr-landing .rlr-cta h2 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0;
}

.rlr-landing .rlr-contact-detail-item__icon {
  background-color: var(--gray-02);
}

.rlr-landing .rlr-contact-detail-item__title {
  color: var(--gray-5);
  font-weight: 700;
}

.rlr-landing .rlr-contact-detail-item__desc {
  color: var(--gray-06);
}

.rlr-landing__demos {
  background-color: var(--brand);
  padding: var(--spacing-14) 0;
}

.rlr-landing__demos .rlr-section__title--main {
  color: var(--gray-04);
}

.rlr-landing__demos .rlr-team-card--v2 {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .rlr-landing__demos .rlr-team-card--v2 {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 48%;
    flex: 1 0 48%;
  }
}

@media (min-width: 1400px) {
  .rlr-landing__demos .rlr-team-card--v2 {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 32%;
    flex: 1 0 32%;
  }
}

.rlr-landing__demos .rlr-team-card--v2 .rlr-team-card__title>a {
  color: var(--gray-05);
}

.rlr-landing__demos .rlr-team-card--v2 .rlr-lightbox--gallery__figure {
  -webkit-box-shadow: 0 32px 84px rgba(14, 86, 124, 0.16);
  box-shadow: 0 32px 84px rgba(14, 86, 124, 0.16);
}

.rlr-landing__demos .rlr-team-card--v2 .rlr-team-card__thumb img {
  border-radius: 0.5rem;
  max-width: none;
}

.rlr-landing__demos .rlr-lightbox--gallery__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
}

.rlr-landing__demos .rlr-lightbox--gallery__figcaption {
  display: none;
}

.rlr-landing__demos .rlr-contact-details {
  margin-bottom: 4rem;
}

/*!  ==============================================================
<!-- 7.11 Order Received ==== -->
============================================================== **/
.rlr-section__title--v2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--brand-10);
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 5.25rem;
  margin: var(--spacing-14) 0;
  min-height: var(--spacing-13);
  padding: 7px 0 5px 6rem;
  position: relative;
}

@media (min-width: 576px) {
  .rlr-section__title--v2 {
    margin-left: var(--spacing-7);
    width: calc(100% - var(--spacing-7));
  }
}

@media (min-width: 992px) {
  .rlr-section__title--v2 {
    height: 5.25rem;
    margin-left: var(--spacing-9);
    padding: 15px 0 5px 6rem;
    width: calc(100% - var(--spacing-9));
  }
}

@media (min-width: 1200px) {
  .rlr-section__title--v2 {
    margin-left: var(--spacing-14);
    width: calc(100% - var(--spacing-14));
  }
}

@media (min-width: 1680px) {
  .rlr-section__title--v2 {
    margin-left: 8.5rem;
    width: calc(100% - 8.5rem);
  }
}

@media screen and (min-width: 1600px) {
  .rlr-section__title--v2 {
    margin-left: 8.5rem;
    width: calc(100% - 8.5rem);
  }
}

.rlr-section__title--v2 .type-h1,
.rlr-section__title--v2 .type-h6 {
  letter-spacing: 0;
  margin-bottom: 0;
}

.rlr-section__title--v2 .type-h1 {
  font-weight: 500;
  line-height: 20px;
  margin-bottom: var(--spacing-3);
}

@media (min-width: 576px) {
  .rlr-section__title--v2 .type-h1 {
    margin-bottom: var(--spacing-4);
  }
}

@media (min-width: 768px) {
  .rlr-section__title--v2 .type-h1 {
    margin-bottom: var(--spacing-6);
  }
}

@media (min-width: 992px) {
  .rlr-section__title--v2 .type-h1 {
    margin-bottom: var(--spacing-7);
  }
}

.rlr-section__title--v2 .type-h6 {
  line-height: 20px;
}

.rlr-section__title--v2:before {
  background: var(--brand-02);
  color: var(--brand-10);
  content: "";
  display: grid;
  font-family: flaticon;
  font-size: 2.5rem;
  height: 5.25rem;
  left: 0;
  place-items: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5.25rem;
}

@media (min-width: 992px) {
  .rlr-section__title--v2:before {
    font-size: 3rem;
  }
}

.woocommerce-thankyou-order-details {
  border-bottom: 1px solid var(--gray-03);
  border-top: 1px solid var(--gray-03);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: var(--spacing-8) 0;
}

.woocommerce-thankyou-order-details li {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  color: var(--gray-05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.woocommerce-thankyou-order-details li>span {
  color: var(--body-color);
  margin-top: var(--spacing-6);
}

.woocommerce-thankyou-order-details li .woocommerce-Price-amount,
.woocommerce-thankyou-order-details li .woocommerce-Price-currencySymbol {
  color: var(--brand-07);
  font-weight: 600;
  letter-spacing: -0.38;
  white-space: nowrap;
}

.woocommerce-thankyou-order-details li .woocommerce-order-overview__payment-method {
  color: var(--gray-07);
  font-weight: 400;
  white-space: nowrap;
}

@media (max-width: 575.98px) {
  .woocommerce-thankyou-order-details {
    grid-template-columns: 1fr;
  }

  .woocommerce-thankyou-order-details li {
    margin-top: var(--spacing-5);
  }

  .woocommerce-thankyou-order-details li>span {
    margin-top: var(--spacing-2);
  }
}

.order-detail-cards {
  width: 100%;
}

.woocommerce {
  margin-top: 0;
}

.woocommerce-column__title {
  color: var(--heading-color);
  margin-bottom: var(--spacing-7);
}

.woocommerce-details__items {
  list-style: none;
  padding-left: 0;
}

.woocommerce-details__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  grid-column-gap: var(--spacing-6);
  align-items: center;
  display: grid;
  grid-template-columns: 3rem auto;
  margin-bottom: var(--spacing-8);
}

.woocommerce-details__icon {
  color: var(--brand-07);
  font-size: 2rem;
  height: 3rem;
  width: 3rem;
}

.woocommerce-details__icon,
.woocommerce-details__icon i {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.woocommerce-details__title {
  color: var(--gray-05);
  letter-spacing: 1.5px;
  margin-bottom: var(--spacing-2);
}

.woocommerce-details__desc {
  color: var(--body-color);
  letter-spacing: 0;
  margin-bottom: 0;
}

.woocommerce-order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991.98px) {
  .woocommerce-order {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.woocommerce-order-details {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-color: var(--brand-00);
  border: none;
  border-collapse: separate;
  border-radius: var(--spacing-7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding: var(--spacing-8) var(--spacing-5);
  position: sticky;
  row-gap: var(--spacing-6);
  text-align: left;
  top: 80px;
  width: 100%;
}

@media (min-width: 992px) {
  .woocommerce-order-details {
    margin: 0 var(--spacing-7);
  }
}

@media (min-width: 1200px) {
  .woocommerce-order-details {
    margin: 0 var(--spacing-9);
  }
}

@media (min-width: 992px) {
  .woocommerce-order-details {
    width: 24.167rem;
  }
}

@media (min-width: 1400px) {
  .woocommerce-order-details {
    margin: 0 6.5rem 0 4rem;
  }
}

.woocommerce-order-details__title {
  margin-bottom: 0;
}

.woocommerce-order__col-1 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 0 var(--spacing-9) 0;
}

@media (min-width: 576px) {
  .woocommerce-order__col-1 {
    padding: 0 var(--spacing-7);
  }
}

@media (min-width: 992px) {
  .woocommerce-order__col-1 {
    padding-left: var(--spacing-9);
    padding-right: var(--spacing-9);
    width: 66.66667%;
  }
}

@media (min-width: 1200px) {
  .woocommerce-order__col-1 {
    padding-left: var(--spacing-14);
    padding-right: var(--spacing-14);
    width: 66.66667%;
  }
}

@media (min-width: 1680px) {
  .woocommerce-order__col-1 {
    padding-left: 8.5rem;
    padding-right: 6.5rem;
  }
}

.woocommerce-order-overview {
  margin-bottom: var(--spacing-9);
}

.order-detail-card {
  background-color: var(--white);
  border-radius: var(--spacing-5);
  -webkit-box-shadow: 0 2px 4px 0 rgba(14, 86, 124, 0.15);
  box-shadow: 0 2px 4px 0 rgba(14, 86, 124, 0.15);
  list-style-type: none;
  overflow: hidden;
  padding-left: 0;
  position: relative;
  width: 100%;
}

.order-detail-card:last-child {
  margin-bottom: var(--spacing-7);
}

.order-detail-card__close {
  cursor: pointer;
  position: absolute;
  right: var(--spacing-5);
  top: var(--spacing-5);
  z-index: 100;
}

.order-detail-card__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  grid-gap: var(--spacing-4);
  align-items: center;
  display: grid;
  grid-template-columns: 5.1875rem auto;
  padding: var(--spacing-4);
}

.order-detail-card__title {
  color: var(--gray-09);
  margin-bottom: var(--spacing-2);
  max-width: calc(100% - 20px);
}

.order-detail-card__date {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  color: var(--gray-05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  margin-bottom: 0;
}

.order-detail-card__img {
  border-radius: 10px;
  width: 5.1875rem;
}

.order-detail-card__item {
  border-top: 1px solid var(--gray-03);
  color: var(--gray-07);
  margin: 0 var(--spacing-4);
  padding: 1.125rem 0;
}

.order-detail-card__item p {
  margin-bottom: 0;
}

.order-detail-card__iconcontainer {
  background-color: var(--brand-01);
  border-radius: 50%;
  color: var(--brand-07);
  display: grid;
  font-size: 1.125rem;
  height: 2rem;
  place-items: center;
  width: 2rem;
}

.order-detail-card__iconcontainer i {
  display: grid;
  place-items: center;
}

.order-detail-card__footer {
  background-color: var(--gray-01);
  padding: 0.5313rem 0 0.4688rem;
  text-align: center;
}

.order-detail-card__footer a {
  grid-gap: var(--spacing-2);
  color: var(--gray-07);
  margin-bottom: 0;
  text-decoration: none;
}

.order-detail-card__footer a,
.order-detail-card__footer a i {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.order-detail-card__footer a i:before {
  font-weight: 700 !important;
}

/*!  ==============================================================
<!-- 7.12 Icon Fonts Page ==== -->
============================================================== **/
.rlr-icon-font-page__list {
  display: grid;
  gap: 32px 32px;
  grid-template-columns: 1fr;
  padding-left: 0;
}

@media (min-width: 768px) {
  .rlr-icon-font-page__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1400px) {
  .rlr-icon-font-page__list {
    grid-template-columns: repeat(4, 1fr);
  }
}

.rlr-icon-font-page__list .rlr-icon-text__card-title {
  white-space: unset;
}

.rlr-icon-font-page__list .rlr-icon-font {
  font-size: 2rem;
}

/*!  ==============================================================
<!-- 7.12 Icon Fonts Page ==== -->
============================================================== **/
.rlr-main--fixed-top {
  margin-top: 80px;
}

.text_shadow {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

.big_img_card {
  height: 532px;
}
a{
  cursor: pointer;
}